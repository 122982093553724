// Import Module
import React from 'react';

import Leads from './leads';

const CurrentLeads = () => {
  return (
    <Leads 
      isOnlyIncomplete={false} 
      isOnlyUnread={false} 
      showDashboard={false}
      isOnlyActive={true} />
  )
};

export default CurrentLeads;