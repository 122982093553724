import { GET, POST, PUT, DELETE, PATCH } from './http-wrapper';
import {
  PUBLIC_HEADER_TOKEN,
  ServiceURL,
  MAPBOX_ACCESS_TOKEN,
} from '../constants';
import { GetCookie } from './helper';

const GetHeader = (isPrivate = false) => {
  let headers = {};
  if (isPrivate) {
    const accessToken = GetCookie('AccessToken');
    headers['Authorization'] = accessToken;
  } else {
    // Public API needs PUBLIC_AUTHORIZATION_TOKEN as header
    headers['Authorization'] = PUBLIC_HEADER_TOKEN;
  }
  return headers;
};

export const LoginAPI = (data) => {
  const url = `${ServiceURL}/login`;
  return POST(url, GetHeader(), data);
};

export const ForgotPwd = (data) => {
  const url = `${ServiceURL}/forgot-password`;
  // Public API needs PUBLIC_AUTHORIZATION_TOKEN as header
  const headers = {};
  headers['Authorization'] = PUBLIC_HEADER_TOKEN;
  return POST(url, headers, data);
};

export const ResetPwd = (data) => {
  const url = `${ServiceURL}/reset-password`;
  // Public API needs PUBLIC_AUTHORIZATION_TOKEN as header
  const headers = {};
  headers['Authorization'] = PUBLIC_HEADER_TOKEN;
  return POST(url, headers, data);
};

export const GetAffiliates = (params) => {
  let url = `${ServiceURL}/affiliates`;
  if (params) {
    url = url + '?' + params;
  }
  return GET(url, GetHeader(true), null);
};

export const GetAffiliateDetails = (id) => {
  let url = `${ServiceURL}/admin/affiliate/${id}`;
  return GET(url, GetHeader(true), null);
};

export const GetAffiliateVehicles = (id) => {
  let url = `${ServiceURL}/affiliate/${id}/vehicles`;
  return GET(url, GetHeader(true), null);
};

export const UpdateAffiliateStatus = (id, status, note) => {
  let url = `${ServiceURL}/affiliate/${id}/status`;
  const data = {
    status: status,
    note: note,
  };
  return PUT(url, GetHeader(true), data);
};

export const UpdateAffiliateDetails = (id, data) => {
  let url = `${ServiceURL}/admin/affiliate/${id}`;
  return PUT(url, GetHeader(true), data);
};

export const GetAffiliateContacts = (id) => {
  let url = `${ServiceURL}/affiliate/${id}/contacts`;
  return GET(url, GetHeader(true), null);
};

export const GetLeads = (params, onlyActive=false) => {
  let url = (onlyActive)? `${ServiceURL}/leads/active` : `${ServiceURL}/leads`;
  if (params) {
    url = url + '?' + params;
  }
  return GET(url, GetHeader(true), null);
};

export const GetLeadDetails = (id) => {
  let url = `${ServiceURL}/lead/${id}`;
  return GET(url, GetHeader(true), null);
};

export const UpdateLeadDetails = (id, data) => {
  let url = `${ServiceURL}/lead/${id}`;
  return PUT(url, GetHeader(true), data);
};

export const FetchLocation = async (zipcode) => {
  try {
    // Validate if the zipcode is in USA format (5 digits)
    const isValidZip = /^\d{5}$/.test(zipcode);
    if (!isValidZip) {
      // console.log('Invalid USA zip code');
      return false;
    }

    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${zipcode}.json?country=US&access_token=${MAPBOX_ACCESS_TOKEN}`
    );
    const locationData = await response.json();

    // Extract city and state from the response
    const features = locationData.features;
    if (features.length > 0) {
      const place = features[0];
      const city = place.context.find((context) =>
        context.id.includes('place')
      );
      const state = place.context.find((context) =>
        context.id.includes('region')
      );
      if (city && state) {
        return {
          city,
          state,
        };
      }
    }
    return null;
  } catch (error) {
    console.error('Error fetching location:', error);
  }
};

export const FetchLocationSuggestions = async (query) => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?autocomplete=true&country=US&types=place%2Clocality%2Cpostcode&access_token=${MAPBOX_ACCESS_TOKEN}`
    );
    const locationData = await response.json();
    return locationData;
  } catch (error) {
    console.error('Error fetching location:', error);
  }
};

export const GetLeadStats = () => {
  let url = `${ServiceURL}/leads/stats`;
  return GET(url, GetHeader(true), null);
}

export const UpdateLeadStatus = (id, status, note) => {
  let url = `${ServiceURL}/lead/${id}/status`;
  const data = {
    "status": status,
    "note": note
  }
  return POST(url, GetHeader(true), data);
};

export const GetMatchingVehicle = (id, params) => {
  let url = `${ServiceURL}/lead/${id}/matching-vehicles`;
  if (params) {
    url = url + '?' + params;
  }
  return GET(url, GetHeader(true), null);
}

export const GetMatchingVehicle30_60 = (id, miles, min, max, isHardReload = true) => {
  let url = `${ServiceURL}/lead/${id}/auto-matching-vehicles?search_radius=${miles}&per_page_rows=200&current_page=1&force_refresh=${isHardReload}&min_search_radius=${min}&max_search_radius=${max}`;
  return GET(url, GetHeader(true), null);
}

export const RequestLead = (id, data) => {
  const url = `${ServiceURL}/lead/${id}/requests`;
  return POST(url, GetHeader(true), data);
};

export const GetLeadRequests = (params) => {
  let url = `${ServiceURL}/leads/requests`;
  if (params) {
    url = url + '?' + params;
  }
  return GET(url, GetHeader(true), null);
};

export const GetLeadResponses = (params) => {
  let url = `${ServiceURL}/leads/responses`;
  if (params) {
    url = url + '?' + params;
  }
  return GET(url, GetHeader(true), null);
};

export const LogOutAdmin = (token) => {
  const url = `${ServiceURL}/logout`;
  const headers = {};
  headers['Authorization'] = token;
  return POST(url, headers, null);
}

export const GetLeadVehicleDetails = (id) => {
  let url = `${ServiceURL}/lead/${id}/requests`;
  return GET(url, GetHeader(true), null);
};

export const DeleteLead = (id) => {
  let url = `${ServiceURL}/lead/${id}`;
  return DELETE(url, GetHeader(true), null);
}

export const DeleteAffiliate = (id) => {
  let url = `${ServiceURL}/affiliate/${id}`;
  return DELETE(url, GetHeader(true), null);
}

export const HireVehicle = (leadId, requestId, data) => {
  let url = `${ServiceURL}/lead/${leadId}/request/${requestId}/hire-vehicle`;
  return POST(url, GetHeader(true), data);
}

export const SendAffiliateReminder = (data) => {
  const payload = {
    'requests': data
  }
  let url = `${ServiceURL}/requests/notify`;
  return POST(url, GetHeader(true), payload);
}

export const GetMatchingVehicleCount = (payload) => {
  let url = `${ServiceURL}/leads/matching-vehicles/count`;
  return POST(url, GetHeader(true), payload);
}

export const GetLeadActivity = (id) => {
  let url = `${ServiceURL}/lead/${id}/activities`;
  return GET(url, GetHeader(true), null);
}

export const GetAffiliateActivity = (id) => {
  let url = `${ServiceURL}/affiliate/${id}/activities`;
  return GET(url, GetHeader(true), null);
}

export const GetAgents = (params) => {
  let url = `${ServiceURL}/agents`;
  if (params) {
    url = url + '?' + params;
  }
  return GET(url, GetHeader(true), null);
};

export const GetAgentsWrapper = (params) => {
  return new Promise((resolve) => {
    // show only active agents
    GetAgents('status=active').then((d) => {
      if (d.code === 200) {
          let agents = [];
          d.data.agentList.forEach((agent) => {
              agents.push({
                  label: agent.first_name + ' ' + agent.last_name,
                  value: agent.id,
              });
          });
          resolve(agents);
      }
    }).catch((e) => {
        console.log('e', e);
    });
  });
};

export const UpdateAgent = (id, data) => {
  let url = `${ServiceURL}/agent/${id}`;
  return PUT(url, GetHeader(true), data);
}

export const DeleteAgent = (id) => {
  let url = `${ServiceURL}/agent/${id}`;
  return DELETE(url, GetHeader(true), null);
}

export const CreateAgent = (data) => {
  let url = `${ServiceURL}/agent`;
  return POST(url, GetHeader(true), data);
}

export const CreateVehicle = (id, data) => {
  let url = `${ServiceURL}/affiliate/${id}/vehicle`;
  return POST(url, GetHeader(true), data);
}

export const EditVehicle = (id, vehicleId, data) => {
  let url = `${ServiceURL}/affiliate/${id}/vehicle/${vehicleId}`;
  return PUT(url, GetHeader(true), data);
}

export const DeleteVehicle = (id, vehicleId) => {
  let url = `${ServiceURL}/affiliate/${id}/vehicle/${vehicleId}`;
  return DELETE(url, GetHeader(true), null);
}

export const CreateContact = (id, data) => {
  let url = `${ServiceURL}/affiliate/${id}/contact`;
  return POST(url, GetHeader(true), data);
}

export const EditContact = (id, contactId, data) => {
  let url = `${ServiceURL}/affiliate/${id}/contact/${contactId}`;
  return PUT(url, GetHeader(true), data);
}


export const DeleteContact = (id, contactId) => {
  let url = `${ServiceURL}/affiliate/${id}/contact/${contactId}`;
  return DELETE(url, GetHeader(true), null);
}

export const NotifyAgent = (leadId, data) => {
  let url = `${ServiceURL}/lead/${leadId}/notify-agent`;
  return POST(url, GetHeader(true), data);
}

export const RegisterAffiliate = (data) => {
  let url = `${ServiceURL}/affiliate/register`;
  return POST(url, GetHeader(), data);
}

export const UpdateLeadNote = (id, note) => {
  let url = `${ServiceURL}/lead/${id}/note`;
  const data = {
    note: note
  }
  return PUT(url, GetHeader(true), data);
}

export const GetLeadQuote = (params) => {
  let url = `${ServiceURL}/leads/received-quotes`;
  if (params) {
    url = url + '?' + params;
  }
  return GET(url, GetHeader(true), null);
}

export const UpdateTripDetails = (id, data) => {
  let url = `${ServiceURL}/lead/${id}/trip-detail`;
  let payload = {
    trip_details: data
  }
  return PATCH(url, GetHeader(true), payload);
}

export const UpdateLead = (id, payload) => {
  let url = `${ServiceURL}/lead/${id}`;
  return PATCH(url, GetHeader(true), payload);
}

export const SendSMS = (data) => {
  let url = `${ServiceURL}/sms`;
  return POST(url, GetHeader(true), data);
};

export const SendMail = (data) => {
  let url = `${ServiceURL}/email`;
  return POST(url, GetHeader(true), data);
};

export const SendReminder = (leadId) => {
  let url = `${ServiceURL}/lead/${leadId}/notify-affiliates`;
  return POST(url, GetHeader(true), null);
}

export const AutoUpdateStatus = (leadId) => {
  let url = `${ServiceURL}/lead/${leadId}/status/auto-update`;
  return POST(url, GetHeader(true), null);
}

export const CreateTemplate = (data) => {
  let url = `${ServiceURL}/template`;
  return POST(url, GetHeader(true), data);
}

export const GetTemplates = (type) => {
  let url = `${ServiceURL}/template`;
  if (type) {
    url = url + '?type=' + type;
  }
  return GET(url, GetHeader(true), null);
}

export const UpdateTemplate = (id, data) => {
  let url = `${ServiceURL}/template/${id}`;
  return PUT(url, GetHeader(true), data);
}

export const DeleteTemplate = (id) => {
  let url = `${ServiceURL}/template/${id}`;
  return DELETE(url, GetHeader(true), null);
}

export const FindPrevNextLeads = (id, direction, source) => {
  let url = `${ServiceURL}/lead/${id}/find-next-previous-lead?direction=${direction}&current_box=${source}`;
  return GET(url, GetHeader(true), null);
}

export const DeleteRequest = (leadId, reqID) => {
  let url = `${ServiceURL}/lead/${leadId}/request/${reqID}`;
  return DELETE(url, GetHeader(true), null);
}

export const GetUnreadResponseCount = () => {
  let url = `${ServiceURL}/leads/received-quotes/unread/count`;
  return GET(url, GetHeader(true), null);
}

export const UpdateResponseCount = () => {
  let url = `${ServiceURL}/leads/received-quotes/read`;
  return POST(url, GetHeader(true), null);
}