// Import Module
import React, { useEffect, useState } from 'react';
import {
    Tag, Table, message, Flex, Button, Input, Typography,
    Select, DatePicker, InputNumber, TimePicker, Timeline, Row, Col,
    Checkbox
} from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import {
    FormOutlined,
    CloudUploadOutlined,
    CarOutlined,
    BellOutlined,
    MessageOutlined,
    CopyOutlined,
} from '@ant-design/icons';

import {
    GetLeadDetails, UpdateLeadDetails, FetchLocation,
    UpdateLeadStatus, GetLeadVehicleDetails, GetLeads, HireVehicle, DeleteLead, SendAffiliateReminder,
    GetLeadActivity, NotifyAgent, GetAgents, UpdateLeadNote, FindPrevNextLeads, DeleteRequest
} from '../services/api';
import {
    PhoneOutlined, MailOutlined, DownOutlined,
    ClockCircleOutlined, CalendarOutlined, EyeOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import copy from 'copy-to-clipboard';

import { Events, TripType, LeadStatus, LeadStatusDropdown, VehicleCategory, VehicleRequestStatus, Status, VehicleColor } from '../constants';
import {
    GetEnumKey, GetOptionForOnlyValue, 
    GenerateCopyToClipBoardData, FormatDate, GetTagColor,
    ConvertTo12HourFormat, FormatPhone, GetClickableLink,
    FormatDateWithDay, FormatTripHours, GetTimeDifference,
    PrevNextBntHelper,
    GetVT,
} from '../services/helper';
import TagRender from '../components/tag_render';
import BackBtn from '../components/back_btn';
import SmsModal from '../components/sms_modal';
import EmailModal from '../components/email_modal';
import FullSpin from '../components/full_spin';

const { Text } = Typography;

const LeadDetails = () => {

    const [data, setData] = useState(null);
    const [vehicleData, setVehicleData] = useState(null);
    const [relatedLeads, setRelatedLeads] = useState([]);
    const [activity, setActivity] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const { TextArea } = Input;
    const [isLoading, setIsLoading] = useState(true);
    const [isNPLoading, setIsNPLoading] = useState(false); //next previouse loading
    const [isSRLoading, setIsSRLoading] = useState(false);
    const [isHireCancelBtnLoading, setIsHireCancelBtnLoading] = useState(false); // Hire/Cancel button on vehicles 
    const [isEditMode, setIsEditMode] = useState(false);
    const [isEditModeForNotes, setIsEditModeForNotes] = useState(false); // 2 edit mode since notes can be edited in some status
    const [messageApi, contextHolder] = message.useMessage();
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 8,
        },
    });
    // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [agents, setAgents] = useState([]);
    const [sentToAgentName, setSentToAgentName] = useState(null);
    const [dummyNote, setDummyNote] = useState(null);
    const [includeLink, setIncludeLink] = useState(false);
    const [isSmsModelOpen, setIsSmsModelOpen] = useState(false);
    const [isEmailModelOpen, setIsEmailModelOpen] = useState(false);
    const [smsNo, setSmsNo] = useState('');
    const [email, setEmail] = useState('');
    const [triggerId, setTriggerId] = useState(0);
    const key = 'updatable';
    let location = useLocation();

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const rlColumns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Trip Date',
            dataIndex: 'pickup_date',
            key: 'pickup_date',
            sorter: (a, b) => a.pickup_date - b.pickup_date,
            width: '10%',
            render: (_, { pickup_date }) => (
                <p >{FormatDateWithDay(pickup_date)}</p>
            ),
        },
        {
            title: 'Event',
            dataIndex: 'trip_event',
            key: 'trip_event',
            render: (_, { trip_event }) => (
                <p >{Events[trip_event]}</p>
            ),
        },
        {
            title: 'PAX',
            dataIndex: 'passenger_count',
            key: 'passenger_count',
        },
        {
            title: 'Hrs',
            dataIndex: 'trip_hours',
            key: 'trip_hours',
        },
        {
            title: 'Pickup',
            dataIndex: 'pickup_address',
            key: 'pickup_address',
            width: '14%',
            render: (_, { pickup_address, pickup_time, customer_email }) => (
                <>
                    <p>{pickup_address}<br />{pickup_time}</p>
                </>
            ),
        },
        {
            title: 'Drop Off',
            dataIndex: 'dropOff_address',
            key: 'dropOff_address',
            render: (_, { dropOff_address, customer_phone_number }) => (
                <>
                    <p>{dropOff_address}</p>
                    {/* {customer_phone_number && <p><PhoneOutlined style={{ fontSize: 15 }} className='contact-icon' /> {FormatPhone(customer_phone_number)}</p>} */}
                </>
            ),
        },
        {
            title: 'Trip Details',
            dataIndex: 'trip_details',
            key: 'trip_details',
            width: '25%',
        },
        {
            title: 'Submitted',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => a.created_at - b.created_at,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <Tag color={status.color} key={status.tag}>
                    {status.tag.toUpperCase()}
                </Tag>
            )
        }
    ];

    const columns = [
        {
            title: 'Id',
            dataIndex: 'requestId',
            key: 'requestId',
            hidden: true
        },
        {
            title: 'Vehicle',
            dataIndex: 'vehicle',
            render: (_, { vehicle, vehicleCategory, company, address, distance, affiliateEmail, affiliatePhone, affiliateWebsite, affiliateId }) => (
                <div>
                    <div className='flex-separate jc-space-between'>
                        <b className=''>{vehicle}</b>
                        <div className={`lead-vehicle-img ${vehicleCategory}`}></div>
                    </div>
                    <p className='txt-cap'>{company} | {address} | Distance: {distance}</p>
                    <p>
                        <span className='cursor-pointer' onClick={()=> {
                                    setEmail(affiliateEmail);
                                    setTriggerId(affiliateId);
                                    setIsEmailModelOpen(true);
                                }}> 
                            {affiliateEmail}
                        </span> | <span className='cursor-pointer'
                                    onClick={()=> {
                                        setSmsNo(affiliatePhone);
                                        setTriggerId(affiliateId);
                                        setIsSmsModelOpen(true);
                                    }}>
                                    {FormatPhone(affiliatePhone)}</span>
                    </p>
                    <p>
                    <a
                        href={GetClickableLink(affiliateWebsite)}
                        rel='noreferrer'
                        className='big-website-link'
                        target='_blank'
                        >
                        {affiliateWebsite}
                        </a>
                    </p>
                </div >
            ),
            key: 'vehicle',
            width: 400
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <Tag color={status.color} key={status.tag}>
                    {status.tag.toUpperCase()}
                </Tag>
            ),
            width: 210
        },
        {
            title: 'Agent',
            dataIndex: 'agent',
            key: 'agent',
            render: (_, { agent }) => (
                <p>{agent}</p>
            ),
            width: 200
        },
        {
            title: 'Action',
            dataIndex: 'hire',
            width: 250,
            key: 'hire',
            render: (_, { requestId, status, isHiredOtherVehicle, searchCriteria, lastRead, lastReadBy, vehicleStatus }) => (
                <>
                    {(status.tag === VehicleRequestStatus.available.name ||
                        status.tag === VehicleRequestStatus.hired.name) &&
                        <Button
                            loading={isHireCancelBtnLoading}
                            style={{ width: '140px' }}
                            onClick={(e) => {
                                // passing is_hired : true in body if it's available
                                // else pass is_hired : false in body if it's hired to cancel the request

                                //  passing opposite of current is_hired value
                                setIsHireCancelBtnLoading(true);

                                HireVehicle(id, requestId, { is_hired: !vehicleStatus }).then((d) => {
                                    if (d.code === 200) {
                                        getLeadDetail();
                                        getVehicleDetail();
                                        messageApi.open({
                                            key,
                                            type: 'success',
                                            content: `Request processed successfully.`,
                                        });
                                        setIsHireCancelBtnLoading(false);
                                    }
                                }).catch((e) => {
                                    messageApi.open({
                                        key,
                                        type: 'error',
                                        content: e.message,
                                    });
                                    setIsHireCancelBtnLoading(false);
                                });
                                return false;
                            }}
                        >
                            {/* Checking vehicle is_hired status instead of the lead status */}
                            {vehicleStatus ? 'Cancel' : 'Hire'}
                        </Button>}

                    {status.tag === VehicleRequestStatus.unattended.name && !isHiredOtherVehicle &&
                        <Button style={{ width: '140px' }}
                            type="primary"
                            loading={requestId === isSRLoading}
                            className="send-reminder-btn"
                            onClick={(e) => {
                                setIsSRLoading(requestId); // set id to show loading in only one button
                                SendAffiliateReminder([requestId]).then((d) => {
                                    if (d.code === 200) {
                                        messageApi.open({
                                            key,
                                            type: 'success',
                                            content: `Reminder sent successfully.`,
                                        });
                                    }
                                    setIsSRLoading(false);
                                }).catch((e) => {
                                    messageApi.open({
                                        key,
                                        type: 'error',
                                        content: e.message,
                                    });
                                    setIsSRLoading(false);
                                });
                                return false;
                            }}> Send Reminder</Button >
                    }
                    <>
                    {/* Show search criteria section only if is present as for old lead it isn't available */}
                        {!!searchCriteria && <p className="search-section">
                            Min: {searchCriteria.min_pax} | Max: {searchCriteria.max_pax} | Mile Radius: {searchCriteria.search_radius} <br />
                            Origin: {searchCriteria.origin_address === null ? "default ( pick up location )" : searchCriteria.origin_address }
                        </p>}
                    </>
                    <>
                        {lastRead && 
                        <div className="search-section">
                            <Tag color='processing'>
                                Last opened: {lastRead}
                            </Tag>
                            <i>by {lastReadBy} </i>
                        </div>}
                    </>
                    <>
                    {/* If vehicle is not hired then only show the delete request option */}
                    <br />
                    {!vehicleStatus && <i className='delete-req-link' onClick={() => deleteSentRequest(id, requestId)}>Delete request</i>}
                    </>
                </>),
        },
        {
            title: 'Request Sent At',
            dataIndex: 'sentAt',
            key: 'sentAt',
            width: 200
        },
        {
            title: 'Response Received At',
            dataIndex: 'receivedAt',
            key: 'receivedAt',
            width: 200
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (_, { price, key, pricing_label, isQuoteIncludedInCustomerLink }) => (
                <div>
                    {price.map((p, i) => 
                        <div key={`price-${key}-${i}`}>
                            {p}
                            {/* If customer link is generated but the quote is not included in link then show this text */}
                            {(!isQuoteIncludedInCustomerLink && data?.customer_link) ? <b className="red-font"> (Never sent in link)</b> : ""}
                        </div>
                    )}
                    <div className='pricing-label'>{pricing_label}</div>
                </div>
            ),
            width: 200,
        },
    ];

    const getLeadDetail = (fetchRelatedLeads = false) => {
        
        GetLeadDetails(id).then((d) => {
            setIsLoading(false);
            if (d.code === 200) {
                setData(d.data);
                setDummyNote(d.data.admin_note);
                if (fetchRelatedLeads) {
                    getRelatedLeads(d.data.customer_phone_number);
                    // calling vehicle details API everytime as unread lead could also have vehicle
                    // provided it is moved to unread because of "Move to Box" 
                    getVehicleDetail();
                }
            }
        }).catch((e) => {
            messageApi.open({
                key,
                type: 'error',
                content: e.message,
            });
            // if lead not found, redirect to lead list
            setTimeout(() => {
                navigate('/leads');
            }, 1000);
        });
    }

    const getRelatedLeads = (phone) => {
        if (!phone) return;
        const params = `customer_phone_number=${phone}&per_page_rows=200`;
        GetLeads(params).then((data) => {
            let mappingData = [];
            data?.data?.leads.forEach((d) => {
                if (id !== d.id) {
                    const tagColor = GetTagColor(Status[d.status]);
                    let obj = {
                        id: d.id,
                        key: d.id,
                        pickup_date: dayjs(d.pickup_date).format('MM-DD-YYYY'),
                        trip_event: d.trip_event,
                        trip_hours: d.trip_hours,
                        trip_type: d.trip_type,
                        passenger_count: d.passenger_count,
                        pickup_address: `${d.pickup_city}, ${d.pickup_state}`,
                        pickup_time: `${ConvertTo12HourFormat(d.pickup_time)}`,
                        dropOff_address: `${d.dropoff_city}, ${d.dropoff_state}`,
                        created_at: `${FormatDate(d.created_at)}`,
                        status: {
                            tag: d.status,
                            color: tagColor,
                        },
                        trip_details: d.trip_details,
                    };
                    mappingData.push(obj);
                }
            });
            setRelatedLeads(mappingData);
        });
    };

    // This is just to return the status based on the affiliate response
    const getVehicleStatus = (response, status) => {
        if (status === 'hired') {
            return 'hired';
        }
        else if (response) {
            return ((response.quotation != null) ? "available" : "unavailable");
        }
    }

    // API call to fetch all the vehicles for which the request was sent
    const getVehicleDetail = () => {
        GetLeadVehicleDetails(id).then((d) => {
            if (d.code === 200) {
                let mappingData = [];

                // set isHiredOtherVehicle flag to hide action button
                let isHiredOtherVehicle = false;
                d.data.requests.forEach((d) => {
                    if (d.status === 'hired') {
                        isHiredOtherVehicle = true;
                    }
                });

                d?.data?.requests.forEach((d) => {
                    const price = [];
                    // const submitted = '';
                    let VehicleStatus = 'unattended';
                    let isQuoteIncludedInCustomerLink = false;
                    const responses = d.response;

                    for (let i = 0; i < responses.length; i++) {
                        let priceToDisplay = '-';
                        if (responses[i].quotation != null) {
                            priceToDisplay = `${FormatTripHours(responses[i].hours)} @ $${responses[i].quotation}`;
                        }
                        // If response object exists but doesn't have price means it's marked as
                        // Not interested/available 
                        else {
                            priceToDisplay = `Not interested/available`;
                        }
                        price.push(priceToDisplay);
                        VehicleStatus = getVehicleStatus(responses[i], d.status);
                        isQuoteIncludedInCustomerLink = responses[i].is_included_in_customer_link;
                    }
                    let lastRead = '';
                    if(d.last_affiliate_read_at){
                        lastRead = GetTimeDifference(d.last_affiliate_read_at);
                    } else if(d.affiliate_read_at){
                        lastRead = GetTimeDifference(d.affiliate_read_at);
                    }

                    // Object to populate the table
                    let obj = {
                        // id: d.id,
                        key: d.id,
                        requestId: d.id,
                        vehicleCategory: d.vehicles.category,
                        vehicle: `${d.vehicles.passengers_count} Passenger ${VehicleCategory[d.vehicles.category]} (${d.vehicles.color})`,
                        passengerCount: d.vehicles.passengers_count,
                        seatFabric: d.vehicles.seat_fabric,
                        company: d.vehicles.affiliates.name,
                        address: `${d.vehicles.affiliates.address.city}, ${d.vehicles.affiliates.address.state}`,
                        distance: `${d.vehicles.distance.toFixed(0)} Miles`,
                        affiliateId: d.vehicles.affiliates.id,
                        affiliateEmail: d.vehicles.affiliates.email,
                        affiliatePhone: d.vehicles.affiliates.phone_number,
                        affiliateWebsite: d.vehicles.affiliates.website,
                        status: {
                            tag: VehicleRequestStatus[VehicleStatus].name,
                            color: VehicleRequestStatus[VehicleStatus].color
                        },
                        price: price,
                        isQuoteIncludedInCustomerLink: isQuoteIncludedInCustomerLink,
                        pricing_label: d.vehicles.affiliates.pricing_label,
                        sentAt: `${FormatDate(d.created_at)}`,
                        receivedAt: (d.response.length > 0) ? `${FormatDate(d.response[d.response.length - 1].created_at)}` : '-',
                        isHiredOtherVehicle: isHiredOtherVehicle,  // flag to hide action button
                        searchCriteria: d.search_criteria,
                        agent:d.agents.map(obj => obj.name).join(', '), // agent name
                        lastRead: lastRead,
                        lastReadBy: (d.last_affiliate_read_by)? d.last_affiliate_read_by.name: d.affiliate_read_by?.name,
                        vehicleStatus: d.is_hired
                    }
                    mappingData.push(obj);
                });
                setVehicleData(mappingData);
            }
        }).catch((e) => {
            console.log('e', e);
        });
    }

    useEffect(() => {
        setIsLoading(true);
        getLeadDetail(true); // Fetch related leads for the first time

        // show only active agents
        GetAgents('status=active').then((d) => {
            if (d.code === 200) {
                let agents = [];
                d.data.agentList.forEach((agent) => {
                    agents.push({
                        label: agent.first_name + ' ' + agent.last_name,
                        value: agent.id,
                    });
                });
                setAgents(agents);
            }
        }).catch((e) => {
            console.log('e', e);
        });

        // Reset agent dropdown
        setSentToAgentName(null);
        GetLeadActivity(id).then((d) => {
            if (d.code === 200) {
                let leadActivity = [];
                d.data.forEach((activity) => {
                    let activityDotColor = 'gray';
                    let activityText = '';
                    let customIcon = '';
                    switch (activity.activity_type) {
                        case 'lead_created':
                            activityDotColor = 'yellow';
                            activityText = (
                                <div>
                                    <p><b>Lead #{activity.lead_id}</b> created.</p>
                                </div>);
                            break;
                        case 'request_sent':
                            activityDotColor = 'blue';
                            let uniqueNames = new Set();
                            activity.activity_data.requests.forEach(obj => uniqueNames.add(obj.affiliate.name));
                            let affList = [...uniqueNames].join(', ');

                            // Assuming that admin is the one sending the request by default
                            let messageText = <p>{activity.created_by.name} sent request to <b>{affList}</b>.</p>;

                            // If it's affiliate that sent additional quotes then update the activity log message accordingly
                            if(activity.created_by.user_type === 'affiliate'){
                                messageText = <p>{activity.created_by.name} from <b>{affList}</b> created request for additional vehicles.</p>
                            }

                            activityText = (
                                <div>
                                    {messageText}
                                </div>);
                            break;
                        case 'request_deleted':
                            activityDotColor = 'red';

                            // Assuming that admin is the one deleting the request by default
                            let messageTextReq = <p>{activity.created_by.name} deleted vehicle {VehicleCategory[activity.activity_data.vehicle.category]} request sent to <b>{activity.activity_data.affiliate.name}</b>. </p>;

                            activityText = (
                                <div>
                                    {messageTextReq}
                                </div>);
                            break;
                        case 'lead_modified':
                            activityDotColor = 'orange';
                            activityText = (
                                <div>
                                    <p><b>Lead #{activity.lead_id}</b> details modified by <b>{activity.created_by.name}</b></p>
                                </div>);
                            break;

                        case 'lead_status_updated':
                            activityText = (
                                <div>
                                    <p><b>Lead #{activity.lead_id}</b> status changed by <b>{activity.created_by.name}</b></p>
                                </div>);
                            break;

                        case 'lead_note_added':
                            activityText = (
                                <div>
                                    <p>Lead note added by <b>{activity.created_by.name}</b></p>
                                </div>);
                            customIcon = (<FormOutlined className='timeline-icon' />)
                            break;

                        case 'quote_received':
                            activityDotColor = 'blue';
                            const uniqueAffiliates = {};
                            activity.activity_data.forEach(item => {
                                uniqueAffiliates[item.affiliate_id] = item.affiliate;
                            });
                            if (Object.keys(uniqueAffiliates).length > 1) {
                                activityText = (
                                    <div>
                                        <p>Quote received from <b>{activity.activity_data.length}</b> Affiliates.</p>
                                    </div>);
                            } else {
                                activityText = (
                                    <div>
                                        <p>Quote submitted by <b>{activity.activity_data[0].affiliate.name}</b></p>
                                        {activity.activity_data.map((item) => {
                                            return (<p className='activity-quote-info' key={`activity-${item.id}`}>
                                               • {VehicleColor[item.vehicle.color]} {VehicleCategory[item.vehicle.category]} - {FormatTripHours(item.hours)} @ ${item.quotes}
                                            </p>)
                                        })}
                                    </div>);
                            }
                            customIcon = (<CloudUploadOutlined className='timeline-icon' />)
                            break;

                        case 'quote_modified':
                            activityDotColor = 'orange';
                            const unqAffiliates = {};
                            activity.activity_data.forEach(item => {
                                unqAffiliates[item.affiliate_id] = item.affiliate;
                            });
                            if (Object.keys(unqAffiliates).length > 1) {
                                activityText = (
                                    <div>
                                        <p><b>{activity.activity_data.length}</b> quotes modified.</p>
                                    </div>);
                            } else {
                                activityText = (
                                    <div>
                                        <p>Quote updated by <b>{activity.activity_data[0].affiliate.name}</b></p>
                                        {activity.activity_data.map((item) => {
                                            return (<p className='activity-quote-info' key={`activity-${item.id}`}>
                                                {/* adding a includes condition as in old activity log $ sign wasn't coming */}
                                                • {VehicleColor[item.vehicle.color]} {VehicleCategory[item.vehicle.category]} - {FormatTripHours(item.hours)} @ {item.quotes.toString().includes("$") ? item.quotes : `$${item.quotes}`}
                                            </p>)
                                        })}
                                    </div>);
                            }
                            customIcon = (<CloudUploadOutlined className='timeline-icon' />)
                            break;

                        case 'hired_vehicle_cancelled':
                            activityDotColor = 'red';
                            activityText = (
                                <div>
                                    <p><b>{activity.created_by.name}</b> cancelled Affiliate <b>#{activity.activity_data.affiliate_id}</b>'s hired vehicle</p>
                                </div>);
                            customIcon = (<CarOutlined className='timeline-icon' />)
                            break;

                        case 'lead_hired':
                            activityDotColor = 'green';
                            activityText = (
                                <div>
                                    <p><b>{activity.created_by.name}</b> hired Affiliate <b>#{activity.activity_data.affiliate_id}</b>'s vehicle</p>
                                </div>);
                            customIcon = (<CarOutlined className='timeline-icon' />)
                            break;

                        case 'send_reminder':
                            activityDotColor = 'orange';
                            // const reminderCount = activity.activity_data.requests.length;
                            const affiliates = activity.activity_data.requests.map((r) => r.affiliate.name).join(', ');
                            activityText = (
                                <div>
                                    <p><b>{activity.created_by.name}</b> sent reminder to <b>{affiliates}</b>.</p>
                                </div>);
                            customIcon = (<CalendarOutlined className='timeline-icon' />)
                            break;

                        case 'notify_agent':
                            activityDotColor = 'blue';
                            activityText = (
                                <div>
                                    <p>Lead sent to <b>{activity.activity_data.agent.name}</b>.</p>
                                </div>);
                            customIcon = (<BellOutlined className='timeline-icon' />)
                            break;

                        case 'lead_read':
                            activityDotColor = 'blue';
                            activityText = (
                                <div>
                                    <p>Lead opened by <b>{activity.activity_data.affiliate.name}</b>.</p>
                                </div>);
                            customIcon = (<EyeOutlined className='timeline-icon' />)
                            break;

                        case 'lead_trip_details_modified':
                            activityDotColor = 'grey';
                            activityText = (
                                <div>
                                    <p>Trip details modified by <b>{activity.created_by.name}</b>.</p>
                                </div>);
                            customIcon = (<FormOutlined className='timeline-icon' />)
                            break;

                        case 'send_sms':
                            activityDotColor = 'blue';
                            activityText = (
                            <div>
                                <p><b>{activity.created_by.name}</b> sent a SMS: <i>{activity.activity_data.message}</i></p>
                            </div>);
                            customIcon = (<MessageOutlined className='timeline-icon' />)
                            break;

                            case 'send_email':
                            activityDotColor = 'blue';
                            activityText = (
                                <div>
                                <b><b>{activity.created_by.name}</b> sent an email.</b>  
                                <p>Subject - {activity.activity_data.subject}</p>
                                <p>Message - {activity.activity_data.message}</p>
                                </div>);
                            customIcon = (<MailOutlined className='timeline-icon' />)
                            break;  

                        default:
                            activityDotColor = 'blue';
                            activityText = activity.activity_type;
                            break;
                    }
                    const la = {
                        color: activityDotColor,
                        children: activityText,
                        label: FormatDate(activity.created_at),
                        dot: customIcon,
                    }
                    leadActivity.push(la);
                });
                setActivity(leadActivity);
            }
        }).catch((e) => { });
        /* eslint-disable-next-line*/
    }, [id]);

    const isRoundTrip = (tripType) => {
        return tripType === GetEnumKey(TripType, TripType.round_trip);
    }
    const disabledDate = (current) => {
        // Can not select days before pickup date
        return current && current < data.pickup_date;
    };

    const saveLead = () => {
        let isError = true;
        if (!data.pickup_address || !data.dropoff_address) {
            messageApi.open({
                key,
                type: 'error',
                content: `Please add pick up & drop off address.`,
            });
        // } else if (!data.pickup_zipcode || !ValidateZIP(data.pickup_zipcode)) {
        //     messageApi.open({
        //         key,
        //         type: 'error',
        //         content: `Please enter valid pick up zip code.`,
        //     });
        // } else if (!data.dropoff_zipcode || !ValidateZIP(data.dropoff_zipcode)) {
        //     messageApi.open({
        //         key,
        //         type: 'error',
        //         content: `Please enter valid drop off zip code.`,
        //     });
        } else if (!data.trip_hours && !(data.trip_type === GetEnumKey(TripType, TripType.one_way_transfer)
            || data.trip_type === GetEnumKey(TripType, TripType.multiple_day_usage) 
            || data.trip_type === GetEnumKey(TripType, TripType.round_trip))) {
            messageApi.open({
                key,
                type: 'error',
                content: `Please enter valid trip hours.`,
            });
        } else if (!data.passenger_count) {
            messageApi.open({
                key,
                type: 'error',
                content: `Please enter valid passengers count.`,
            });
        } else if (!data.trip_details) {
            messageApi.open({
                key,
                type: 'error',
                content: `Please enter valid trip details.`,
            });
        } else if(isRoundTrip(data.trip_type) && dayjs(data.return_pickup_date).format('YYYY-MM-DD') < data.pickup_date){
            messageApi.open({
                key,
                type: 'error',
                content: `Return date must be greater than pickup date.`,
            });
        }
        else {
            isError = false;
            setIsLoading(true);
            let _data = {
                "pickup_address": data.pickup_address,
                "pickup_zipcode": data.pickup_zipcode,
                "dropoff_address": data.dropoff_address,
                "dropoff_zipcode": data.dropoff_zipcode,
                "pickup_time": data.pickup_time,
                "pickup_date": dayjs(data.pickup_date).format('MM/DD/YYYY'),
                "trip_type": data.trip_type,
                "trip_hours": data.trip_hours,
                "trip_details": data.trip_details,
                "trip_event": data.trip_event,
                "passenger_count": data.passenger_count
            }

            if(isRoundTrip(data.trip_type)){
                _data['return_pickup_date'] = dayjs(data.return_pickup_date).format('MM/DD/YYYY');
                _data['return_pickup_time'] = data.return_pickup_time;
                // Explicitly setting 0 in trip hours for round trip
                _data['trip_hours'] = 0;
            }
            UpdateLeadDetails(id, _data).then((d) => {
                if (d.code === 200) {
                    // updateStatus(d.data.status, data.admin_note);
                    setIsLoading(false);
                    setIsEditMode(false);
                    setIsEditModeForNotes(false);
                    messageApi.open({
                        key,
                        type: 'success',
                        content: `Lead updated.`,
                    });
                }
            }).catch((e) => {
                setIsLoading(false);
                messageApi.open({
                    key,
                    type: 'error',
                    content: e.message,
                });
            });
        }
        return isError;
    };

    const isTripHoursNotFinalized = (tripType) => {
        return (tripType === GetEnumKey(TripType, TripType.one_way_transfer) 
        || tripType === GetEnumKey(TripType, TripType.multiple_day_usage))
    };

    const updateData = (type, value) => {
        let _data = { ...data };
        if(type === 'trip_type'){
            if (isTripHoursNotFinalized(value)){
                _data['trip_hours'] = 0;
            } else if(isRoundTrip(value)){
                _data['return_pickup_date'] = _data['pickup_date'];
                _data['return_pickup_time'] = _data['pickup_time'];
                _data['trip_hours'] = 0;
            }
        }
        _data[type] = value;
        setData(_data);
    };

    const eventOptions = GetOptionForOnlyValue(Events);

    const tripTypeOptions = GetOptionForOnlyValue(TripType);

    const updateAddress = async (type, value) => {
        let _data = { ...data };
        _data[type] = value;
        if (type === 'pickup_zipcode' || type === 'dropoff_zipcode') {
            const locationData = await FetchLocation(value);
            if (locationData && locationData.city && locationData.state) {
                if (type === 'pickup_zipcode') {
                    _data['pickup_city'] = locationData.city.text;
                    _data['pickup_state'] = locationData.state.text;
                } else {
                    _data['dropoff_city'] = locationData.city.text;
                    _data['dropoff_state'] = locationData.state.text;
                }
            }
            setData(_data);
        } else {
            setData(_data);
        }
    };

    const updateStatus = (status, note) => {
        UpdateLeadStatus(id, status, note)
            .then((d) => {
                setIsLoading(false);
                let _data = { ...data };
                _data.status = status;
                setData(_data);
                setIsEditMode(false);
                setIsEditModeForNotes(false);
                if (d.code === 200) {
                    messageApi.open({
                        key,
                        type: 'success',
                        content: `Status changed successfully.`,
                    });
                }
            })
            .catch((e) => {
                messageApi.open({
                    key,
                    type: 'error',
                    content: e.message,
                });
            });
    }

    const statusChange = (value, options) => {
        // update data only when select one item or clear action
        if (value?.length > 1) {
            let _value = value[value.length - 1];
            const status = GetEnumKey(LeadStatus, _value);
            setIsLoading(true);
            updateStatus(status, data.admin_note);
        }
    };

    const triggerCopyLeadDetails = (vehicleData, data) => {
        const text = GenerateCopyToClipBoardData(vehicleData, data);
        copy(text);
        messageApi.open({
            key,
            type: 'success',
            content: `Copied lead data to clipboard.`,
        });
    };

    const notifyAgent = (v) =>{
        const payload = {
            "agent_id": v,
            "includeLink": includeLink
        };
        NotifyAgent(id, payload)
            .then((d) => {
                if (d.code === 200) {
                    messageApi.open({
                        key,
                        type: 'success',
                        content: `Agent notified successfully.`,
                    });
                }
            }).catch((e) => {
                messageApi.open({
                    key,
                    type: 'error',
                    content: e.message,
                });
            });
    }

    const updateIncludeLink = (e) => {
        setIncludeLink(e.target.checked);
    }

    const getNextPrev = (id, type) => {
        // Getting the source from from location state from which page it landed to leads detail
        if(location.state){
            setIsNPLoading(true);
            FindPrevNextLeads(id, type ,PrevNextBntHelper(location.state)).then((d) => {
                if (d.code === 200) {
                    const leadId = d.data.lead_id;
                    if(leadId !== null){
                        // need to store the previous url for passing it next
                        navigate(`/lead/${leadId}`, {state: location.state});
                    } else {
                        messageApi.open({
                            key,
                            type: 'warning',
                            content: `No ${type} lead found.`,
                        });
                    }
                }
                setIsNPLoading(false);
            }).catch((e) => {
                console.log('e', e);
                setIsNPLoading(false);
            });
        }
    };

    const handleDelete = () => {
        DeleteLead(id).then(() => {
            navigate(-1);
        });
    };

    const deleteSentRequest = (leadID, reqID) => {
        DeleteRequest(leadID, reqID).then((d) => {
            if (d.code === 200) {
                messageApi.open({
                    key,
                    type: 'success',
                    content: `Request deeleted successfully.`,
                });
                // Call the API again to populate vehicles
                getVehicleDetail();
            }
        }).catch((e) => {
            messageApi.open({
                key,
                type: 'error',
                content: e.message,
            });
        });
    }

    return (
        <div className='lead-details-container'>
            {/* Passing the state from where the lead details page was opened */}
            {/* It could be leads, other-leads, incomplete-lead, current inbox */}
            
            <BackBtn pageURL={`${location.state || "/leads"}`}/>
            {contextHolder}
            {data &&
                <div className='lead-detail-section'>
                    {isNPLoading && <FullSpin />}
                    <div className="">
                        <h1 className='display-ib'>Lead #{id}</h1>
                        <Button type="default" block={false} className="btn float-right mt-10"
                            onClick={() => {
                                navigate(`/lead/${id}/request-quote`, {state: location.pathname});
                            }} size='medium'>Manage leads</Button>
                        <Button type="primary" size="medium" className="btn btn-copy float-right mt-10"
                            onClick={() => {
                                triggerCopyLeadDetails(vehicleData, data);
                            }}>Copy Lead Info </Button>

                        <Button type="primary" disabled={data.is_deleted === '1'} danger block={false} className="btn float-right mt-10 mr-10"
                            onClick={handleDelete} size='medium'>Delete</Button>

                        <Button type="primary" block={false} className="btn generate-link-btn float-right mt-10 mr-10"
                            onClick={() => { window.open(`${process.env.REACT_APP_LIMO_LINK}?lead=${id}`, '_blank', 'noopener')}} size='medium'>Generate Link</Button>

                        {/* Since the requirements are not clear yet, comment the functionality of Next and Prev  */}

                        <Button type="default" block={false} className="btn float-right mt-10 mr-10"
                            onClick={() => {
                                getNextPrev(id, 'next');
                            }} size='medium'>Next</Button>

                        <Button type="default" block={false} className="btn float-right mt-10 mr-10"
                            onClick={() => {
                                getNextPrev(id, 'previous');
                            }} size='medium'>Prev</Button>

                        <br />
                    </div>
                    <div className="flex-separate">
                        <div className="">
                            <p>Submitted on <b>{FormatDate(data.created_at)}</b></p>
                            {data.modified_by && <p>Last updated at <b>{FormatDate(data.modified_at)}</b> by {data.modified_by.name}</p>}
                        </div>
                        <div className="flex-separate">
                            <div className='flex-1'>
                                <div>
                                <Checkbox
                                    checked={includeLink}
                                    onChange={(e) => {updateIncludeLink(e)}}
                                >
                                Send W/ Link
                                </Checkbox>
                                </div>
                                <Select
                                    value={sentToAgentName}
                                    placeholder='Send lead to agent'
                                    onChange={(v, o) => {
                                        setSentToAgentName(v);
                                        if(v){
                                            notifyAgent(v);
                                        }
                                    }}
                                    className='flex-2 agent-list'
                                    options={agents}
                                    listHeight={'100%'}
                                />
                            </div>
                            <div className={`flex-1 lead-status-tag-${data.status}`}>
                                Status &nbsp;
                                <Select
                                    mode='tags'
                                    tagRender={TagRender}
                                    value={[LeadStatus[data.status]]}
                                    style={{
                                        width: '100%',
                                    }}
                                    popupClassName={'affiliate-status-tag'}
                                    className={'affiliate-status-tag'}
                                    options={GetOptionForOnlyValue(LeadStatusDropdown)}
                                    showSearch={false}
                                    onInputKeyDown={((e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        return false;
                                    })}
                                    maxCount={2}
                                    maxTagCount={2}
                                    onChange={statusChange}
                                    loading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <Row>
                        <Col xs={24} md={16} xl={16}>
                            <div className="flex-separate align-start">
                                <div className='flex-1'>
                                    <h3 className='text-ul'>Pick Up</h3>
                                    <Flex vertical gap={3} style={{ marginLeft: -10 }}>
                                        <Input placeholder="Street address" maxLength={60}
                                            variant={`${isEditMode ? "outlined" : "borderless"}`} disabled={!isEditMode}
                                            value={data.pickup_address}
                                            onChange={(e) => updateAddress('pickup_address', e.target.value)} />
                                        <Input placeholder="City" maxLength={30}
                                            variant={`borderless`} disabled={true}
                                            value={data.pickup_city}
                                            onChange={(e) => updateAddress('pickup_city', e.target.value)} />
                                        <Input placeholder="State" maxLength={30}
                                            variant={`borderless`} disabled={true}
                                            value={data.pickup_state}
                                            onChange={(e) => updateAddress('pickup_state', e.target.value)} />
                                        <Input placeholder="Zip code" maxLength={5}
                                            variant={`${isEditMode ? "outlined" : "borderless"}`} disabled={!isEditMode}
                                            value={data.pickup_zipcode}
                                            onChange={(e) => updateAddress('pickup_zipcode', e.target.value)} />
                                    </Flex>
                                </div>
                                <div className='flex-1 ml-20'>
                                    <h3 className='text-ul'>Drop Off</h3>
                                    <Flex vertical gap={3} style={{ marginLeft: -10 }}>
                                        <Input placeholder="Street address" maxLength={60}
                                            variant={`${isEditMode ? "outlined" : "borderless"}`} disabled={!isEditMode}
                                            value={data.dropoff_address}
                                            onChange={(e) => updateAddress('dropoff_address', e.target.value)} />
                                        <Input placeholder="City" maxLength={30}
                                            variant={`borderless`} disabled={true}
                                            value={data.dropoff_city}
                                            onChange={(e) => updateAddress('dropoff_city', e.target.value)} />
                                        <Input placeholder="State" maxLength={30}
                                            variant={`borderless`} disabled={true}
                                            value={data.dropoff_state}
                                            onChange={(e) => updateAddress('dropoff_state', e.target.value)} />
                                        <Input placeholder="Zip code" maxLength={5}
                                            variant={`${isEditMode ? "outlined" : "borderless"}`} disabled={!isEditMode}
                                            value={data.dropoff_zipcode}
                                            onChange={(e) => updateAddress('dropoff_zipcode', e.target.value)} />
                                    </Flex>
                                </div>
                            </div>

                            <h3 className='text-ul'>Service Details</h3>
                            <div className="flex-separate align-start">
                                <div className='flex-1'>
                                    <Flex vertical gap={10} style={{ marginLeft: 0 }}>
                                        <span><b>Event date: </b>
                                            <DatePicker
                                                value={dayjs(data.pickup_date, 'YYYY-MM-DD')}
                                                format={'MM-DD-YYYY'}
                                                allowClear={false}
                                                suffixIcon={isEditMode ? <CalendarOutlined /> : <></>}
                                                disabled={!isEditMode}
                                                variant={`${isEditMode ? "outlined" : "borderless"}`}
                                                onChange={(v) => updateData('pickup_date', v)} />
                                            {/* Showing the readable date format in Read only mode */}
                                            &nbsp;{FormatDateWithDay(dayjs(data.pickup_date).format('MM-DD-YYYY'))}
                                        </span>
                                        <span><b>Pickup time: </b>
                                            <TimePicker use12Hours value={dayjs(data.pickup_time, 'HH:mm')}
                                                format={'HH:mm'}
                                                allowClear={false}
                                                suffixIcon={isEditMode ? <ClockCircleOutlined /> : <></>}
                                                disabled={!isEditMode}
                                                variant={`${isEditMode ? "outlined" : "borderless"}`}
                                                onChange={(time, timeString) => {
                                                    updateData('pickup_time', `${time.format("HH:mm")}`)
                                                }} />
                                            &nbsp;{ConvertTo12HourFormat(data.pickup_time)}
                                        </span>

                                        {
                                            isRoundTrip(data.trip_type) && 
                                            <>
                                                <span><b>Return date: </b>
                                                    <DatePicker
                                                        value={dayjs(data.return_pickup_date, 'YYYY-MM-DD')}
                                                        format={'MM-DD-YYYY'}
                                                        disabledDate={disabledDate}
                                                        allowClear={false}
                                                        suffixIcon={isEditMode ? <CalendarOutlined /> : <></>}
                                                        disabled={!isEditMode}
                                                        variant={`${isEditMode ? "outlined" : "borderless"}`}
                                                        onChange={(v) => updateData('return_pickup_date', v)} />
                                                    {/* Showing the readable date format in Read only mode */}
                                                    &nbsp;{data.return_pickup_date && FormatDateWithDay(dayjs(data.return_pickup_date).format('MM-DD-YYYY'))}
                                                </span>
                                                <span><b>Return time: </b>
                                                    <TimePicker use12Hours value={dayjs(data.return_pickup_time, 'HH:mm')}
                                                        format={'HH:mm'}
                                                        allowClear={false}
                                                        suffixIcon={isEditMode ? <ClockCircleOutlined /> : <></>}
                                                        disabled={!isEditMode}
                                                        variant={`${isEditMode ? "outlined" : "borderless"}`}
                                                        onChange={(time, timeString) => {
                                                            updateData('return_pickup_time', `${time.format("HH:mm")}`)
                                                        }} />
                                                    &nbsp;{data.return_pickup_time && ConvertTo12HourFormat(data.return_pickup_time)}
                                                </span>
                                            </>
                                        }
                                        <span><b>Trip hours: </b>
                                            <InputNumber min={0} max={100} value={data.trip_hours}
                                                onChange={(v) => updateData('trip_hours', v)}
                                                disabled={!isEditMode || isTripHoursNotFinalized(data.trip_type) || isRoundTrip(data.trip_type)}
                                                variant={`${(!isEditMode || isTripHoursNotFinalized(data.trip_type) || isRoundTrip(data.trip_type)) ? "borderless" : "outlined"}`}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </span>
                                        <span><b>Trip details:</b>
                                            <TextArea rows={4} placeholder="Trip detail"
                                                variant={`${isEditMode ? "outlined" : "borderless"}`}
                                                maxLength={3000} value={data.trip_details}
                                                disabled={!isEditMode}
                                                onChange={(e) => updateData('trip_details', e.target.value)}
                                                size={'large'}
                                                style={{
                                                    height: 120,
                                                    resize: 'none',
                                                }} />
                                        </span>
                                        <Text className={`associated-lead-text ${relatedLeads.length > 0 ? '': 'visibility-hidden'}`} type="danger">Additional associate leads see below</Text>
                                    </Flex>
                                </div>
                                <div className='flex-1 ml-20'>
                                    <Flex vertical gap={10} style={{ marginLeft: 0 }}>
                                        <span><b>Event type: </b>
                                            <Select
                                                value={Events[data.trip_event]}
                                                style={{
                                                    width: 220,
                                                }}
                                                suffixIcon={isEditMode ? <DownOutlined /> : <></>}
                                                disabled={!isEditMode}
                                                variant={`${isEditMode ? "outlined" : "borderless"}`}
                                                onChange={(v) => updateData('trip_event', GetEnumKey(Events, v))}
                                                options={eventOptions}
                                            />
                                        </span>
                                        <span><b>Trip type: </b>
                                            <Select
                                                value={TripType[data.trip_type]}
                                                style={{
                                                    width: 200,
                                                }}
                                                suffixIcon={isEditMode ? <DownOutlined /> : <></>}
                                                disabled={!isEditMode}
                                                variant={`${isEditMode ? "outlined" : "borderless"}`}
                                                onChange={(v) => updateData('trip_type', GetEnumKey(TripType, v))}
                                                options={tripTypeOptions}
                                            />
                                        </span>
                                        <span><b>Passengers: </b>
                                            <InputNumber min={1} max={999} placeholder="Passengers"
                                                style={{
                                                    width: "30%"
                                                }}
                                                maxLength={3}
                                                disabled={!isEditMode}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                variant={`${isEditMode ? "outlined" : "borderless"}`}
                                                value={data.passenger_count}
                                                onChange={(v) => updateData('passenger_count', v)} />
                                        </span>
                                        <div>
                                            <b >Contact Details: </b>
                                            <p>
                                                <PhoneOutlined style={{ fontSize: 15 }} className='contact-icon' /> 
                                                {' '}
                                                <span onClick={()=> {
                                                    setSmsNo(data.customer_phone_number);
                                                    setTriggerId(id);
                                                    setIsSmsModelOpen(true);
                                                }} className='cursor-pointer'>{data.customer_phone_number ? FormatPhone(data.customer_phone_number) : 'n/a'}</span>
                                                {' '}
                                                <CopyOutlined className='copy-to-clip-icon' 
                                                    onClick={()=>{
                                                        copy(data.customer_phone_number);
                                                        messageApi.open({
                                                            key,
                                                            type: 'success',
                                                            content: `Phone number copied.`,
                                                        });
                                                    }}/>
                                            </p>
                                            <p className='website-link-container'>
                                                <MailOutlined
                                                    style={{ fontSize: 15 }}
                                                    className='site-form-item-icon'
                                                />{' '}
                                                <span onClick={()=> {
                                                    setEmail(data.customer_email);
                                                    setTriggerId(id);
                                                    setIsEmailModelOpen(true);
                                                }} className='cursor-pointer'>{data.customer_email}</span> {' '}
                                                <CopyOutlined className='copy-to-clip-icon' 
                                                    onClick={()=>{
                                                        copy(data.customer_email);
                                                        messageApi.open({
                                                            key,
                                                            type: 'success',
                                                            content: `Email copied.`,
                                                        });
                                                    }}/>
                                            </p>
                                        </div>
                                        <div>
                                            <b>Lead Source: </b>
                                            <a href={data.source}
                                                className="lead-website"
                                                rel='noreferrer' target='_blank'>
                                                {data.source}
                                            </a>
                                        </div>
                                        <div>
                                            <b>Vehicle Type: </b>
                                            <span>{GetVT(data.veh_type)}</span>
                                        </div>
                                    </Flex>
                                </div>
                            </div>
                            <br />
                            <div>
                                <span><b>Admin note:</b>
                                    <TextArea rows={4} placeholder="Admin note"
                                        variant={`${isEditModeForNotes ? "outlined" : "borderless"}`}
                                        maxLength={200} value={data.admin_note}
                                        disabled={!isEditModeForNotes}
                                        onChange={(e) => updateData('admin_note', e.target.value)}
                                        showCount={isEditModeForNotes}
                                        size={'large'}
                                        style={{
                                            height: 120,
                                            resize: 'none',
                                        }} />
                                </span>
                            </div>
                        </Col>
                        <Col xs={24} md={8} xl={8}>
                            <h3 className='text-ul'>Activity </h3>
                            <Timeline className='timeline-container' items={activity} mode={'left'} />
                        </Col>
                    </Row>

                    <br />
                    <Flex gap="small" wrap="wrap">
                        <Button disabled={data.is_deleted === '1'} type="default" block={false} className="btn"
                            onClick={() => {
                                if (isEditMode) {
                                    getLeadDetail(); // Already edit mode, so reset the data on cancel
                                }
                                // Allow editing only when status is HIRED
                                if (data.status !== GetEnumKey(LeadStatus, LeadStatus.hired)) {
                                    setIsEditMode(!isEditMode);
                                }
                                setIsEditModeForNotes(!isEditModeForNotes);
                            }} size='medium'>{isEditMode || isEditModeForNotes ? 'Cancel' : 'Edit'}</Button>
                        <Button type="primary" block={false} className="btn" loading={isLoading}
                            disabled={!isEditMode && !isEditModeForNotes} onClick={
                                () => {
                                    console.log('isEditMode', isEditMode, 'isEditModeForNotes', isEditModeForNotes)
                                    if (isEditMode) {
                                        const isError = saveLead(); // Save everything
                                        if(isError){
                                            return false; // if there is an error, don't proceed further
                                        }
                                    } 
                                    if(isEditModeForNotes) {
                                        setIsEditModeForNotes(false);
                                        if(data.admin_note !== dummyNote){
                                            setIsLoading(true);
                                            UpdateLeadNote(id, data.admin_note).then((d) => {
                                                if (d.code === 200) {
                                                    messageApi.open({
                                                        key,
                                                        type: 'success',
                                                        content: `Note saved.`,
                                                    });
                                                    setDummyNote(data.admin_note); // update dummy note
                                                }
                                                setIsLoading(false);
                                            }).catch((e) => {
                                                messageApi.open({
                                                    key,
                                                    type: 'error',
                                                    content: e.message,
                                                });
                                            });
                                        }

                                        // updateStatus(data.status, data.admin_note); // Save only admin note
                                    }
                                }} size='medium'>Save</Button>
                        {/* Buttons to show at bottom right corner */}
                        <div className="lead-bottom-btn">

                            <Button type="default" block={false} className="btn float-right"
                                onClick={() => {
                                    navigate(`/lead/${id}/request-quote`, {state: location.pathname});
                                }} size='medium'>Manage leads</Button>
                            <Button type="primary" size="medium" className="btn btn-copy float-right"
                                onClick={() => {
                                    triggerCopyLeadDetails(vehicleData, data);
                                }}>Copy Lead Info </Button>

                            <Button type="primary" disabled={data.is_deleted === '1'} danger block={false} className="btn float-right mr-10"
                                onClick={handleDelete} size='medium'>Delete</Button>

                            <Button type="primary" block={false} className="btn generate-link-btn float-right mr-10"
                                onClick={() => { window.open(`${process.env.REACT_APP_LIMO_LINK}?lead=${id}`, '_blank', 'noopener')}} size='medium'>Generate Link</Button>

                            <div className='send-link-container float-right'>
                                <Checkbox
                                    checked={includeLink}
                                    onChange={(e) => {updateIncludeLink(e)}}
                                >
                                Send W/ Link
                                </Checkbox>
                                <Select
                                    value={sentToAgentName}
                                    placeholder='Send lead to agent'
                                    onChange={(v, o) => {
                                        setSentToAgentName(v);
                                        if(v){
                                            notifyAgent(v);
                                        }
                                    }}
                                    className='float-right mr-10 agent-list'
                                    options={agents}
                                    listHeight={'100%'}
                                />
                            </div>
                            
                            <Button type="default" block={false} className="btn float-right mr-10"
                                onClick={() => {
                                    getNextPrev(id, 'next');
                                }} size='medium'>Next</Button>

                            <Button type="default" block={false} className="btn float-right mr-10"
                                onClick={() => {
                                    getNextPrev(id, 'previous');
                                }} size='medium'>Prev</Button>

                        </div>
                    </Flex>
                </div>
            }

            {
                data?.customer_link && <div className='customer-link-section'>
                    <h3>Customer Link</h3>
                    
                    <div className='flex-separate'>
                        <div>
                            <b>Results link #{data.customer_link.id}</b>
                            <br />
                            {data.customer_link.created_by && <>
                                Created by {data.customer_link.created_by.name} |&nbsp;
                            </>}
                            {FormatDate(data.customer_link.created_at)}
                        </div>
                        <div>
                            <Button type="default" block={false} className="btn ml-10"
                                onClick={() => { window.open(`${data.customer_link.customer_link}`, '_blank', 'noopener')}} size='medium'>
                                    Open Link
                            </Button>
                            <Button 
                                type="primary"
                                className='ml-10'
                                onClick={()=>{
                                copy(data.customer_link.customer_link);
                                messageApi.open({
                                    key,
                                    type: 'success',
                                    content: `Copied customer link to clipboard.`,
                                });
                            }}>Copy Link</Button>
                            <Button type="primary" block={false} className="btn generate-link-btn ml-10"
                            onClick={() => {
                                //  If autolink 2 add query param at the end &gen=1&auto=1
                                window.open(
                                    (data.customer_link.autolink === 2)
                                    ? 
                                    `${process.env.REACT_APP_LIMO_LINK}?lead=${id}&gen=1&auto=1` 
                                    : 
                                    `${process.env.REACT_APP_LIMO_LINK}?lead=${id}` , '_blank', 'noopener')}}  
                                size='medium'>Preview / Modify</Button>
                        </div>
                    </div>
                </div>
            }

            <div className='p-ant-table-container'>
                <Table
                    dataSource={vehicleData}
                    columns={columns}
                    loading={isLoading}
                    rowClassName={(record, index) => `tbl-${record.status.color}-row`}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    scroll={{
                        x: 1400,
                    }}
                />
            </div>

            {relatedLeads.length > 0 && <div className='p-ant-table-container'>
                <Table
                    title={() => <h3>Related leads</h3>}
                    dataSource={relatedLeads}
                    columns={rlColumns}
                    loading={isLoading}
                    rowClassName={(record, index) => `leads-row tbl-${record.status.color}-row`}
                    // pagination={tableParams.pagination}
                    // onChange={handleTableChange}
                    scroll={{
                        x: 1000,
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                //  Doing same for related leads based on whatever is there in ref forwarding it
                                navigate(`/lead/${record.id}`, {state: location.state});
                            }, // click row
                        };
                    }}
                />
            </div>}

            {isSmsModelOpen && <SmsModal 
                isModalOpen={isSmsModelOpen}
                setIsModalOpen={setIsSmsModelOpen}
                msgType={triggerId === id ? "lead" : "affiliate"}
                phoneNumber={smsNo}
                id={triggerId}
            />}

            {isEmailModelOpen && <EmailModal 
                isModalOpen={isEmailModelOpen}
                setIsModalOpen={setIsEmailModelOpen}
                msgType={triggerId === id ? "lead" : "affiliate"}
                emailAddress={email}
                id={triggerId}
            />}
        </div >)
};

export default LeadDetails;