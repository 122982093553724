// Import Module
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Steps, Input, Space, message, Alert, Radio, List } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { RegisterAffiliate } from '../services/api';
import { ValidateEmail, ValidateURL, ValidatePhone,
   ValidateZIP, FormatPhoneNumberForServer, IsStrongPassword } from '../services/helper';
import { VehicleCategory, VehicleColor, VehicleSeat } from '../constants'
import {
  // CheckCircleTwoTone,
  // CloseCircleTwoTone,
  LockOutlined,
} from '@ant-design/icons';

import BackBtn from '../components/back_btn';
import PhoneInput from '../components/phone_input';
import VehicleInfoModal from '../components/vehicle_info_modal';

const key = 'updatable';
const Password =({ savePassword, cpass, pass, saveCPassword})=>{

  return(
    <>
    <div className='mb-3 mt-10'>
        <label className='add-affiliate-form-item-label'>Password</label>
        <Input.Password
          placeholder='Enter new password'
          maxLength={48}
          value={pass}
          prefix={<LockOutlined className='site-form-item-icon' />}
          onChange={(e) => savePassword(e.target.value)}
        />
      </div>
      <div className='mb-3 mt-10'>
        <label className='add-affiliate-form-item-label'>Confirm Password</label>
        <Input.Password
          maxLength={48}
          placeholder='Re-type password'
          className='form-control'
          value={cpass}
          onChange={(e) => saveCPassword(e.target.value)}
          prefix={<LockOutlined className='site-form-item-icon' />}
        />
      </div>
    </>
  )
};

const Contact = ({ saveContact, contactData }) => {
  const [contacts, setContacts] = useState(contactData?.length > 0 ? contactData :
    [{
      name: '',
      email: '',
      phone_number: '',
      is_primary: true,
    }]);

  const addContact = () => {
    let _data = [...contacts];
    _data.push({
      name: '',
      email: '',
      phone_number: '',
      is_primary: false,
    });
    setContacts(_data);
    saveContact(_data);
  }

  const deleteContact = (index) => {
    let _data = [...contacts];
    _data.splice(index, 1);
    setContacts(_data);
    saveContact(_data);
  }

  const updateData = (index, key, value) => {
    let _data = [...contacts];
    let obj = _data[index];
    obj[key] = value;
    setContacts(_data);
    saveContact(_data);
  };

  const generateContactForm = () => {
    return contacts.map((contact, index) => {
      return (
        <div className='add-contact-affiliate-form' key={`aff-contact-${index}`}>
          <div className='flex-separate'>
            <h3>{(contact.is_primary) ? 'Primary Contact' : `Contact person ${index}`}</h3>
            {index > 0 && <Button type="primary" danger icon={<DeleteOutlined />} size='small' onClick={() => { deleteContact(index) }}>Remove</Button>}
          </div>
          <div className='add-affiliate-form-item'>
            <div className='add-affiliate-form-item-label'>Name</div>
            <Input value={contact.name} placeholder='Enter name' maxLength={35} onChange={(e) => updateData(index, 'name', e.target.value)} />
          </div>
          <div className='add-affiliate-form-item'>
            <div className='add-affiliate-form-item-label'>Email address</div>
            <Input value={contact.email} placeholder='Enter email address' maxLength={48} onChange={(e) => updateData(index, 'email', e.target.value)} />
          </div>
          <div className='add-affiliate-form-item'>
            <div className='add-affiliate-form-item-label'>Mobile number</div>
            <PhoneInput  value={contact?.phone_number} placeholder='Enter mobile number' onChange={(e) => updateData(index, 'phone_number', e)}/>
            {/* <Input addonBefore="+1" value={FormatPhoneNumberForDisplay(contact.phone_number)} maxLength={10} placeholder='Enter mobile' onChange={(e) => updateData(index, 'phone_number', e.target.value)} /> */}
          </div>
        </div >
      )
    });
  }

  return (
    <div className='affiliate-contact-container'>
      <div>
        {generateContactForm()}
      </div>
      {contacts.length < 3 &&
        <Alert
          message="Add extra contacts"
          description="Do you want to add additional team members to your company? Can add up to 2 additional contacts who will be able to view and respond to all incoming lead requests."
          type="info"
          action={
            <Space direction="vertical">
              <Button size="small" type="primary" onClick={addContact}>
                Add Contact
              </Button>
            </Space>
          }
        />
      }
    </div>
  )
};

const VehicleList = ({ saveVehicle, messageApi, vehicleData }) => {
  const [vehicles, setVehicles] = useState(vehicleData?.length > 0 ? vehicleData : []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModeIndex, setEditModeIndex] = useState(-1);

  const addVehicle = (vehicle) => {
    // check for duplication
    if (vehicles.some((v) => 
      v.category === vehicle.category &&
      v.color === vehicle.color &&
      v.seat_fabric === vehicle.seat_fabric &&
      v.passenger_count === vehicle.passenger_count)) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Vehicle already exists!',
      });
      return;
    }
    let _data = [...vehicles];
    if (editModeIndex > -1) {
      _data[editModeIndex] = vehicle; // edit
      setEditModeIndex(-1); // reset
    } else {
      _data.push(vehicle); // add new
    }
    setVehicles(_data);
    saveVehicle(_data);
    setIsModalOpen(false);
  }

  const removeVehicle = (index) => {
    let _data = [...vehicles];
    _data.splice(index, 1);
    setVehicles(_data);
    saveVehicle(_data);
  };

  const editVehicle = (index) => {
    setIsModalOpen(true);
    setEditModeIndex(index);
  }

  return (
    <div className='vehicle-list-container'>
      {isModalOpen && <VehicleInfoModal 
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        addVehicleIntoList={addVehicle}
        editModeIndex={editModeIndex}
        loading={false}
        messageApi={messageApi}
        vehicle={editModeIndex > -1 ? vehicles[editModeIndex] : null}
      />}
      <div className='flex-separate'>
        <h3 className='mt-20'>Vehicle List</h3>
        <Button type="primary" className='mt-10' onClick={() => {
          setEditModeIndex(-1); // reset for new vehicle
          setIsModalOpen(true)
        }}>Add Vehicle</Button>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={vehicles}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <EditOutlined className='vehicle-action-icon' onClick={(e) => editVehicle(index)} />,
              <DeleteOutlined className='vehicle-action-icon' onClick={(e) => removeVehicle(index)} />]}>
            <List.Item.Meta
              avatar={<span className={` ${item.category} vehicle-select-list`}></span>}
              title={VehicleCategory[item.category]}
              description={
                <div>{item.passenger_count} passenger{(item.passenger_count > 1) ? 's' : ''} | <span className={`vehicle-list-color-icon vehicle-color-list-${VehicleColor[item.color]}`}></span>{VehicleColor[item.color]} {VehicleSeat[item.seat_fabric]? `| ${VehicleSeat[item.seat_fabric]} seat` : ''}</div>}
            />
          </List.Item>
        )
        }
      />
    </div >
  )
}

const AddAffiliate = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [current, setCurrent] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const validateFirstStep = () => {
    let _data = data;
    let errorMsg = '';
    if (!_data.name) {
      errorMsg = 'Company name is required!';
    }
    else if (!_data?.address?.street_address) {
      errorMsg = `Full address of company is required!`;
    }
    else if (!_data?.address?.zipcode) {
      errorMsg = `Zip code is required!`;
    }
    else if (!ValidateZIP(_data.address.zipcode)) {
      errorMsg = `Invalid zip code!`;
    }
    else if (!_data.phone_number) {
      errorMsg = `Office phone number is required!`;
    }
    else if (_data.phone_number.length < 10 || !ValidatePhone(_data.phone_number)) {
      errorMsg = `Invalid office phone number!`;
    }
    else if (!_data.email) {
      errorMsg = `Email address is required!`;
    }
    else if (!ValidateEmail(_data.email)) {
      errorMsg = `Please enter a valid email address.`;
    }
    else if (!_data.website) {
      errorMsg = `Company website is required!`;
    }
    else if (!ValidateURL(_data.website)) {
      errorMsg = `Invalid Company website!`;
    }
    else if (_data.dot_number && _data.dot_number.length > 10) {
      errorMsg = `Please enter valid DOT number.`;
    }
    else if(!_data.is_cross_state_insured){
      errorMsg = `Cross state lines selection is required!`;
    }

    if(errorMsg){
      messageApi.open({
        key,
        type: 'error',
        content: errorMsg,
      });
      return false;
    }
    else {
      return true;
    }
  }

  const validateSecondStep = () => {
    if (!data?.contacts || data?.contacts?.length === 0) {
      messageApi.open({
        key,
        type: 'error',
        content: `Please add at least one contact.`,
      });
      return false;
    } else {
      let contacts = data.contacts;
      const isProper = contacts.map((contact, index) => {
        if (!contact.name) {
          messageApi.open({
            key,
            type: 'error',
            content: `Name is required!`,
          });
          return false;
        } else if (!contact.email) {
          messageApi.open({
            key,
            type: 'error',
            content: `Email address is required!`,
          });
          return false;
        } else if (!ValidateEmail(contact.email)) {
          messageApi.open({
            key,
            type: 'error',
            content: `Please enter a valid email address.`,
          });
          return false;
        } else if (!contact.phone_number) {
         messageApi.open({
           key,
           type: 'error',
           content: `Mobile number is required!`,
         });
         return false;
       } else if (contact.phone_number.length < 10
           || !ValidatePhone(contact.phone_number)
        ) {
          messageApi.open({
            key,
            type: 'error',
            content: `Please add valid phone number.`,
          });
          return false;
        }
        return true;
      });
      return !isProper.includes(false);
    }
  }

  const next = () => {
    if (current === 0) {
      const isValidated = validateFirstStep();
      if (!isValidated) {
        return;
      }
    } else if (current === 1) {
      const isValidated = validateSecondStep();
      if (!isValidated) {
        return;
      }
    } else if (current === 2) {
      if (!data?.vehicles || data?.vehicles?.length === 0) {
        messageApi.open({
          key,
          type: 'error',
          content: `Please add at least one vehicle.`,
        });
        return;
      }
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const saveContact = (contacts) => {
    let _data = { ...data };
    _data['contacts'] = contacts;
    setData(_data);
  }

  const saveVehicle = (vehicles) => {
    let _data = { ...data };
    _data['vehicles'] = vehicles;
    setData(_data);
  }

  const savePassword = (password) => {
    let _data = { ...data };
    _data['password'] = password;
    setData(_data);
  };

  const saveCPassword = (password) => {
    let _data = { ...data };
    _data['confirm_password'] = password;
    setData(_data);
  };

  const updateData = (key, value) => {
    let _data = { ...data };
    if (key === 'street_address' || key === 'zipcode') {
      if (!_data['address']) {
        _data['address'] = {};
      }
      _data['address'][key] = value;
    } else {
      _data[key] = value;
    }
    setData(_data);
  };

  const steps = [
    {
      title: 'Company Details',
      content:
        <div>
          <div className='add-affiliate-form'>
            <div className='add-affiliate-form-item'>
              <div className='add-affiliate-form-item-label'>Company Name</div>
              <Input placeholder='Enter company name' maxLength={60} value={data.name} onChange={(e) => updateData('name', e.target.value)} />
            </div>
            <div className='add-affiliate-form-item'>
              <div className='add-affiliate-form-item-label'>Full address of company</div>
              <Input placeholder='Enter address' maxLength={250} value={data?.address?.street_address} onChange={(e) => updateData('street_address', e.target.value)} />
            </div>
            <div className='add-affiliate-form-item'>
              <div className='add-affiliate-form-item-label'>Zip Code</div>
              <Input placeholder='Enter Zip code' maxLength={5} value={data?.address?.zipcode} onChange={(e) => updateData('zipcode', e.target.value)} />
            </div>
            <div className='add-affiliate-form-item'>
              <div className='add-affiliate-form-item-label'>Office Phone Number</div>
              <PhoneInput  value={data?.phone_number} onChange={(e) => updateData('phone_number', e)}/>
            </div>
            <div className='add-affiliate-form-item'>
              <div className='add-affiliate-form-item-label'>Main Company Email</div>
              <Input placeholder='Enter email address' maxLength={5000} value={data.email} onChange={(e) => updateData('email', e.target.value)} />
            </div>
            <div className='add-affiliate-form-item'>
              <div className='add-affiliate-form-item-label'>Company Website</div>
              <Input placeholder='Enter website' value={data.website} maxLength={320} onChange={(e) => updateData('website', e.target.value)} />
            </div>
            <div className='add-affiliate-form-item'>
              <div className='add-affiliate-form-item-label'>DOT Number (if applicable)</div>
              <Input placeholder='Enter number' value={data.dot_number} maxLength={10} 
                onChange={(e) => updateData('dot_number', e.target.value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }
                }} />
            </div>
            <div className='add-affiliate-form-item'>
              <div className='add-affiliate-form-item-label'>Are you properly insured to cross state lines</div>
              <Radio.Group name="radiogroup" value={data?.is_cross_state_insured} onChange={(e) => updateData('is_cross_state_insured', e.target.value)}>
                <Radio value={'Yes'}>Yes</Radio>
                <Radio value={'No'}>No</Radio>
              </Radio.Group>
            </div>
          </div>

        </div>,
    },
    {
      title: 'Contact Details',
      content: <Contact saveContact={saveContact} contactData={data?.contacts} />,
    },
    {
      title: 'Vehicle Details',
      content: <VehicleList saveVehicle={saveVehicle} messageApi={messageApi}
        vehicleData={data?.vehicles} />,
    },
    {
      title: 'Password',
      content: <Password 
        savePassword={savePassword}
        saveCPassword={saveCPassword}
        pass={data?.password}
        cpass={data?.confirm_password} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const registerAffiliateData = () => {
    if (!data?.password) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Password is required!',
      });
      return;
    } else if (!IsStrongPassword(data?.password)) {
      messageApi.open({
        key,
        type: 'error',
        content: "Password should be minimum of 8 characters and cannot contain whitespace.",
      });
      return;
    } else if (data?.password !== data?.confirm_password) {
      messageApi.open({
        key,
        type: 'error',
        content: "Passwords don't match.",
      });
      return;
    }
    setLoading(true);
    // const payload = {
    //   "name": "Max mark 1",
    //   "website": "localhost.com",
    //   "email": "maxmark1@gmailt.com",
    //   "phone_number": "+1 8069787857",
    //   "dot_number": "",
    //   "is_cross_state_insured": false,
    //   "address": {
    //     "street_address": "Killen max mark",
    //     "zipcode": "35645"
    //   },
    //   "contacts": [
    //     {
    //       "is_primary": true,
    //       "name": "max primary",
    //       "email": "maxprimary1@pgmailt.com",
    //       "phone_number": "+1 8064548688"
    //     },
    //     {
    //       "is_primary": false,
    //       "name": "max secondary 1",
    //       "email": "maxsecnodary11@gmailt.com",
    //       "phone_number": "+1 8064548688"
    //     },
    //     {
    //       "is_primary": false,
    //       "name": "max secondary 2",
    //       "email": "maxsecnodary21@gmailt.com",
    //       "phone_number": "+1 8064548688"
    //     }
    //   ],
    //   "vehicles": [
    //     {
    //       "category": "charter_bus",
    //       "passenger_count": "1",
    //       "color": "white",
    //       "seat_fabric": "cloth",
    //       "status": "available"
    //     }
    //   ]
    // };


    // Patch for phone number which append +1
    data.phone_number = FormatPhoneNumberForServer(data.phone_number);
    data.contacts.forEach((contact) => {
      contact.phone_number = FormatPhoneNumberForServer(contact.phone_number);
    });

    RegisterAffiliate(data).then((response) => {
      if (response.code === 200) {
        messageApi.success({ content: 'Affiliate added successfully.', key, duration: 2 });
        setLoading(false);
        setTimeout(() => {
          navigate(`/affiliate/${response.data.affiliate_id}`);
        }, 2000);
      }
    }).catch((e) => {
      messageApi.open({
        key,
        type: 'error',
        content: e.message,
      });
      setLoading(false);
    });
  }

  return (
    <div className='add-affiliate-container'>
      {contextHolder}
      <BackBtn />
      <div className='add-affiliate-section'>
        <Steps current={current} items={items} />
        <div>{steps[current].content}</div>
        <div
          style={{
            marginTop: 24,
          }}
        >
          {current > 0 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={registerAffiliateData} loading={loading}>
              Done
            </Button>
          )}

        </div>
      </div>
    </div >
  );
};

export default AddAffiliate;
