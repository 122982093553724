// Import Module
import React, { useEffect, useState } from 'react';
import { Button, Input, Form, Col, message } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import SideCarousel from '../components/side_carousel';
import { SetCookie, GetCookie, ValidateEmail } from '../services/helper';
import { LoginAPI } from '../services/api';
import logo from '../assets/images/price4limo-logo.jpg';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';

  useEffect(() => {
    const accessToken = GetCookie('AccessToken');
    if (accessToken) {
      navigate('/leads');
    }
  }, [navigate]);

  const onLogin = () => {
    if (!ValidateEmail(email) || password.length < 5) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Email or Password seems to be invalid.',
      });
    } else {
      const data = {
        email: email.trim(),
        password: password,
        is_admin: true
      };
      // messageApi.open({
      //   key,
      //   type: 'loading',
      //   content: 'Loading...',
      // });
      setLoading(true);
      LoginAPI(data)
        .then((d) => {
          setLoading(false);
          if (d.code === 200) {
            const { token, refreshToken } = d?.data?.tokens;
            const { name } = d?.data?.user_details;
            messageApi.open({
              key,
              type: 'success',
              content: `Welcome ${name}!`,
            });
            if (token) {
              SetCookie('AccessToken', token, 30);
              SetCookie('RefreshToken', refreshToken, 30);
              SetCookie('User', name, 30);
              setTimeout(() => {
                navigate('/leads');
              }, 1000);
            }
          } else {
            messageApi.open({
              key,
              type: 'error',
              content: 'Email or Password seems to be invalid.',
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          messageApi.open({
            key,
            type: 'error',
            content: e.message,
          });
        });
    }
  };

  return (
    <div className='auth-wrapper'>
      <SideCarousel />
      {contextHolder}
      <Col className='auth-section'>
        <Form>
          <div className='login-logo-wrapper'>
            <img src={logo} alt='price 4 limo' className='login-logo' />
          </div>
          <h1>Log In</h1>
          <div className='mb-3'>
            <label>Email address</label>
            <Input
              placeholder='Enter your email'
              size='large'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={48}
              prefix={<MailOutlined className='site-form-item-icon' />}
            />
          </div>
          <div className='mb-3'>
            <label>Password</label>
            <Input.Password
              placeholder='Enter your password'
              size='large'
              className='form-control'
              value={password}
              maxLength={48}
              onChange={(e) => setPassword(e.target.value)}
              prefix={<LockOutlined className='site-form-item-icon' />}
            />
          </div>
          <div className='mb-3 flex-separate'>
            <div className='custom-control custom-checkbox'>
              {/* <Checkbox />
              <label className="custom-control-label" htmlFor="customCheck1">Remember me</label> */}
            </div>
            <p className='forgot-password text-right'>
              Forgot <a href='/forgot-password'>password?</a>
            </p>
          </div>
          <Button
            type='primary'
            block={true}
            loading={loading}
            className='btn btn-primary'
            size='large'
            onClick={onLogin}
          >
            Submit
          </Button>
        </Form>
      </Col>
    </div>
  );
};

export default Login;
