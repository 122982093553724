// Import Module
import React, { useEffect, useRef, useState } from 'react';
import { Button, Table, Tag, Input, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { GetAffiliates } from '../services/api';
import { GetTagColor, GetEnumKey, FormatPhone, GetClickableLink, FormatDate } from '../services/helper';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  SearchOutlined,
} from '@ant-design/icons';
import { Status, VehicleCategory } from '../constants';
import SmsModal from '../components/sms_modal';
import EmailModal from '../components/email_modal';

const Affiliates = () => {
  const navigate = useNavigate();
  const { affiliateStatus } = useParams();
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState(null);

  /* eslint-disable-next-line */
  const [searchText, setSearchText] = useState('');
  /* eslint-disable-next-line */
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [isSmsModelOpen, setIsSmsModelOpen] = useState(false);
  const [isEmailModelOpen, setIsEmailModelOpen] = useState(false);
  const [triggerId, setTriggerId] = useState(0);
  const [smsNo, setSmsNo] = useState('');
  const [email, setEmail] = useState('');

  const [tableParams, setTableParams] = useState({
    pagination: {
      total: 1,
      current: 1,
      pageSize: 50,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
    getAffiliatesData({
      pagination,
      filters,
      sorter,
    });
  };

  const linkClick = (website, event) => {
    window.open(GetClickableLink(website), '_blank');
    event.stopPropagation();
    event.preventDefault();
    return false;
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
      // record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const preventClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
      width: 80,
    },
    {
      title: 'Company Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      ...getColumnSearchProps('name'),
      width:250
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ...getColumnSearchProps('address'),
      width:250
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
      ...getColumnSearchProps('phone_number'),
      render: (_, { phone_number, id }) => (
        <p 
          className='cursor-pointer'
          onClick={(e)=> {
            setTriggerId(id);
            setSmsNo(phone_number);
            setIsSmsModelOpen(true);
            preventClick(e);
        }}>
        {phone_number}</p>
      ),
      width: 150
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
      width: 200,
      render: (_, { email, id }) => (
        <p 
          className='cursor-pointer'
          onClick={(e)=> {
            setTriggerId(id);
            setEmail(email);
            setIsEmailModelOpen(true);
            preventClick(e);
        }}>
          {email}
        </p>
      ),
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      render: (_, { website }) => (
        <a
          href={GetClickableLink(website)}
          rel='noreferrer'
          className='big-website-link'
          target='_blank'
          onClick={(e) => linkClick(website, e)}
        >
          {website}
        </a>
      ),
      ...getColumnSearchProps('website'),
      width: 250
    },
    {
      title: 'Sign up at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => a.created_at - b.created_at,
      render: (_, { created_at }) => (
        <p>{FormatDate(created_at)}</p>
      ),
      width: 180,
      hidden: (affiliateStatus !== 'pending')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => (
        <Tag color={status.color} key={status.tag}>
          {status.tag.toUpperCase()}
        </Tag>
      ),
      sorter: (a, b) => a.status - b.status,
      filterMultiple: false,
      filters: (affiliateStatus) ? false : [
        {
          text: Status.approved,
          value: GetEnumKey(Status, Status.approved),
        },
        {
          text: Status.pending,
          value: GetEnumKey(Status, Status.pending),
        },
        {
          text: Status.rejected,
          value: GetEnumKey(Status, Status.rejected),
        },
      ],
      width: 150,
    },
    {
      title: 'DOT #',
      dataIndex: 'dot_number',
      key: 'dot_number',
      sorter: (a, b) => a.dot_number - b.dot_number,
      width: 100,
    },
    {
      title: 'Insured',
      dataIndex: 'insured',
      key: 'insured',
      width: 100,
      render: (_, { insured }) => (
        <>
          {insured ? (
            <CheckCircleTwoTone
              className='site-form-item-icon insurance-icon'
              twoToneColor='#52c41a'
            />
          ) : (
            <CloseCircleTwoTone
              className='site-form-item-icon insurance-icon'
              twoToneColor='#eb2f96'
            />
          )}
        </>
      ),
    },
    {
      title: 'Pricing Label',
      dataIndex: 'pricing_label',
      key: 'pricing_label',
      width: 150,
    },
    {
      title: '',
      dataIndex: 'view',
      key: 'view',
      render: (_, { id }) => (
        <Button
          onClick={() => {
            navigate(`/affiliate/${id}`);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getAffiliatesData = (tableParams) => {
    let params = `per_page_rows=${tableParams.pagination.pageSize}&current_page=${tableParams.pagination.current}`;
    if (tableParams.sorter?.columnKey) {
      params += '&sort_by=' + tableParams.sorter.columnKey;
      if (tableParams.sorter?.order) {
        const order = tableParams.sorter.order === 'ascend' ? 'ASC' : 'DESC';
        params += '&order_by=' + order;
      }
    }
    if (tableParams.filters) {
      Object.keys(tableParams.filters).forEach((key) => {
        if (tableParams.filters[key]) {
          const _val = tableParams.filters[key][0];
          params += `&${key}=${_val}`;
        }
      });
    }
    if (affiliateStatus) {
      params += `&status=${affiliateStatus}`;
    }
    setLoading(true);
    GetAffiliates(params)
      .then((data) => {
        let mappingData = [];
        data?.data?.affiliates.forEach((d) => {
          const tagColor = GetTagColor(Status[d.status]);
          let obj = {
            name: d.name,
            id: d.id,
            key: d.id,
            address: `${d.address?.city}, ${d.address?.state}`,
            phone_number: FormatPhone(`${d.phone_number}`),
            email: d.email,
            website: d.website,
            created_at: d.created_at,
            dot_number: d.dot_number,
            insured: d.is_cross_state_insured,
            status: {
              tag: d.status,
              color: tagColor,
            },
            pricing_label: d.pricing_label,
            vehicles: d.vehicles,
          };
          mappingData.push(obj);
        });
        setDataSource(mappingData);
        setLoading(false);
        //Set pagination
        const pg = data.data.pagination;
        let pagination = {
          total: pg.total_items,
          current: pg.current_page,
          pageSize: parseInt(pg.per_page_rows),
        };
        // if (tableParams.pagination.current === 1) {
        const { filters, sorter } = tableParams;
        setTableParams({ pagination, filters, sorter });
        // }
      })
      .catch((e) => {
        // console.log('error', e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAffiliatesData(tableParams);
  }, [affiliateStatus]); // eslint-disable-line

  return (
    <div className='p-ant-table-container'>
      <Button type="primary" size="medium" 
        className="btn float-right create-aff-btn"
        onClick={() => {navigate('/affiliate/add')}}>Create New Affiliate </Button>
      
      <Table
        dataSource={dataSource}
        fixed={'top'}
        columns={columns}
        rowClassName={(record, index) =>
          `affiliate-row ${record.status.tag} ${affiliateStatus === 'pending'? 'only-pending' : ''} tbl-${record.status.color}-row`
        }
        pagination={tableParams.pagination}
        onChange={handleTableChange}
        scroll={{
          x: 1900,
        }}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/affiliate/${record.id}`);
            }, // click row
          };
        }}
        expandable= {{
          // expandRowByClick: false,
          defaultExpandAllRows: false, //(affiliateStatus ==='pending'), // show expandable rows only in pending affiliates
          expandedRowClassName: (record, index) =>
            `affiliate-exp-row ${record.status.tag} tbl-${record.status.color}-row`,
          fixed: false,
          columnWidth: 0,
          showExpandColumn: (affiliateStatus ==='pending'),
          expandedRowRender: (record) => ( 
            <div className='exp-row-container'>
            {record.vehicles.slice(0, 9).map((v, index) => <p key={`aff-exp-${record.id}-veh-${index}`} className='aff-exp-vehicle'>
              {VehicleCategory[v.category]} | {v.passenger_count } passenger{(v.passenger_count > 1) ? 's' : ''}
            </p>)}
            </div>
          ),
        }}
      />

      {isSmsModelOpen && <SmsModal 
        isModalOpen={isSmsModelOpen}
        setIsModalOpen={setIsSmsModelOpen}
        msgType="affiliate"
        phoneNumber={smsNo}
        id={triggerId}
      />}

      {isEmailModelOpen && <EmailModal 
        isModalOpen={isEmailModelOpen}
        setIsModalOpen={setIsEmailModelOpen}
        msgType="affiliate"
        emailAddress={email}
        id={triggerId}
      />}
    </div>
  );
};

export default Affiliates;
