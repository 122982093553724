// Import Module
import React from 'react';

import Leads from './leads';

const SentToAgent = () => {
  return (
    <Leads 
      isOnlyIncomplete={false} 
      isOnlyUnread={false} 
      showDashboard={false}
      isOnlySentToAgent={true} />
  )
};

export default SentToAgent;