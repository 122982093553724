// Import Module
import React, { useState, useEffect } from "react";
import { Modal, Button, Input, message, Select } from "antd";
import { SendMail, GetTemplates } from "../services/api";

const { TextArea } = Input ;
const EmailModal = ({
  isModalOpen,
  setIsModalOpen,
  emailAddress,
  msgType="affiliate",
  id,
}) => {
  const [email, setEmail] = useState(emailAddress); //eslint-disable-line
  const [msg, setMsg] = useState("");
  const [subject, setSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailTemplates, setemailTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  useEffect(()=>{
    GetTemplates('email').then((d) => {
      if (d.code === 200) {
        setemailTemplates(d.data.templates);
      }
    });
  }, []);

  const send = () => {
    // validate vehicle
    if (!msg || msg.trim() === ""){
      messageApi.open({
        key,
        type: "error",
        content: `Message is required.`,
      });
      return false;
    } else if(!subject || subject.trim() === ""){
      messageApi.open({
        key,
        type: "error",
        content: `Subject is required.`,
      });
      return false;
    } else {
      let obj = {
        to_user_email: email,
        subject: subject,
        message: msg,
        message_type: msgType,
        id: id,
      };
      
      setIsLoading(true);
      SendMail(obj).then((d) => {
        setIsLoading(false);
        if (d.code === 200) {
          messageApi.open({
            key,
            type: "success",
            content: `Email sent successfully.`,
          });
        } else {
          messageApi.open({
            key,
            type: "error",
            content: `Failed to send an email.`,
          });
        }
        // timeout to show message and close modal
        setTimeout(() => {
          setIsModalOpen(false);
        }, 1000);
      }).catch((e) => {
        setIsLoading(false);
        messageApi.open({
          key,
          type: "error",
          content: `Failed to send an email.`,
        });
      });
    }
  };

  return (
    <Modal
      title="Send Email"
      open={isModalOpen}
      centered
      width={600}
      // okText={'Yes'} cancelText={'No'} onOk={statusUpdate}
      onCancel={() => setIsModalOpen(false)}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => {
            send();
          }}
        >
          Send
        </Button>,
      ]}
    >
      <div>
        {contextHolder}
        <Input value={email} className="mt-10" disabled={true} />
        <Input 
          value={subject} 
          placeholder="Enter subject"
          maxLength={50}
          onChange={(e)=> setSubject(e.target.value)}
          className="mt-10"/>
        <TextArea 
          value={msg}
          rows={4}
          className="mt-10"
          placeholder="Enter body"
          maxLength={5000}
          onChange={(e) => setMsg(e.target.value)}
          size={'large'}
          style={{
            height: 120,
            resize: 'none',
          }}
        />
        
        <Select
          value={selectedTemplate}
          placeholder='Insert Template'
          onChange={(v, o) => {
            setSelectedTemplate(o.label);
            const content = emailTemplates.find(s => s.id === v).content;
            setMsg(content.body);
            setSubject(content.subject);
          }}
          className='mt-10 w-100'
          options={emailTemplates.map((t) => ({ label: t.content.subject, value: t.id }))}
        />
      </div>
    </Modal>
  );
};

export default EmailModal;
