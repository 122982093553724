import dayjs from 'dayjs';
import 'dayjs/plugin/relativeTime';

import { Status, Events, TripType, VehicleCategory, VT } from '../constants';

export const GetCookie = (cName) => {
  const name = `${cName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const ValidateEmail = (email) => {
  const _emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/;
  // const _emailRegex =
  // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return _emailRegex.test(email.trim());
};

export const ValidateURL = (url) => {
  // Regular expression for a valid URL format
  const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;

  // Test the URL against the regex
  return urlRegex.test(url);
};

export const ValidatePhone = (phone) => {
  // Remove the +1 prefix
  phone = phone.replace('+1 ', '');

  if(phone === '000-000-0000' || phone === '0000000000' || 
    phone === '111-111-1111' || phone === '1111111111') {
    return false;
  }
  phone = phone.replaceAll('-', '');
  // Regular expression for a valid Phone format
  const phoneRegex = /^[2-9]\d{9}$/;

  // Test the URL against the regex
  return phoneRegex.test(phone);
};

export const FormatPhone = (phone) => {
  phone = phone.replace('+1 ', '');
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const ValidateZIP = (url) => {
  // Regular expression for a valid ZIP format
  const urlRegex = /^\d{5}?$/;

  // Test the URL against the regex
  return urlRegex.test(url);
};


export const SetCookie = (name, value, days = 30) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

export const RemoveCookie = function (name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const GetTagColor = (tag) => {
  switch (tag) {
    case Status.active:
      return 'success';

    case Status.available:
      return 'success';

    case Status.pending:
      return 'processing';

    case Status.rejected:
      return 'red';

    case Status.approved:
      return 'success';

    case Status.unread:
      return 'yellow';

    case Status.open:
      return 'processing';

    case Status.sent_for_quote:
      return 'orange';

    case Status.quote_received:
      return 'blue';

    case Status.hired:
      return 'green';

    case Status.archived:
      return 'red';

    case Status.incomplete:
      return 'magenta';

    case Status.low_budget:
      return 'volcano';

    case Status.deleted:
      return 'red';

    case Status.sent_to_agent:
      return 'yellow';

    default:
      return 'default';
  }
};

export const GetEnumKey = (data, value) => {
  const keys = Object.keys(data);
  return keys.find((key) => data[key] === value);
};

export const GetOptionForEnum = (data) => {
  return Object.keys(data).map(key => ({
    text: data[key],
    value: key
  }));
};


export const GetOptionForOnlyValue = (data) => {
  return Object.keys(data).map(key => ({
    // text: data[key],
    value: data[key]
  }));
};

export const BeautifyNumber = (number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'K';
  } else {
    return number.toString();
  }
}

export const formatAMPM = (date) => {
  let minutes = date.getMinutes()
  minutes = minutes < 10 ? '0' + minutes : minutes
  return `${(date.getHours() % 12) || 12}:${minutes} ${date.getHours() < 12 ? 'AM' : 'PM'}`
}

export const FormatDate = (timestamp, returnObject=false) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  // const hours = date.getHours().toString().padStart(2, '0');
  // const minutes = date.getMinutes().toString().padStart(2, '0');
  const AMPMTime = formatAMPM(date);
  const defaultString = `${month}-${day}-${year} ${AMPMTime}`;

  // Passed a flag to return date object as it is needed at some places
  // By default this is set to false so it doesn't have to be passed at all places
  if(returnObject){
    const dateTimeObject = {
      "date": `${month}-${day}-${year}`,
      "time": `${AMPMTime}`
    }
    return dateTimeObject;
  }
  else return defaultString;
}

export const FormatDateWithoutTime = (timestamp) => {
  const date = new Date(timestamp);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

export const FindUniqueAffiliateName = (affiliates) => {
  return Array.from(new Set(affiliates.map((item) => item.name)));
}

export const GetTotalAffiliatesAndRequests = (data) => {
  const uniqueAffiliates = new Set();
  const uniqueVehicleIds = new Set();
  let totalVehicles = 0;
  const vehiclesPerAffiliate = {};

  data.forEach(affiliate => {
    const affiliateName = affiliate.name;
    uniqueAffiliates.add(affiliateName);

    // This loop is needed as backend is sending all request in each of the vehicles currently
    affiliate.vehicles.forEach(vehicle => {
      if (vehicle.id && !uniqueVehicleIds.has(vehicle.id)) {
        uniqueVehicleIds.add(vehicle.id);
      }
    });
    const vehiclesCount = affiliate.vehicles.length;
    // totalVehicles = affiliate.vehicles.length;
    totalVehicles = uniqueVehicleIds.size;
    if (vehiclesPerAffiliate[affiliateName]) {
      vehiclesPerAffiliate[affiliateName] = vehiclesPerAffiliate[affiliateName] + vehiclesCount;
    } else {
      vehiclesPerAffiliate[affiliateName] = vehiclesCount;
    }
  });

  return {
    totalAffiliates: uniqueAffiliates.size,
    totalRequests: totalVehicles,
    requestsPerAffiliate: vehiclesPerAffiliate
  };
};

export const GetTotalAffiliatesAndResponses = (data) => {
  const uniqueAffiliates = new Set();
  let totalVehicles = 0;
  const vehiclesPerAffiliate = {
  };

  data.forEach(affiliate => {
    const affiliateName = affiliate.name;
    let tagColor = 'blue' //default color
    uniqueAffiliates.add(affiliateName);
    let responsesCounter = affiliate.vehicles_with_quotes;
    const vehiclesCount = affiliate.vehicles;
    let sentToAgent = {};
    // totalVehicles = affiliate.vehicles.length;
    totalVehicles += vehiclesCount;

    // Sorting the array based on the 'hours' field
    affiliate?.responses?.sort((a, b) => a.hours - b.hours);

    affiliate?.responses?.forEach(response => {
      if(response.is_not_available !== null){
        tagColor = (response.is_not_available === true) ? 'red' : 'green';
      } 
      if(response.is_sent_to_agent === false && 
          (response.quotation ||response.is_not_available === true)){
        let str = 'New Quote For ';
        let priceStr = "";
        if(response.category){
          str += VehicleCategory[response.category];
        }
        // Temporary they are not needed as they are on leads details
        // if(response.color){
        //   str +=  ' | ' + VehicleColor[response.color];
        // }
        // if(response.seat_fabric){
        //   str += ' | ' + VehicleSeat[response.seat_fabric];
        // }
        // if(response.passengers_count){
        //   str += ' | ' + response.passengers_count + ' PAX';
        // }
        if(response.hours && response.quotation){
          let _str = FormatTripHours(response.hours) + ' @ $' + response.quotation;
          if(!response.is_included_in_customer_link){
            _str +='Never sent in link';
          } 
          priceStr = _str;
        } else if(response.is_not_available === true){
          priceStr = 'Not interested / available';
        }

        if(sentToAgent[response.vehicle_id]){
          const oldPriceStr = sentToAgent[response.vehicle_id].priceStr;
          oldPriceStr.push(priceStr);
          sentToAgent[response.vehicle_id] = {str,priceStr: oldPriceStr};
        } else {
          sentToAgent[response.vehicle_id] = {str, priceStr: [priceStr]};
        }
      }
    });

    vehiclesPerAffiliate[affiliateName] = {
      vehiclesCount: vehiclesCount,
      tagColor: tagColor,
      responses: responsesCounter,
      sentToAgent: sentToAgent, 
    };
  });

  return {
    totalAffiliates: uniqueAffiliates.size,
    totalRequests: totalVehicles,
    requestsPerAffiliate: vehiclesPerAffiliate
  };
};

export const ConvertTo12HourFormat = (timeString) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(':').map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  let hours12 = hours % 12;
  hours12 = hours12 || 12; // 12:00 should remain 12, not 0

  // Format hours and minutes with leading zeros if necessary
  const hoursStr = hours12; // String(hours12).padStart(2, '0');
  const minutesStr = String(minutes).padStart(2, '0');

  // Concatenate hours, minutes, and period
  return `${hoursStr}:${minutesStr} ${period}`;
}

export const GetMinMaxPAX = (PAX) => {
  // https://trello.com/c/DAh6GIrC/190-pax-logic-update
  if(PAX >= 56) {
    return { min: 25, max: 200 };
  }
  else if(PAX >= 25) {
    return { min: PAX - 15, max: 200 };
  }
  else if (PAX > 15) {
    return { min: PAX - 15, max: PAX + 15 };
  } 
  else {
    return { min: 1, max: PAX + 15 };
  }
}

export const ToggleZoom = () => {
  const zoomLevel = GetCookie('Zoom');
  const isSentToAgent = (window.location.pathname === '/sent-to-agent')
  if (!zoomLevel || zoomLevel === '100') {
    if(isSentToAgent){
      SetCookie('Zoom', '75');
    } else {
      SetCookie('Zoom', '80');      
    }
  } else {
    RemoveCookie('Zoom');
  }
  SetBrowserZoom();
};

export const SetBrowserZoom = () => {
  const zoomLevel = GetCookie('Zoom');
  if (zoomLevel && zoomLevel !== '100') {
    const isSentToAgent = (window.location.pathname === '/sent-to-agent')
    if(isSentToAgent){
      document.body.classList.remove("zoom-in");
      document.body.classList.add("zoom-in-2");
    } else {
      document.body.classList.add("zoom-in");
      document.body.classList.remove("zoom-in-2");
    }
  } else {
    document.body.classList.remove("zoom-in");
    document.body.classList.remove("zoom-in-2");
  }
}

export const GenerateCopyToClipBoardData = (vehicleData, leadData) => {
  let vehicleInfo = '';
  // Blank array setup it the vehicles are not present
  if(vehicleData === null ) vehicleData = [];
  for (let i = 0; i < vehicleData.length; i++) {
    vehicleInfo += `Company: ${vehicleData[i].company}
Contact: ${vehicleData[i].affiliateEmail}, ${FormatPhone(vehicleData[i].affiliatePhone)}
Address: ${vehicleData[i].address}
Vehicle: ${vehicleData[i].vehicle}  
Quote: ${vehicleData[i].price} 
----
`;
  }

  const text = `Customer Email: ${leadData.customer_email}
Customer Phone: ${leadData.customer_phone_number}

Pick Up: ${leadData.pickup_city}, ${leadData.pickup_state} ${!!leadData.pickup_zipcode ? ","+leadData.pickup_zipcode : ""}
Pick Date and Time: ${dayjs(leadData.pickup_date).format('MM-DD-YYYY')} ${ConvertTo12HourFormat(leadData.pickup_time)}
Drop Off: ${leadData.dropoff_city}, ${leadData.dropoff_state} ${!!leadData.dropoff_zipcode ? ","+leadData.dropoff_zipcode : ""}
${!!leadData.return_pickup_date && !!leadData.return_pickup_time ? `Return Date and Time: ${dayjs(leadData.return_pickup_date).format('MM-DD-YYYY')} ${ConvertTo12HourFormat(leadData.return_pickup_time)}` : ""}

Event Type: ${Events[leadData.trip_event]}
Trip Type: ${TripType[leadData.trip_type]}
Trip Hours: ${leadData.trip_hours}
Passengers: ${leadData.passenger_count}

Trip Details: ${leadData.trip_details}

Lead Source: ${leadData.source}
Admin Note: ${leadData.admin_note}

---------------
Affiliate Details

${vehicleInfo}`
  return text;
}

export const FormatDateWithDay = (inputDate) => {
  // Parse input date
  const [month, day, year] = inputDate.split('-').map(Number);

  // Create a new Date object
  const dateObj = new Date(year, month - 1, day);

  // Array of month names
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

  // Array of day names
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  // Get day, month name, day of month, and year
  const dayName = dayNames[dateObj.getDay()];
  const monthName = monthNames[dateObj.getMonth()];
  const dayOfMonth = dateObj.getDate();
  const formattedYear = dateObj.getFullYear();

  // Construct formatted date string
  const formattedDate = `${dayName} ${monthName} ${dayOfMonth}, ${formattedYear}`;

  return formattedDate;
}


// Function to format phone number for sending to server (add country code)
export const FormatPhoneNumberForServer = (phoneNumber) => {
  // Assuming phone number format: XXX-XXX-XXXX
  return (phoneNumber.startsWith('+1')) ? phoneNumber : `+1 ${phoneNumber}`;
};

export const IsStrongPassword = (password) => {
  // Regular expression to check for the specified criteria
  const passwordRegex =
    /^(?!.* ).{8,}$/;
    

  // Test the password against the regex
  return passwordRegex.test(password);
};

export const FormatTripHours = (hours) => {
  let value = '';
  switch (hours) {
    case 'one_way_transfer':
      value = "One Way";
      break;
    case 'multiple_day_usage':
      value = "Multi Days";
      break;
    case 'trip_1':
      value = "Trip 1";
      break;
    case 'trip_2':
      value = "Trip 2";
      break;
    // In old build it's received as null so to handle it add null case
    case null:
      value = 'One Way/ Multi';
      break;
    default: 
      value = `${hours} Hrs`
  }
  return value;
}

export const GetTimeDifference = (timestamp) => {
  dayjs.extend(require('dayjs/plugin/relativeTime'));
  const date = dayjs(timestamp);
  const current = dayjs();

  const daysDifference = current.diff(date, 'days');

  if (daysDifference <= 7) {
      return date.fromNow({ includeSeconds: true, suffix: true }) + ' ago';
  } else {
      return date.format('MM-DD-YYYY');
  }
};

export const GetTimeDifferenceViaDateTime = (dateString, timeString) => {
  // Parse the date and time strings using Day.js
  var customDateTime = dayjs(dateString, 'MM-DD-YYYY');
  // var customDateTime = dayjs(dateString + ' ' + timeString, 'MM-DD-YYYY HH:mm A');
  return GetTimeDifferenceInHours(customDateTime);
}

export const GetTimeDifferenceInHours = (timestamp) => {
  const date = dayjs(timestamp);
  const current = dayjs();

  const hoursDifference = current.diff(date, 'hours');
  return hoursDifference + 1; // Add 1 to make it round off to next hour
};

export const GetTimeDifferenceInDays = (timestamp) => {
  const date = dayjs(timestamp);
  const current = dayjs();

  const daysDifference = date.diff(current, 'days');
  return daysDifference + 1; // Add 1 to make it round off to next day
};

// Function to format phone number for display (remove country code)
export const FormatPhoneNumberForDisplay = (phoneNumber) => {
  // Assuming phone number format: +1-XXX-XXX-XXXX
  return phoneNumber? phoneNumber.replace('+1 ', '') : '';
};

export const GetClickableLink = link => {
  return link.startsWith("http://") || link.startsWith("https://") ?
    link
    : `http://${link}`;
};

export const PrevNextBntHelper = (url) =>{
  let current_box = '';
  switch (url) {
    case '/leads':
      current_box= 'new_leads';
      break;

    case '/other-leads':
      current_box= 'incomplete_leads';
      break;

    case '/all-leads':
      current_box= 'all_leads';
      break;

    case '/current-leads':
      current_box= 'current_leads';
      break;

    case '/requests':
      current_box= 'awaiting_responses';
      break;

    case '/responses':
      current_box= 'quotes_received';
      break;
    
    case '/sent-to-agent':
      current_box= 'sent_to_agent';
      break;

    default:
      current_box = 'all_leads';
      break;
  }

  return current_box;
}

const ExtractPathKey = (path) => {
  return path.substr(1, path.length);
}

export const SaveFilterIntoLocalStorage = (pathKey, data) => {
  const filterData = {
    filter: data
  };
  localStorage.setItem(ExtractPathKey(pathKey), JSON.stringify(filterData));
}

export const GetFilterFromLocalStorage = (pathKey) => {
  const _pathKey = ExtractPathKey(pathKey);
  if (!localStorage.getItem(_pathKey)) {
    return null;
  }
  return JSON.parse(localStorage.getItem(_pathKey)).filter;
}

export const SaveLeadIdIntoLocalStorage = (pathKey, leadId) => {
  localStorage.setItem(`${ExtractPathKey(pathKey)}-leadId`, leadId);
}

export const GetLeadIdIntoLocalStorage = (pathKey, leadId) => {
  return localStorage.getItem(`${ExtractPathKey(pathKey)}-leadId`);
}

export const RemoveLeadIdFromLocalStorage = (pathKey) => {
  localStorage.removeItem(`${ExtractPathKey(pathKey)}-leadId`);
}

export const GetVT = (vt) => {
  return (vt && vt.length > 0) ? vt.map((r) => VT[r]).join(', ') : 'n/a';
}