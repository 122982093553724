// Import Module
import React from 'react';
import { Tag } from 'antd';
import { GetTagColor } from '../services/helper';

const TagRender = (props) => {
    let { label } = props;
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const tagColor = GetTagColor(label);
    return (
        <Tag
            color={tagColor}
            onMouseDown={onPreventMouseDown}
            style={{
                marginRight: 3,
                textTransform: 'uppercase',
            }}
        >
            {label}
        </Tag>
    );
};

export default TagRender;