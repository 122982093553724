// Import Module
import React from "react";
import { Spin } from "antd";
import { GetCookie } from "../services/helper";

const FullSpin = () => {
  const zoomLevel = GetCookie('Zoom');
  return (
    <Spin fullscreen className={`${zoomLevel === '80' ? 'ant-spin-fullscreen-custom': ''}`}/>
  );
};

export default FullSpin;
