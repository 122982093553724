// Import Module
import React from 'react';
import { elastic as Menu } from 'react-burger-menu';
import { NavLink } from 'react-router-dom';
import { message } from 'antd';
import { LogOutAdmin, GetUnreadResponseCount } from '../services/api.js';
import { BadgeCountContext } from '../context/unreadContext.js';


import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  ContactsOutlined,
  ProfileOutlined,
  PoweroffOutlined,
  QuestionCircleOutlined,
  AppstoreOutlined,
  RiseOutlined,
  TeamOutlined,
  ZoomInOutlined,
  ContactsFilled,
  CustomerServiceOutlined,
  DashboardOutlined,
  MailOutlined,
  MessageOutlined,
} from '@ant-design/icons';

import { GetCookie, RemoveCookie, ToggleZoom } from '../services/helper';
import Logo from '../assets/images/price4limo-logo.jpg';
import socket from '../socket.js';

class BurgerMenu extends React.Component {
  // Assign a contextType to read the current context
  static contextType = BadgeCountContext;

  constructor(props) {
    super(props);
    this.state = {
      isOpen: true, //reactLocalStorage.get('isOpen') === 'true',
      active: false,
      menuCollapse: false,
      areMenusOpen: false,
      user: '' 
    };
  }

  componentDidMount() {
    const user = GetCookie('User');
    this.setState({ user });
    
    // Make API call here to get unread badge count
    GetUnreadResponseCount().then((response) => {
      const { setValue } = this.context;
      // Update the badge count
      setValue(response.data.unread_quotations);
    });

    // Connecting socket events
    socket.connect();

    // Event on socket connection
    socket.on('connect', () => {
      // Socket connect
    });

    // Listen to the new quotes coming in
    socket.on('new_quotes', (newMessage) => {
      // If multiple hours quote is received then response will have that many counts but we need to show only as 1 count
      const { value, setValue } = this.context;
      // Update badge count ( Old + Add new )
      setValue(value + 1);
    }); 
  }

  logOutUser = () => {
    // Clear all cookies
    const accessToken = GetCookie('AccessToken');
    LogOutAdmin(accessToken).then((data) => {
      RemoveCookie('AccessToken');
      RemoveCookie('RefreshToken');
      RemoveCookie('User');
      RemoveCookie('Zoom');
      localStorage.clear();
      window.location = '/login';
    }).catch((e) => {
      message.error(e.message);
    });;
  };

  onClick = (e) => {
    e.preventDefault();
  };

  handleStateChange = (state) => {
    // reactLocalStorage.set('isOpen', state.isOpen);
    this.setState({ isOpen: state.isOpen });
    const burgerElement = document.getElementsByClassName('bm-burger-button');
    if (burgerElement) {
      burgerElement[0].classList.toggle('menu-push');
    }
    const element = document.getElementById('outer-container');
    element.classList.toggle('push-body');

    const { menuCollapse } = this.state;
    this.setState({
      menuCollapse: !menuCollapse,
    });
  };

  render() {
    const { isOpen, menuCollapse, areMenusOpen } = this.state;

    const { value } = this.context;

    /** ********** closeAllMenusOnEsc function is used for to solve Home & End keybord key not working issue ************ */

    const closeAllMenusOnEsc = (e) => {
      e = e || window.event;
      if (
        e.key === 'Home' ||
        e.keyCode === 37 ||
        e.key === 'End' ||
        e.keyCode === 35
      ) {
        this.setState({ areMenusOpen: false });
      }
    };

    return (
      <div className='menu-container'>
        <div className='menu-blury-container' />
        <Menu
          className={`my-menu ${menuCollapse ? 'collapse-on' : 'collapse-off'}`}
          customOnKeyDown={closeAllMenusOnEsc}
          isOpen={isOpen}
          isOpens={areMenusOpen}
          role='presentation'
          onStateChange={this.handleStateChange}
          noOverlay
          disableOverlayClick
          customBurgerIcon={<span>☰</span>}
        >
          <div className='logo-container' role='presentation'>
            <img
              alt='logo'
              className='img-responsive company-menu-logo'
              src={Logo}
            />
          </div>

          {isOpen ? (
            <div className='company-user-details'>
              <div className='company-user-name'>Hi, {this.state.user}</div>
            </div>
          ) : null}

          <NavLink
            // activeClassName="burger-menu-active"
            className='bm-item-list'
            // className={({ isActive }) => (isActive ? "burger-menu-active" : 'bm-item-list')}
            to='/leads'
          >
            <RiseOutlined className='site-form-item-icon' />
            <span className='nav-title' role='presentation'>
              New Leads
            </span>
          </NavLink>

          <NavLink className='bm-item-list' to='/responses' exact='true'>
            <CloudUploadOutlined className='site-form-item-icon' />
            <span className='nav-title'>Quotes Received </span>
            {value > 0 && <span className='badge-count'>{value}</span>}
          </NavLink>

          <NavLink className='bm-item-list' to='/sent-to-agent' exact='true'>
            <CustomerServiceOutlined className='site-form-item-icon' />
            <span className='nav-title'>Sent To Agent</span>
          </NavLink>

          <NavLink className='bm-item-list' to='/requests' exact='true'>
            <CloudDownloadOutlined className='site-form-item-icon' />
            <span className='nav-title'>Awaiting Responses</span>
          </NavLink>

          <NavLink className='bm-item-list' to='/other-leads' exact='true'>
            <QuestionCircleOutlined className='site-form-item-icon' />
            <span className='nav-title'>Incomplete Leads</span>
          </NavLink>

          <NavLink className='bm-item-list' to='/current-leads' exact='true'>
            <AppstoreOutlined className='site-form-item-icon' />
            <span className='nav-title'>Current Leads</span>
          </NavLink>

          <br/>

          <NavLink className='bm-item-list' to='/affiliates/approved' exact='true' reloadDocument={true}>
            <ContactsOutlined className='site-form-item-icon' />
            <span className='nav-title'>Approved Affiliates</span>
          </NavLink>

          <NavLink className='bm-item-list' to='/affiliates/pending' exact='true' reloadDocument={true}>
            <ProfileOutlined className='site-form-item-icon' />
            <span className='nav-title'>Pending Affiliates</span>
          </NavLink>

          <NavLink className='bm-item-list' to='/affiliates' exact='true' end reloadDocument={true}>
            <ContactsFilled className='site-form-item-icon' />
            <span className='nav-title'>All Affiliates</span>
          </NavLink>

          <NavLink className='bm-item-list' to='/agents' exact='true'>
            <TeamOutlined className='site-form-item-icon' />
            <span className='nav-title'>Agents</span>
          </NavLink>

          <br/>

          <NavLink className='bm-item-list' to='/templates/email' exact='true' reloadDocument={true}>
            <MailOutlined className='site-form-item-icon' />
            <span className='nav-title'>Email Templates</span>
          </NavLink>

          <NavLink className='bm-item-list' to='/templates/sms' exact='true' reloadDocument={true}>
            <MessageOutlined className='site-form-item-icon' />
            <span className='nav-title'>SMS Templates</span>
          </NavLink>

          <span className='bm-item-list hover-off-link' onClick={() => ToggleZoom()}>
            <ZoomInOutlined className='site-form-item-icon' />
            <span className='nav-title'>Toggle Zoom Mode</span>
          </span>

          <NavLink className='bm-item-list' to='/all-leads' exact='true'>
            <AppstoreOutlined className='site-form-item-icon' />
            <span className='nav-title'>All Leads</span>
          </NavLink>

          {process.env.REACT_APP_ENV === 'production' &&
          <NavLink className='bm-item-list' to='/locker-studio' exact='true'>
            <DashboardOutlined className='site-form-item-icon' />
            <span className='nav-title'>Analytics</span>
          </NavLink>}

          <br/>

          <span
            className='bm-item-list hover-off-link'
            id='logout'
            role='presentation'
            onClick={this.logOutUser}
          >
            <PoweroffOutlined className='site-form-item-icon' />
            <span className='nav-title'>Log Out</span>
          </span>
        </Menu>
      </div>
    );
  }
}

BurgerMenu.propTypes = {};

BurgerMenu.defaultProps = {};

// export default withRouter(BurgerMenu);
export default BurgerMenu;
