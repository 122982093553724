// Import Module
import React, { useState, useEffect } from 'react';
import { Button, Input, Form, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { ForgotPwd } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { ValidateEmail, GetCookie } from '../services/helper';

import SideCarousel from '../components/side_carousel';
import logo from '../assets/images/price4limo-logo.jpg';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const key = 'updatable';

  useEffect(() => {
    const accessToken = GetCookie('AccessToken');
    if (accessToken) {
      navigate('/leads');
    }
  }, [navigate]);

  const onSubmit = () => {
    if (!ValidateEmail(email)) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Email seems to be invalid.',
      });
    } else {
      const data = {
        email: email.trim(),
      };
      setLoading(true);
      ForgotPwd(data)
        .then((d) => {
          setLoading(false);
          if (d.code === 200) {
            messageApi.open({
              key,
              type: 'success',
              content:
                'You will receive an email with the reset password link.',
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          messageApi.open({
            key,
            type: 'error',
            content: e.message,
          });
        });
    }
  };

  return (
    <div className='auth-wrapper'>
      <SideCarousel />
      {contextHolder}
      <div className='auth-section'>
        <Form>
          <div className='login-logo-wrapper'>
            <img src={logo} alt='price 4 limo' className='login-logo' />
          </div>
          <h1>Forgot Password?</h1>
          <p>You will receive a reset password link on below email.</p>
          <div className='mb-3'>
            <label>Email address</label>
            <Input
              placeholder='Enter your email'
              size='large'
              value={email}
              maxLength={48}
              onChange={(e) => setEmail(e.target.value)}
              prefix={<MailOutlined className='site-form-item-icon' />}
            />
          </div>
          <br />
          <Button
            type='primary'
            block={true}
            className='btn btn-primary'
            onClick={onSubmit}
            size='large'
            loading={loading}
          >
            Submit
          </Button>
          <p className='back-to-login'>
            Back to <a href='/login'>login</a>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
