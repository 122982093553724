// Import Modules
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import PrivateRoute from './components/private_route';
import BurgerMenu from './components/burger_menu';

// All the scene of routing
import Login from './scenes/login';
import ForgotPassword from './scenes/forgot-password';
import ResetPassword from './scenes/reset-password';
import AffiliateResetPassword from './scenes/affiliate-reset-password';
import AffiliateLeadDetails from './scenes/affiliate-lead-details';
import Leads from './scenes/leads';
import LeadDetails from './scenes/lead-details';
import LeadQuote from './scenes/lead-quote';
import OtherLeads from './scenes/other-leads'; // incomplete leads
import AllLeads from './scenes/all-leads';
import CurrentLeads from './scenes/current-leads';
import Affiliates from './scenes/affiliates';
import AffiliateDetails from './scenes/affiliate-details';
import Requests from './scenes/requests';
// import Responses from './scenes/responses';
import ResponsesNew from './scenes/responses-new';
import SentToAgent from './scenes/sent-to-agent';
import Error404 from './scenes/error404';

import { Permission } from './components/authorization';
import { GetCookie, SetBrowserZoom } from './services/helper';
import Agents from './scenes/agents';
import AddAffiliate from './scenes/add-affiliate';
import LockerStudio from './scenes/locker-studio';
import Templates from './scenes/templates';

const LeadsComponent = Permission.Admin(Leads);
const OtherLeadsComponent = Permission.Admin(OtherLeads);
const LeadDetailsComponent = Permission.Admin(LeadDetails);
const RequestsComponent = Permission.Admin(Requests);
const ResponsesComponent = Permission.Admin(ResponsesNew);
const LeadQuoteComponent = Permission.Admin(LeadQuote);
const AffiliatesComponent = Permission.Admin(Affiliates);
const AffiliateDetailsComponent = Permission.Admin(AffiliateDetails);
const AddAffiliateComponent = Permission.Admin(AddAffiliate);
const AgentsComponent = Permission.Admin(Agents);
const SentToAgentComponent = Permission.Admin(SentToAgent);
const LockerStudioComponent = Permission.Admin(LockerStudio);
const TemplatesComponent = Permission.Admin(Templates);

const RouteList = () => {
  // class RouteList extends React.Component {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const accessToken = GetCookie('AccessToken');
    // If Cookie contains Access_Token or Refresh Token then access website
    if (accessToken) {
      setIsLoggedIn(true);
    }
    // If Cookie is not there then redirect it to login page
    else {
      setIsLoggedIn(false);
    }

    SetBrowserZoom();
  }, [location]);

  return (
    <>
      {isLoggedIn && <BurgerMenu />}
      <div id={isLoggedIn ? 'outer-container' : ''}>
        <Routes>
          <Route exact path='/forgot-password' element={<ForgotPassword />} />
          <Route exact path='/' element={<Login />} />
          <Route exact path='/login' element={<Login />} />
          <Route
            exact
            path='/reset-password/:token'
            element={<ResetPassword />}
          />
          <Route
            exact
            path='/affiliate/reset-password/:token'
            element={<AffiliateResetPassword />}
          />
          <Route
            exact
            path='/affiliate/reset-password-web/:token'
            element={<ResetPassword />}
          />
          <Route
            exact
            path='/affiliate/lead/:leadId'
            element={<AffiliateLeadDetails />}
          />
          {isLoggedIn && (
            <>
              <Route exact path='/leads' element={<PrivateRoute />}>
                <Route exact path='/leads' element={<LeadsComponent />} />
              </Route>
              <Route exact path='/lead/:id' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/lead/:id'
                  element={<LeadDetailsComponent />}
                />
              </Route>
              <Route
                exact
                path='/lead/:id/request-quote'
                element={<PrivateRoute />}
              >
                <Route
                  exact
                  path='/lead/:id/request-quote/:miles?'
                  element={<LeadQuoteComponent />}
                />
              </Route>
              <Route exact path='/other-leads' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/other-leads'
                  element={<OtherLeadsComponent />}
                />
              </Route>
              <Route exact path='/all-leads' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/all-leads'
                  element={<AllLeads />}
                />
              </Route>
              <Route exact path='/current-leads' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/current-leads'
                  element={<CurrentLeads />}
                />
              </Route>
              
              <Route exact path='/requests' element={<PrivateRoute />}>
                <Route exact path='/requests' element={<RequestsComponent />} />
              </Route>
              <Route exact path='/responses' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/responses'
                  element={<ResponsesComponent />}
                />
              </Route>
              <Route exact path='/sent-to-agent' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/sent-to-agent'
                  element={<SentToAgentComponent />}
                />
              </Route>
              <Route exact path='/affiliates' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/affiliates'
                  element={<AffiliatesComponent />}
                />
              </Route>
              <Route exact path='/affiliates/:affiliateStatus' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/affiliates/:affiliateStatus'
                  element={<AffiliatesComponent />}
                />
              </Route>
              <Route exact path='/affiliate/add' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/affiliate/add'
                  element={<AddAffiliateComponent />}
                />
              </Route>
              <Route exact path='/affiliate/:id' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/affiliate/:id'
                  element={<AffiliateDetailsComponent />}
                />
              </Route>
              <Route exact path='/agents' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/agents'
                  element={<AgentsComponent />}
                />
              </Route>
              <Route exact path='/locker-studio' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/locker-studio'
                  element={<LockerStudioComponent />}
                />
              </Route>
              <Route exact path='/templates/:type' element={<PrivateRoute />}>
                <Route
                  exact
                  path='/templates/:type'
                  element={<TemplatesComponent />}
                />
              </Route>
            </>
          )}
          <Route path='*' element={<Error404 />} />
        </Routes>
      </div>
    </>
  );
};

RouteList.propTypes = {
  setThemeMode: PropTypes.func,
};

RouteList.defaultProps = {
  setThemeMode: () => { },
};

export default RouteList;
