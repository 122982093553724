// Import Module
import React from 'react';

import Leads from './leads';

const OtherLeads = () => {
  return (
    <Leads isOnlyIncomplete={true} isOnlyUnread={false} showDashboard={false} />
  )
};

export default OtherLeads;