// Import Module
import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const AffiliateResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  //  email is passed in query param
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  useEffect(() => {

    const detectDeviceType = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? 'Mobile'
    : 'Desktop';

    // If it's a mobile device then redirect to the app, if not the open in web browser
    if(detectDeviceType() === 'Mobile'){
      setTimeout(function () { window.location = "https://itunes.apple.com/appdir"; }, 100);

      // Sending email to display in mobile app
      const email = query.get('email');
      window.location = `price4limo://reset-password/${email}/${token}`;
    }
    else{
      navigate(`/affiliate/reset-password-web/${token}`);
    }
    
  }, []); // eslint-disable-line

  return (
    <p>Please download mobile app to set your password</p>
  );
};

export default AffiliateResetPassword;