// Import Modules
import React from 'react';

import NoAccess from '../assets/images/no_access.avif';

// Authorization HOC
export const Authorization = (requiredPermission) => (WrappedComponent) => class WithAuthorization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: [],
      isCheckingPermission: true,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    // const userId = 123;
    const data = {
      permissions: [{ code: 'ADMIN' }],
    }; //await GetUserRolesAndPermissions(userId, false, true);
    if (data) {
      const permissions = data.permissions.map((permission) => permission.code);
      if (this._isMounted) {
        this.setState({
          permission: permissions,
          isCheckingPermission: false,
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { permission, isCheckingPermission } = this.state;
    // prints null once and the correct profile once
    if (permission.length > 0 && requiredPermission.some((val) => permission.indexOf(val) >= 0)) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return (<WrappedComponent {...this.props} permission={permission} />);
    }
    if (!isCheckingPermission) {
      return (
        <div className="text-center no-access-img-container">
          <img alt="no-access" src={NoAccess} />
        </div>
      );
    }
    return <div />;
  }
};

// All the permissions to restrict users based on his/her permission
export const Permission = {
  Admin: Authorization(['ADMIN']),
  SA: Authorization(['SUPER_ADMIN']),
};
