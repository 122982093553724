// Import Module
import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, Tag, Input, Row, Col, Space, Modal, Typography, message, DatePicker } from 'antd';
import { SearchOutlined, MailOutlined, PhoneOutlined, EditOutlined, 
  UserOutlined, CarOutlined, FilterFilled } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { GetLeads, GetLeadStats, DeleteLead, GetAgentsWrapper } from '../services/api';
import {
  GetTagColor, GetOptionForEnum, BeautifyNumber,
  FormatDate, ConvertTo12HourFormat, FormatPhone, FormatDateWithDay,
  GetTimeDifferenceViaDateTime, GetTimeDifferenceInDays,
  SaveFilterIntoLocalStorage, GetFilterFromLocalStorage, SaveLeadIdIntoLocalStorage,
  GetVT,
} from '../services/helper';
import { Status, Events, LeadStatus, TripType } from '../constants';

import SmsModal from '../components/sms_modal';
import EmailModal from '../components/email_modal';
import MatchingVehicleModal from '../components/matching_vehicle_modal';
import TripNote from '../components/trip_note';
import AgentDropdown from '../components/agent_dropdown';

import Day from '../assets/images/24-hours.png';
import Week from '../assets/images/7-days.png';
import Month from '../assets/images/30-days.png';
import Year from '../assets/images/all.png';
import TripHours from '../components/trip_hours';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const Leads = ({ isOnlyIncomplete = false, showDashboard = true, isOnlyUnread = true , isOnlySentToAgent = false, isOnlyActive=false}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [toBeDeleteLeadId, setToBeDeleteLeadId] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [stats, setStats] = useState({
    all: 0, last_7_days: 0, last_24_hours: 0, last_30_days: 0
  });
  const [messageApi, contextHolder] = message.useMessage();

  /* eslint-disable-next-line */
  const [searchText, setSearchText] = useState('');
  /* eslint-disable-next-line */
  const [searchedColumn, setSearchedColumn] = useState('');
  const [isLeadNoteModel, setIsLeadNoteModel] = useState(false);
  const [isTripHoursModel, setIsTripHoursModel] = useState(false);
  const [isSmsModelOpen, setIsSmsModelOpen] = useState(false);
  const [isEmailModelOpen, setIsEmailModelOpen] = useState(false);
  const [isMatchingVehicleModelOpen, setIsMatchingVehicleModelOpen] = useState(false);
  const [leadNote, setLeadNote] = useState('');
  const [leadHour, setLeadHour] = useState('');
  const [editLeadId, setEditLeadId] = useState('');
  const [smsNo, setSmsNo] = useState('');
  const [email, setEmail] = useState('');
  const [triggerId, setTriggerId] = useState(0);
  const [agents, setAgents] = useState([]);
  const searchInput = useRef(null);
  const tableRef = useRef(null);

  // To pass the data inside matching vehicle modal
  const [tripTypeAndHours, setTripTypeAndHours] = useState([]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      total: 1,
      current: 1,
      pageSize: 200,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    const _tblParams = { pagination, filters, sorter };
    setTableParams(_tblParams);
    getLeadData(_tblParams);
    const path = window.location.pathname;
    SaveFilterIntoLocalStorage(path, _tblParams);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const getColumnCalendarProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className='date-picker-container'>
        <RangePicker
            allowEmpty={[true, true]}
            format={'MM/DD/YYYY'}
            value={[
              selectedKeys[0] ? dayjs(selectedKeys[0]) : '',
              selectedKeys[1] ? dayjs(selectedKeys[1]) : '',
            ]}
            placeholder={['Min Date', 'Max Date']}
            onChange={(date, dateString) => {
              setSelectedKeys(dateString ? dateString : []);
            }}
          />
        </div>  
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const appendSortOrder = (columnKey) => {
    if(tableParams?.sorter?.columnKey === columnKey){
      return {
        sortOrder:tableParams?.sorter?.order,
      }
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const preventClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 90,
      ...appendSortOrder('id'),
      // sortOrder: tableParams?.sorter?.columnKey === 'id'? tableParams?.sorter?.order : null,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Agents',
      dataIndex: 'agents',
      key: 'agents',
      ...appendSortOrder('agents'),
      // sortOrder: tableParams?.sorter?.columnKey === 'agents'? tableParams?.sorter?.order : null,
      width: 170,
      hidden: !isOnlySentToAgent, // hide for other pages
    },
    {
      title: 'Trip Date',
      dataIndex: 'pickup_date',
      key: 'pickup_date',
      filteredValue: tableParams?.filters?.pickup_date || null,
      sorter: (a, b) => a.pickup_date - b.pickup_date,
      width: 160,
      ...appendSortOrder('pickup_date'),
      // sortOrder: tableParams?.sorter?.columnKey === 'pickup_date'? tableParams?.sorter?.order : null,
      ...getColumnCalendarProps('pickup_date'),
      render: (_, { pickup_date, pickup_time, customer_link, request_count, source }) => {
        const dateStr = FormatDateWithDay(pickup_date);
        const hoursDiff = GetTimeDifferenceViaDateTime(pickup_date, pickup_time);
        return (<>
          <p>
            {dateStr.slice(0, dateStr.indexOf(' '))} <br />
            {dateStr.slice(dateStr.indexOf(' ') + 1)}
          </p>
          <br/><br/>
          {/* Show lead source URL */}
          <p>
              {source && <Text className="lead-source" rel='noreferrer' target='_blank'> {source}</Text>}
          </p>
            {/* 48 hours difference excluding the lead time */}
            <br/><br/>
            {( hoursDiff >= -48 && hoursDiff < 24) &&<Text className="trip-reminder-section" type="danger">Trip coming up in next 48 hours.</Text>}
            {(customer_link && customer_link.autolink === 1) && <Text className="trip-reminder-section customer-link-generated-txt">Custom Link</Text>}
            {(customer_link && customer_link.autolink === 2) && <Text className="trip-reminder-section auto-link">Autolink</Text>}
            {(request_count > 0) && <Text className="trip-reminder-section">Returned to new box no responses</Text>}
        </>)
      },
    },
    {
      title: 'Event',
      dataIndex: 'trip_event',
      key: 'trip_event',
      filteredValue: tableParams?.filters?.trip_event || null,
      width: 110,
      render: (_, { trip_event }) => (
        <p >{Events[trip_event]}</p>
      ),
      sorter: (a, b) => a.trip_event - b.trip_event,
      ...appendSortOrder('trip_event'),
      // sortOrder: tableParams?.sorter?.columnKey === 'trip_event'? tableParams?.sorter?.order : null,
      filterMultiple: true,
      filters: GetOptionForEnum(Events),
    },
    {
      title: 'PAX',
      dataIndex: 'passenger_count',
      key: 'passenger_count',
      width: 70,
      ...appendSortOrder('passenger_count'),
      // sortOrder: tableParams?.sorter?.columnKey === 'passenger_count'? tableParams?.sorter?.order : null,
      sorter: (a, b) => a.passenger_count - b.passenger_count,
      // ...getColumnSearchProps('passenger_count'),
    },
    {
      title: 'Hrs',
      dataIndex: 'trip_hours',
      key: 'trip_hours',
      render: (_, { trip_hours, trip_type, id, status }) => (
        <>
          <p>{trip_hours === 0 ? TripType[trip_type] : `${trip_hours} Hr${trip_hours > 1? 's': ''}`} </p>
          {/* Show the edit icon only if lead status is unread checking on condition explicitly here */}
          {/* Do not allow editing of hours if Trip is round trip */}
          {LeadStatus[status.tag] === LeadStatus.unread  && TripType[trip_type] !== TripType.round_trip && <EditOutlined 
            style={{ fontSize: 15 }} 
            className='edit-trip-details'
            onClick={(e)=> {
              setEditLeadId(id);
              setLeadHour(trip_hours=== 0 ? trip_type : trip_hours);
              setIsTripHoursModel(true);
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}/>}
        </>),
      sorter: (a, b) => a.trip_hours - b.trip_hours,
      ...appendSortOrder('trip_hours'),
      // sortOrder: tableParams?.sorter?.columnKey === 'trip_hours'? tableParams?.sorter?.order : null,
      width: 150
    },
    {
      title: 'Pickup',
      dataIndex: 'pickup_address',
      key: 'pickup_address',
      filteredValue: tableParams?.filters?.pickup_address || null,
      width: 260,
      className: 'v-top',
      ellipsis: true,
      ...getColumnSearchProps('pickup_address'),
      render: (_, { id, pickup_address, duplicate_leads, pickup_time, customer_phone_number, is_sms_sent }) => (
        <div onClick={preventClick}>
          <p>{pickup_address}<br />{pickup_time}</p>
          {customer_phone_number && 
            <div className='lead-actbtn-container' 
              onClick={()=> {
                setTriggerId(id);
                setSmsNo(customer_phone_number);
                setIsSmsModelOpen(true);
                }}>
              <PhoneOutlined style={{ fontSize: 15 }} className='contact-icon' /> {FormatPhone(customer_phone_number)}</div>}

              {is_sms_sent && <b>TEXT SENT</b>}
              <br/>
              {/* If there are duplicate leads having same phone number then show that below the phone number */}
              {duplicate_leads.length > 0 && 
              <Text className="trip-reminder-section" type="danger">
                Additional Associated Leads:
              </Text>}
              {duplicate_leads.map((m,i)=>
                <div className='mt-5' key={`additional-lead-tag-${i}`}>
                  <span className='additional-link' onClick={(e)=> {
                      // navigate(`/lead/${m.lead_id}`, {state: location.pathname});
                      window.open(`/lead/${m.lead_id}`, '_blank', 'noopener');
                      preventClick(e);
                    }}>{`#${m.lead_id}`}</span>: &nbsp;
                  <Tag color={GetTagColor(Status[m.status])}>
                    {LeadStatus[m.status]}
                  </Tag>
                </div>)}
        </div>
      ),
    },
    {
      title: 'Drop Off',
      dataIndex: 'dropOff_address',
      key: 'dropOff_address',
      filteredValue: tableParams?.filters?.dropOff_address || null,
      className: 'v-top',
      width: 260,
      ...getColumnSearchProps('dropOff_address'),
      render: (_, { dropOff_address, customer_email, id, is_email_sent
       }) => (
        <div>
          <p onClick={preventClick}>{dropOff_address}<br />&nbsp;</p>
          {customer_email && 
          <div className='lead-actbtn-container' 
            onClick={(e)=> {
              // Open a modal here 
              setTriggerId(id);
              setEmail(customer_email);
              setIsEmailModelOpen(true);
              preventClick(e);
            }}>
            <MailOutlined style={{ fontSize: 15 }} className='contact-icon' /> {customer_email}</div>}
            {is_email_sent && <b>EMAIL SENT</b>}
        </div>
      ),
    },
    {
      title: 'Trip Details',
      dataIndex: 'trip_details',
      key: 'trip_details',
      width: 250,
      render: (_, { trip_details, id }) => (
        <div>
          <p>{trip_details}</p>
          <EditOutlined 
            style={{ fontSize: 15 }} 
            className='edit-trip-details'
            onClick={(e)=> {
              setEditLeadId(id);
              setLeadNote(trip_details);
              setIsLeadNoteModel(true);
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}/>
        </div>
      ),
    },
    {
      title: 'Submitted',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 130,
      sorter: (a, b) => a.created_at - b.created_at,
      ...appendSortOrder('created_at'),
      // sortOrder: tableParams?.sorter?.columnKey === 'created_at'? tableParams?.sorter?.order : false,
      // Purposefully rendering it here to show date on first line and time on the next line
      render: (_, { created_at_obj }) => (
        <p>{created_at_obj.date}<br/>{created_at_obj.time}</p>
      ),
    },
    {
      title: 'Matching Count',
      dataIndex: 'matching_vehicles',
      key: 'matching_vehicles',
      width: 225,

      // REMOVED THE FILTER----------------------------------------------------
      // filteredValue: tableParams?.filters?.matching_vehicles || null,
      // filterMultiple: true,
      // filterIcon:<DownCircleOutlined />, 
      // filters: [{
      //     'text': 'Filter by 35 Miles Matched',
      //     'value': 'matching_vehicles_35_min' 
      //   },{
      //     'text': 'Filter by 65 Miles Matched',
      //     'value': 'matching_vehicles_65_min' 
      //   }
      // ],
      // REMOVED THE FILTER----------------------------------------------------

      render: (_, { id, matching_count, veh_type, matching_count_30_60, trip_type, trip_hours, pickup_date, trip_event,
        passenger_count, pickup_address, pickup_time, trip_details, dropOff_address }) => (
        <>
          {matching_count_30_60 && matching_count_30_60.map((d, i) => 
            // Show the matching count only if it is greater than 1, hidding it via styling
              <div className="matching-logic-section" key={`matching-${id}-${i}`}>
                <p className='matching-counter matching-counter-special' onClick={(e) => {
                  // Open a modal here and pass all the details that is needed inside the modal
                  setTripTypeAndHours({
                    "miles": d.search_radius,"type": trip_type, "hours": trip_hours,pickup_address,dropOff_address, 
                    pickup_date, pickup_time, trip_details, trip_event, passenger_count, veh_type: GetVT(veh_type),
                    "min": d.min_search_radius ? d.min_search_radius : 0, "max": d.max_search_radius ? d.max_search_radius : 0,
                    "isComputerSelected": true  
                    // Passing isComputerSelected to know if this is picked by rules
                  });
                  setTriggerId(id);
                  setIsMatchingVehicleModelOpen(true);
                  preventClick(e);
                }}>
                  &nbsp; {d.search_radius} {d.search_radius !== 'selected' ? 'miles': '' } &nbsp;
                  <UserOutlined style={{ fontSize: 15 }} /> {d.affiliates} &nbsp;
                  <CarOutlined style={{ fontSize: 15 }} /> {d.vehicles}</p>
              </div>)}

              {matching_count && matching_count.map((d, i) => 
              <div className='text-center matching-logic-section' key={`matching-${id}-${i}`}>
                <p className='matching-counter' onClick={(e) => {
                  // Save lead id into local storage
                  // SaveLeadIdIntoLocalStorage(window.location.pathname, id);
                  // navigate(`/lead/${id}/request-quote/${d.search_radius}`, {state: location.pathname});
                  // preventClick(e);
                  setTripTypeAndHours({
                    "miles": d.search_radius,"type": trip_type, "hours": trip_hours,pickup_address,dropOff_address, 
                    pickup_date, pickup_time, trip_details, trip_event, passenger_count, veh_type: GetVT(veh_type),
                    "min": d.min_search_radius ? d.min_search_radius : 0, "max": d.max_search_radius ? d.max_search_radius : 0,
                    "isComputerSelected": false    
                    // Passing isComputerSelected to know if this is picked by rules
                  });
                  setTriggerId(id);
                  setIsMatchingVehicleModelOpen(true);
                  preventClick(e);
                }}>
                  {d.search_radius} miles &nbsp;
                  <UserOutlined style={{ fontSize: 15 }} /> {d.affiliates} &nbsp;
                  <CarOutlined style={{ fontSize: 15 }} /> {d.vehicles}</p>
              </div>)}

          {veh_type && veh_type.length > 0 && <p className='text-center'>Vehicle Type: {GetVT(veh_type)}</p>}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (_, { status }) => (
        <Tag color={status.color} key={status.tag}>
          {LeadStatus[status.tag]}
        </Tag>
      ),
      sorter: (a, b) => a.status - b.status,
      ...appendSortOrder('status'),
      // sortOrder: tableParams?.sorter?.columnKey === 'status'? tableParams?.sorter?.order : null,
      filterMultiple: true,
      filteredValue: tableParams?.filters?.status || null,
      hidden: isOnlyUnread, // hide for new leads
      filters: (isOnlyIncomplete || isOnlyUnread) ? false : GetOptionForEnum(LeadStatus),
    },
    {
      title: 'Action',
      dataIndex: 'manageLeads',
      key: 'manageLeads',
      width: 180,
      render: (_, { id }) => (
        <>
          <Button
            style={{ width: '140px' }}
            onClick={(e) => {
              // Save lead id into local storage
              SaveLeadIdIntoLocalStorage(window.location.pathname, id);              
              navigate(`/lead/${id}/request-quote`, {state: location.pathname});
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          >
            Manage Lead
          </Button>
          <Button
            style={{ width: '140px' }}
            type="primary"
            className="generate-link-btn mt-10"
            onClick={(e) => {
              window.open(`${process.env.REACT_APP_LIMO_LINK}?lead=${id}`, '_blank', 'noopener');
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          >
            Generate Link 
          </Button>
          <Button
            danger
            style={{ width: '140px' }}
            className='mt-10'
            loading={isDeleting && toBeDeleteLeadId === id}
            onClick={(e) => {
              setToBeDeleteLeadId(id);
              // setIsDeleteModalOpen(true);
              setIsDeleting(true);
              DeleteLead(id).then(() => {
                setIsDeleting(false);
                let _data = [...dataSource];
                const _index = _data.findIndex((d) => d.id === id);
                _data.splice(_index, 1);
                setDataSource(_data);
                messageApi.success('Lead deleted successfully.');
              });

              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          >
            Delete Lead
          </Button>

          <AgentDropdown id={id} agents={agents}/>
        </>
      ),
    },
  ];

  useEffect(() => {
    // SetBrowserZoom('80%');
    if (showDashboard) {
      GetLeadStats().then((data) => {
        setStats(data.data);
      });
    }
  }, [showDashboard]);

  const getLeadData = (tableParams) => {
    let params = `per_page_rows=${tableParams.pagination.pageSize}&current_page=${tableParams.pagination.current}`;
    if (tableParams.sorter?.columnKey) {
      params += '&sort_by=' + tableParams.sorter.columnKey;
      if (tableParams.sorter?.order) {
        const order = tableParams.sorter.order === 'ascend' ? 'ASC' : 'DESC';
        params += '&order_by=' + order;
      }
    }
    if (tableParams.filters) {
      Object.keys(tableParams.filters).forEach((key) => {
        if (tableParams.filters[key]) {
          let _val = '';
          if(key === 'status') {
            _val = tableParams.filters[key].map((v) => v).join(',');
            params += `&${key}=${_val}`;
          } 
          else if(key === 'trip_event') {
            _val = tableParams.filters[key].map((v) => v).join(',');
            params += `&${key}=${_val}`;
          }
          else if(key === 'pickup_date') {
            if(tableParams.filters[key].length > 0) {
              const [minDate, maxDate] = tableParams.filters[key];
              if(minDate){
                params += `&trip_date_min=${minDate}`;
              }
              if(maxDate){
                params += `&trip_date_max=${maxDate}`;
              }
            }
          }
          else if(key === 'matching_vehicles') {
            tableParams.filters[key].forEach((v) => {
              params += `&${v}=1`;
            })
          } else {
            _val = tableParams.filters[key][0];
            params += `&${key}=${_val}`;
          }
        }
      });
    }
    // Add default parameters
    if (isOnlyIncomplete) {
      params += `&status=incomplete`;
    }
    if (isOnlyUnread) {
      params += `&status=unread`;
    }
    if (isOnlySentToAgent) {
      params += `&status=sent_to_agent`;
    }

    setLoading(true);

    GetLeads(params, isOnlyActive)
      .then((data) => {
        let mappingData = [];
        data?.data?.leads.forEach((d) => {
          const tagColor = GetTagColor(Status[d.status]);
          let obj = {
            id: d.id,
            key: d.id,
            pickup_date: dayjs(d.pickup_date).format('MM-DD-YYYY'),
            trip_event: d.trip_event,
            trip_hours: d.trip_hours,
            trip_type: d.trip_type,
            passenger_count: d.passenger_count,
            pickup_address: `${d.pickup_city}, ${d.pickup_state}`,
            customer_email: d.customer_email,
            customer_phone_number: d.customer_phone_number,
            pickup_time: `${ConvertTo12HourFormat(d.pickup_time)}`,
            dropOff_address: `${d.dropoff_city}, ${d.dropoff_state}`,
            created_at: `${FormatDate(d.created_at)}`,
            created_at_obj: FormatDate(d.created_at,true),
            status: {
              tag: d.status,
              color: tagColor,
            },
            trip_details: d.trip_details,
            request_count: parseInt(d.request_count),
            source: d.source,
            // Adding a condition here to show 35 and 65 miles separately because there is a loop in matching count 
            // It would show all together 
            matching_count: d.matching_count.filter(miles => ((miles.search_radius === '0-25' && miles.is_selected === false )|| (miles.search_radius === '26-60' && miles.is_selected === false ) || (miles.search_radius === '0-60' && miles.is_selected === false ))),
            matching_count_30_60: d.matching_count.filter(miles => ((miles.search_radius === '0-25' && miles.is_selected === true )|| (miles.search_radius === '26-60' && miles.is_selected === true ) || (miles.search_radius === '0-60' && miles.is_selected === true ))),
            veh_type: d.veh_type,
            customer_link: d.customer_link,
            duplicate_leads: d.duplicate_leads,
            is_email_sent: d?.lead_activity?.findIndex((d) => d.activity_type === 'send_email') !== -1,
            is_sms_sent: d?.lead_activity?.findIndex((d) => d.activity_type === 'send_sms') !== -1,
            agents: d?.agents?.map(obj => obj.name).join(', '), // agent name
          };
          mappingData.push(obj);
        });
        setDataSource(mappingData);
        setLoading(false);

        // Commenting out logic for auto scroll 

        // let leadToScroll = GetLeadIdIntoLocalStorage(window.location.pathname);
        // // if ref available with table scroll to row
        // if (tableRef.current && leadToScroll) {
        //   setTimeout(() => {
        //     tableRef.current.scrollTo({ key: leadToScroll});
        //     RemoveLeadIdFromLocalStorage(window.location.pathname);
        //   }, 2000);
        // }

        //Set pagination
        const pg = data.data.pagination;
        let pagination = {
          total: pg.total_items,
          current: pg.current_page,
          pageSize: parseInt(pg.per_page_rows),
        };
        // if (tableParams.pagination.current === 1) {
          const { filters, sorter } = tableParams;
          setTableParams({ pagination, filters, sorter });
        // }
      })
      .catch((e) => {
        // console.log('error', e);
        setLoading(false);
      });
  }

  useEffect(() => {
    let defaultFilter = GetFilterFromLocalStorage(window.location.pathname);
    if(defaultFilter) {
      setTableParams(defaultFilter);
      getLeadData(defaultFilter);
    } else {
      getLeadData(tableParams);
    }

    GetAgentsWrapper().then((agents) => {
      setAgents(agents);
    });
  }, []); // eslint-disable-line

  const getRowHighlightColor = (pickup_date) => {
    const days = GetTimeDifferenceInDays(pickup_date);
    if( days <= 30) {
      return 'lead-highlight-row';
    } else if( days > 30 && days <= 90) {
      return 'lead-highlight-row-2';
    } else if( days > 270){
      return 'lead-highlight-row-3';
    }
  };

  return (
    <div className='p-ant-table-container '>
      {contextHolder}
      {showDashboard &&
        <Row className='mt-20'>
          <Col lg={6} md={8} sm={12}>
            <div className='flex-separate lead-card'>
              <div>
                <img src={Day} className='lead-img' alt='lead duration' />
              </div>
              <div>
                <h1>{stats.last_24_hours}</h1>
                <div>Leads in last 24 hours</div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={8} sm={12}>
            <div className='flex-separate lead-card'>
              <div>
                <img src={Week} className='lead-img' alt='lead duration' />
              </div>
              <div>
                <h1>{BeautifyNumber(stats.last_7_days)}</h1>
                <div>Leads in last Week</div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={8} sm={12}>
            <div className='flex-separate lead-card'>
              <div>
                <img src={Month} className='lead-img' alt='lead duration' />
              </div>
              <div>
                <h1>{BeautifyNumber(stats.last_30_days)}</h1>
                <div>Leads in last 30 days</div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={8} sm={12}>
            <div className='flex-separate lead-card'>
              <div>
                <img src={Year} className='lead-img' alt='lead duration' />
              </div>
              <div>
                <h1>{BeautifyNumber(stats.all)}</h1>
                <div>All time Leads</div>
              </div>
            </div>
          </Col>
        </Row>}

      <Button className='clear-filter-btn' onClick={()=>{
        handleTableChange({total: 1, current: 1, pageSize: 200}, {}, {});
      }}>Clear Filters</Button>

      <Table
        dataSource={dataSource}
        columns={columns}
        ref={tableRef}
        rowKey="id"
        rowClassName={(record, index) => `leads-row ${getRowHighlightColor(record.pickup_date)} tbl-${record.status.color}-row`}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
        scroll={{
          x: 1800,
          // scrollToFirstRowOnChange: true,
        }}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              // Save lead id into local storage
              SaveLeadIdIntoLocalStorage(window.location.pathname, record.id);
              navigate(`/lead/${record.id}`, {state: location.pathname});
            }, // click row
          };
        }}
      />

      <Modal centered title="Delete Lead?" open={isDeleteModalOpen} okText="Yes"
        onOk={() => {
          DeleteLead(toBeDeleteLeadId).then(() => {
            setIsDeleteModalOpen(false);
            setToBeDeleteLeadId(0);
            getLeadData(tableParams);
          });
        }}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}>
        <p>Are you sure you want to delete lead #{toBeDeleteLeadId}</p>
      </Modal>

      

      {isSmsModelOpen && <SmsModal 
        isModalOpen={isSmsModelOpen}
        setIsModalOpen={setIsSmsModelOpen}
        msgType="lead"
        phoneNumber={smsNo}
        id={triggerId}
      />}

      {isEmailModelOpen && <EmailModal 
        isModalOpen={isEmailModelOpen}
        setIsModalOpen={setIsEmailModelOpen}
        msgType="lead"
        emailAddress={email}
        id={triggerId}
      />}

      {isMatchingVehicleModelOpen && <MatchingVehicleModal 
      isModalOpen={isMatchingVehicleModelOpen}
      setIsModalOpen={setIsMatchingVehicleModelOpen}
      id={triggerId}
      tripTypeAndHours={tripTypeAndHours}
      />}

      {isLeadNoteModel && <TripNote
        isLeadNoteModel={isLeadNoteModel}
        setIsLeadNoteModel={setIsLeadNoteModel}
        editLeadId={editLeadId}
        leadNoteToEdit={leadNote}
        callback={(d) => {
          // update state data with new note
          let _data = [...dataSource];
          const _index = _data.findIndex((d) => d.id === editLeadId);
          _data[_index].trip_details = d;
          setDataSource(_data);
        }}
      />}

      {isTripHoursModel && <TripHours
        isTripHoursModel={isTripHoursModel}
        setIsTripHoursModel={setIsTripHoursModel}
        editLeadId={editLeadId}
        defaultHours={leadHour}
        callback={(d) => {
          // update state data with new note
          let _data = [...dataSource];
          const _index = _data.findIndex((d) => d.id === editLeadId);
          _data[_index].trip_hours = d.trip_hours;
          _data[_index].trip_type = d.trip_type;
          setDataSource(_data);
        }}
      />}
    </div>
  );
};

export default Leads;
