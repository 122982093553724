import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

const AffiliateLeadDetails = () => {
  const [appInstalled, setAppInstalled] = useState(); //eslint-disable-line
  const { leadId } = useParams();


  const OS_TYPE = {
    android: "Android",
    ios: "iOS",
    windows: "Windows",
    mac: "Mac OS",
    linux: "Linux",
    unknown: "Unknown",
  };
  const p4lAppStoreLink =
    "https://apps.apple.com/in/app/price-4-limo-partners/id6476625526";
  const p4lPlayStoreLink =
    "https://play.google.com/store/apps/details?id=com.price4limopartner";

  function detectOS() {
    var userAgent = window.navigator.userAgent;
    if (/Android/i.test(userAgent)) {
      return OS_TYPE.android;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return OS_TYPE.ios;
    } else if (/Windows|Win32|Win64|Windows NT/.test(userAgent)) {
      return OS_TYPE.windows;
    } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
      return OS_TYPE.mac;
    } else if (/Linux/.test(userAgent)) {
      return OS_TYPE.linux;
    } else {
      return OS_TYPE.unknown;
    }
  }

  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  function navigateToStore() {
    // In mobile app redirect to app store or play store in web redirect to affiliate portal
    const isMobileDevice = isMobile();
    if(isMobileDevice){
      window.location.href = [OS_TYPE.ios, OS_TYPE.mac].includes(detectOS())? p4lAppStoreLink: p4lPlayStoreLink;
    }
    else{
      window.location.href = `${process.env.REACT_APP_AFFILIATE_URL}/lead/${leadId}`;
    }
  }

  function openP4LAppLink(leadId) {
    if (leadId) {
      window.location.href = `price4limo://lead/${leadId}`;
    } else {
      navigateToStore();
    }
  }

  useEffect(() => {
    // In function parameter pass the applink to navigate
    if (leadId) {
      openP4LAppLink(leadId);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    setTimeout(function () {
      if (!appInstalled) {
        navigateToStore();
      }
    }, 1000); // Adjust timeout as needed
  }, []); //eslint-disable-line

  return <div> Loading... Please wait</div>;
}

export default AffiliateLeadDetails;