// Import Module
import React, { useState } from "react";
import { Modal, Input, Button } from "antd";
import { FormatPhoneNumberForServer, ValidateEmail, ValidatePhone } from "../services/helper";
import PhoneInput from "./phone_input";

const ContactInfoModal = ({
  isModalOpen,
  setIsModalOpen,
  editModeIndex,
  contact,
  addContact,
  loading,
  messageApi,
}) => {
  const [data, setData] = useState(contact);
  const key = "updatable";

  const addEditContact = () => {
    if (!data?.name) {
      messageApi.open({
        key,
        type: 'error',
        content: `Name is required!`,
      });
      return false;
    } else if (!data.email) {
      messageApi.open({
        key,
        type: 'error',
        content: `Email address is required!`,
      });
      return false;
    } else if (!ValidateEmail(data?.email)) {
      messageApi.open({
        key,
        type: 'error',
        content: `Please enter a valid email address.`,
      });
      return false;
    } else if (!data?.phone_number ) {
      messageApi.open({
        key,
        type: 'error',
        content: `Mobile number is required!`,
      });
      return false;
    } else if(!ValidatePhone(data?.phone_number) || data?.phone_number.length < 10){
      messageApi.open({
        key,
        type: 'error',
        content: `Please add valid phone number.`,
      });
      return false;
    }

    // Patch for phone number which append +1
    data.phone_number = FormatPhoneNumberForServer(data.phone_number);
    addContact(data);
  };

  const updateData = (key, value) => {
    let _data = {...data};
    _data[key] = value;
    setData(_data);
  };

  return (
    <Modal
      title="Enter your contact information"
      open={isModalOpen}
      centered
      width={600}
      onCancel={() => setIsModalOpen(false)}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            addEditContact();
          }}
        >
          {editModeIndex > -1 ? "Save changes" : "Add contact"}
        </Button>,
      ]}
    >
      <div className='add-contact-affiliate-form' key={`aff-contact`}>
          <div className='add-affiliate-form-item'>
            <div className='add-affiliate-form-item-label'>Name</div>
            <Input value={data?.name} placeholder='Enter name' maxLength={35} onChange={(e) => updateData('name', e.target.value)} />
          </div>
          <div className='add-affiliate-form-item'>
            <div className='add-affiliate-form-item-label'>Email address</div>
            <Input value={data?.email} placeholder='Enter email address' maxLength={48} onChange={(e) => updateData('email', e.target.value)} />
          </div>
          <div className='add-affiliate-form-item'>
            <div className='add-affiliate-form-item-label'>Mobile number</div>
            <PhoneInput value={data?.phone_number} placeholder='Enter mobile number' onChange={(e) => updateData('phone_number', e)}/>
            {/* <Input addonBefore="+1" maxLength={10} value={FormatPhoneNumberForDisplay(data?.phone_number)} placeholder='Enter mobile' onChange={(e) => updateData('phone_number', e.target.value)} /> */}
          </div>
        </div >
    </Modal>
  );
};

export default ContactInfoModal;
