export const PUBLIC_HEADER_TOKEN = "PUBLIC_AUTHORIZATION_TOKEN";
export const ServiceURL = process.env.REACT_APP_API_ENDPOINT;
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_KEY;

export const Status = {
    pending: 'Pending',
    approved: 'Approved',
    rejected: 'Rejected',
    active: 'Active',
    available: 'Available',
    unread: 'Unread',
    sent_for_quote: 'Sent For Quote',
    quote_received: 'Quote Received',
    hired: 'Hired',
    archived: 'Archived',
    incomplete: 'Incomplete',
    low_budget: 'Low Budget',
    sent_to_agent: 'Sent To Agent',
    deleted: 'Deleted'
}

export const LeadStatus = {
    unread: 'Unread',
    sent_for_quote: 'Sent For Quote',
    quote_received: 'Quote Received',
    hired: 'Hired',
    archived: 'Archived',
    incomplete: 'Incomplete',
    low_budget: 'Low Budget',
    sent_to_agent: 'Sent To Agent',
    deleted: 'Deleted'
}

export const LeadStatusDropdown = {
    unread: 'Unread',
    incomplete: 'Incomplete',
    low_budget: 'Low Budget',
    quote_received: 'Quote Received',
    sent_for_quote: 'Sent For Quote',
}

export const VehicleCategory = {
    charter_bus: 'Charter Bus/ Motocoaches',
    shuttle_buses_minibuses: 'Shuttle/ Mini buses',
    sprinter_van_with_passenger_seating: 'Sprinter Van (Forward Facing Seating)',
    sprinter_van_with_limousine_seating: 'Sprinter Van (Limo Style Seating)',
    party_bus: 'Party Bus',
    school_buses: 'School Buses',
    hummer_limo: 'Hummer Limo',
    escalade_limo: 'Escalade Limo',
    excursion_limo: 'Excursion Limo',
    chrysler_limo: 'Chrysler 300 Limo',
    range_rover_limo: 'Range Rover Limo',
    suburban_limo: 'Suburban Limo',
    lincoIn_mkt_limo: 'Lincoln MKT Limo',
    lincoIn_town_car_limo: 'Lincoln Town Car Limo',
    trolley: 'Trolley',
    non_stretch_suv: 'Non Stretch SUV',
    non_stretch_sedan: 'Non Stretch Sedan',
}


export const LimoVehicles = [
    'sprinter_van_with_limousine_seating',
    'hummer_limo',
    'escalade_limo',
    'excursion_limo',
    'chrysler_limo',
    'range_rover_limo',
    'suburban_limo',
    'lincoIn_mkt_limo',
    'lincoIn_town_car_limo',
    'party_bus',
    'trolley'
];

export const Events = {
    birthday: 'Birthday',
    prom: 'Prom',
    funeral: 'Funeral',
    wedding: 'Wedding',
    night_on_the_town: 'Night on the town',
    corporate_or_group_outing: 'Corporate or Group Outing',
    concert: 'Concert',
    sporting_event: 'Sporting Event',
    bachelor_party_bachelorette: 'Bachelor Party / Bachelorette',
    home_coming: 'HomeComing',
    general_transportation: 'General Transportation',
}

export const TripType = {
    fixed_hours: 'Fixed Hours',
    more_hours: 'More Hours',
    one_way_transfer: 'One Way Transfer',
    multiple_day_usage: 'Multiple Days',
    round_trip: 'Round Trip',
}

export const VehicleSeat = {
    leather: 'Leather',
    cloth: 'Cloth',
}

export const VehicleRequestStatus = {
    available: {
        name: 'Available',
        color: "green"
    },
    unattended: {
        name: 'Unattended',
        color: "grey"
    },
    unavailable: {
        name: 'Not available/Interested',
        color: 'red'
    },
    hired: {
        name: 'Hired',
        color: 'green'
    }
}

export const VehicleColor = {
    white: 'White',
    black: 'Black',
    grey: 'Grey',
    red: 'Red',
    pink: 'Pink',
    yellow: 'Yellow',
    other: 'Other',
}

export const VehiclePassengerCapacity = {
    charter_bus: 56,
    shuttle_buses_minibuses: 30,
    sprinter_van_with_passenger_seating: 14,
    sprinter_van_with_limousine_seating: 14,
    party_bus: 20,
    school_buses: 47,
    hummer_limo: 18,
    escalade_limo: 18,
    excursion_limo: 18,
    chrysler_limo: 10,
    range_rover_limo: 15,
    suburban_limo: 15,
    lincoIn_mkt_limo: 8,
    lincoIn_town_car_limo: 8,
    trolley: 30,
    non_stretch_suv: 7,
    non_stretch_sedan: 3,
}

export const VehicleWithSeatFabric = ['charter_bus', 'shuttle_buses_minibuses'];

export const PricingLabel = {
    cheapest: '$',
    cheap: '$$',
    affordable: '$$$',
    expensive: '$$$$',
};

export const VT = {
    li: 'Limousine',
    pb: 'Party Bus',
    cb: 'Charter bus',
};

export const TimeZones = {
    EST: 'America/New_York',
    CST: 'America/Chicago',
    MST: 'America/Denver',
    PST: 'America/Los_Angeles',
    AKST: 'America/Anchorage',
    HAST: 'Pacific/Honolulu'
};