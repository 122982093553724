// Import Modules
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import RouteList from './route_list';

const App = () => {
  return (
    <div id="wrapper">
      <BrowserRouter forceRefresh>
        <RouteList />
      </BrowserRouter>
    </div>
  );
};

export default App;
