// Import Module
import React from 'react';

const LockerStudio = () => {
  return (
    <div className='locker-studio-iframe-container'>
      <iframe src="https://lookerstudio.google.com/embed/reporting/1cd7f354-bbba-4d89-a3a0-2f41c2dc060f/page/QImvD" 
        title="Locker Studio" 
        className='locker-studio-iframe'
        width="100%" height="100%"></iframe>
    </div>
  )
};

export default LockerStudio;