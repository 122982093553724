// Import Module
import React, { useEffect, useState } from 'react';
import {
  Tag,
  Select,
  Input,
  Table,
  Button,
  Flex,
  Typography,
  Switch,
  message,
  Modal,
  Col,
  Row,
  Timeline,
  Popover
} from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import {
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  EditOutlined,
  DeleteOutlined,
  CarOutlined,
  UserAddOutlined,
  UserOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';

import {
  GetAffiliateDetails,
  GetAffiliateVehicles,
  UpdateAffiliateStatus,
  GetAffiliateContacts,
  UpdateAffiliateDetails,
  FetchLocation,
  DeleteAffiliate,
  DeleteVehicle,
  GetAffiliateActivity,
  EditVehicle,
  CreateVehicle,
  DeleteContact,
  EditContact,
  CreateContact,
} from '../services/api';
import {
  GetEnumKey,
  GetTagColor,
  ValidateEmail,
  ValidateURL,
  ValidateZIP,
  FormatDateWithDay,
  FormatPhone,
  FormatDate,
  FormatPhoneNumberForServer,
  FormatPhoneNumberForDisplay,
  GetClickableLink,
  GetOptionForEnum,
} from '../services/helper';
import { Status, VehicleCategory, VehicleSeat, VehicleColor, PricingLabel, TimeZones } from '../constants';
import TagRender from '../components/tag_render';
import BackBtn from '../components/back_btn';
import VehicleInfoModal from '../components/vehicle_info_modal';
import ContactInfoModal from '../components/contact_info_modal';
import SmsModal from '../components/sms_modal';
import EmailModal from '../components/email_modal';

const { Text } = Typography;

const AffiliateDetails = () => {
  const [data, setData] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [note, setNote] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState('');
  const [leadActivity, setLeadActivity] = useState([]);
  const [isEditVehicleModalOpen, setIsEditVehicleModalOpen] = useState(false);
  const [editModeVehicleIndex, setEditModeVehicleIndex] = useState(-1);
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [editModeContactIndex, setEditModeContactIndex] = useState(-1);
  const [isContactLoading, setIsContactLoading] = useState(false);
  const [isVehicleLoading, setIsVehicleLoading] = useState(false);
  const [isSmsModelOpen, setIsSmsModelOpen] = useState(false);
  const [isEmailModelOpen, setIsEmailModelOpen] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const { TextArea } = Input;
  const { id } = useParams();
  const navigate = useNavigate();
  const key = 'updatable';

  const getAffiliateInfo = () => {
    GetAffiliateDetails(id).then((d) => {
      if (d.code === 200) {
        setData(d.data);
        setNote(d.data.admin_note);
      }
    }).catch((e) => {
      messageApi.open({
        key,
        type: 'error',
        content: e.message,
      });
      setTimeout(() => {
        // if affiliate not found, redirect to affiliate list
        navigate('/affiliates');
      }, 1000);
    });
  }

  const getAffVehicles = () => {
    GetAffiliateVehicles(id).then((d) => {
      if (d.code === 200) {
        // Patch to remove key warning in Ant design table
        let data = d.data.map((item, index) => ({
          ...item,
          key: item.id, // Use id field as key
          index
        }));
        setVehicles(data);
      }
    }).catch((e) => {
      console.log('e');
    });
  };

  const getAffContacts = () => {
    GetAffiliateContacts(id).then((d) => {
      if (d.code === 200) {
        // Patch to remove key warning in Ant design table
        let data = d.data.map((item, index) => ({
          ...item,
          key: item.id, // Use id field as key
          index // for edit purpose
        }));
        setContacts(data);
      }
    }).catch((e) => {
      console.log('e');
    });
  }

  useEffect(() => {
    getAffiliateInfo();
    getAffVehicles();
    getAffContacts();    

    GetAffiliateActivity(id).then((d) => {
      if (d.code === 200) {
        let leadActivity = [];
        d.data.forEach((activity) => {
          let activityDotColor = 'gray';
          let activityText = '';
          let customIcon = '';
          switch (activity.activity_type) {
            case 'affiliate_signed_up':
              activityDotColor = 'yellow';
              activityText = (
                <div>
                  <p><b>{activity.activity_data.affiliate_detail.affiliate.name}</b> signed up.</p>
                </div>);
              break;
            case 'affiliate_modified':
              activityDotColor = 'orange';
              activityText = (
                <div>
                  <p><b>{activity.activity_data.affiliate_detail.name}</b> modified by {activity.created_by.name}</p>
                </div>);
              break;
            case 'affiliate_status_changed':
              activityDotColor = 'orange';
              activityText = (
                <div>
                  {/* affiliate name not available */}
                  <p><b>Affiliate {activity.activity_data.affiliate.name}</b> status changed by <b>{activity.created_by.name}</b></p>
                </div>);
              break;

            case 'contact_user_created':
              activityDotColor = 'green';
              activityText = (
                <div>
                  <p><b>{activity.activity_data.contact_user.name}</b> added as contact by <b>{activity.created_by.name}</b></p>
                </div>);
              customIcon = (<UserAddOutlined className='timeline-icon' />)
              break;

            case 'contact_user_modified':
              activityText = (
                <div>
                  <p><b>{activity.activity_data.contact_user.name}</b> contact modified by <b>{activity.created_by.name}</b></p>
                </div>);
              customIcon = (<UserOutlined className='timeline-icon' />)
              break;

            case 'contact_user_deleted':
              activityDotColor = 'red';
              activityText = (
                <div>
                  <p>Contact #<b>{activity.activity_data.contact_user_id}</b> deleted by <b>{activity.created_by.name}</b></p>
                </div>);
              customIcon = (<UserOutlined className='timeline-icon' />)
              break;

            case 'vehicle_created':
              activityDotColor = 'green';
              activityText = (
                <div>
                  <p><b>{VehicleCategory[activity.activity_data.vehicle.category]}</b> vehicle added by <b>{activity.created_by.name}</b></p>
                  <p>Seat Fabric: <b>{!!VehicleSeat[activity.activity_data.vehicle.seat_type] ? VehicleSeat[activity.activity_data.vehicle.seat_type] : "-"}</b></p>
                  <p>Passenger: <b>{activity.activity_data.vehicle.passenger_count}</b></p>
                  <p>Color: <b>{VehicleColor[activity.activity_data.vehicle.color]}</b></p>
                </div>);
              customIcon = (<CarOutlined className='timeline-icon' />)
              break;

            case 'vehicle_modified':
              activityDotColor = 'orange';
              activityText = (
                <div>
                  <p><b>{VehicleCategory[activity.activity_data.vehicle.category]}</b> vehicle modified by <b>{activity.created_by.name}</b></p>
                  <p>Seat Fabric: <b>{!!VehicleSeat[activity.activity_data.vehicle.seat_type] ? !!VehicleSeat[activity.activity_data.vehicle.seat_type] : "-"}</b></p>
                  <p>Passenger: <b>{activity.activity_data.vehicle.passenger_count}</b></p>
                  <p>Color: <b>{VehicleColor[activity.activity_data.vehicle.color]}</b></p>
                </div>);
              customIcon = (<CarOutlined className='timeline-icon' />)
              break;

            case 'vehicle_deleted':
              activityDotColor = 'red';
              activityText = (
                <div>
                  <p>Vehicle #<b>{activity.activity_data.vehicle_id}</b> deleted by <b>{activity.created_by.name}</b></p>
                </div>);
              customIcon = (<CarOutlined className='timeline-icon' />)
              break;

            case 'affiliate_deleted':
              activityDotColor = 'red';
              activityText = (
                <div>
                  <p>Affiliate #<b>{activity.affiliate_id}</b> deleted by <b>{activity.created_by.name}</b></p>
                </div>);
              customIcon = (<UserOutlined className='timeline-icon' />)
              break;

              case 'send_sms':
                activityDotColor = 'blue';
                activityText = (
                  <div>
                    <p><b>{activity.created_by.name}</b> sent a SMS: <i>{activity.activity_data.message}</i></p>
                  </div>);
                customIcon = (<MessageOutlined className='timeline-icon' />)
                break;

                case 'send_email':
                  activityDotColor = 'blue';
                  activityText = (
                    <div>
                      <p><b>{activity.created_by.name}</b> sent an email with subject <i>{activity.activity_data.subject}</i>.</p>
                    </div>);
                  customIcon = (<MailOutlined className='timeline-icon' />)
                  break;  

            default:
              activityDotColor = 'blue';
              activityText = activity.activity_type;
              break;
          };

          const la = {
            color: activityDotColor,
            children: activityText,
            label: FormatDate(activity.created_at),
            dot: customIcon,
          }
          leadActivity.push(la);
        });
        setLeadActivity(leadActivity);
      }
    }).catch((e) => {
      console.log('e');
    });
    // eslint-disable-next-line
  }, [id]);

  const statusUpdate = (newStatus) => {
    let _data = { ...data };
    if (newStatus) {
      _data.status = newStatus;
    }
    setIsLoading(true);
    UpdateAffiliateStatus(id, _data.status, note)
      .then((d) => {
        setIsLoading(false);
        setIsModalOpen(false);
        setData(_data);
        if (d.code === 200) {
          messageApi.open({
            key,
            type: 'success',
            content: `Status changed successfully.`,
          });
        }
      })
      .catch((e) => {
        messageApi.open({
          key,
          type: 'error',
          content: e.message,
        });
      });
  };

  const statusChange = (value, options) => {
    // update data only when select one item or clear action
    if (value?.length > 1) {
      let _value = value[value.length - 1];
      setConfirmationStatus(_value);
      // setIsModalOpen(true); // if we want to show model
      statusUpdate(_value);
    }
  };

  const options = [
    {
      value: GetEnumKey(Status, Status.approved),
    },
    {
      value: GetEnumKey(Status, Status.rejected),
    },
  ];

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      hidden: true,
    },
    {
      title: 'Vehicle',
      dataIndex: 'category',
      key: 'category',
      render: (_, { category }) => <p>{VehicleCategory[category]}</p>,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      render: (_, { color }) => <p className='affiliate-color'>{color}</p>,
    },
    {
      title: 'Passengers',
      dataIndex: 'passenger_count',
      key: 'passenger_count',
    },
    {
      title: 'Seat Fabric',
      dataIndex: 'seat_fabric',
      key: 'seat_fabric',
      render: (_, { seat_fabric }) => (
        <p className='affiliate-color'>{seat_fabric}</p>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status, id }) => (
        <Tag color={GetTagColor(Status[status])} key={`${id}-${status.tag}`}>
          {status.toString().toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'ud',
      key: 'ud',
      render: (_, { key, index }) => (
        <div>
          <EditOutlined className='vehicle-action-icon'
            onClick={() => {
              setIsEditVehicleModalOpen(true);
              setEditModeVehicleIndex(index);
            }} />
          <Popover content={() => DeletePopContent(id, key, index)} title="Are you sure about deleting this?">
            <DeleteOutlined className='vehicle-action-icon' />
          </Popover>
        </div>
      ),
    },
  ];

  const contactsColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      hidden: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, { name, is_primary }) => (
        <>
          <p>
            {name} {is_primary && <Tag color={'green'}>{'Primary'}</Tag>}
          </p>
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (_, { phone_number }) => (
        <p>{FormatPhone(phone_number)}</p>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'ud',
      key: 'ud',
      render: (_, { key, index, is_primary }) => (
        <div>
          <EditOutlined className='vehicle-action-icon'
            onClick={() => {
              setIsEditContactModalOpen(true);
              setEditModeContactIndex(index);
            }} />
          {!is_primary && 
          <Popover content={() => DeleteContactPopContent(id, key, index)} title="Are you sure about deleting this?">
            <DeleteOutlined className='vehicle-action-icon' />
          </Popover>}
        </div>
      ),
    },
  ];

  const updateData = (type, value) => {
    let _data = { ...data };
    _data[type] = value;
    setData(_data);
  };

  const updateAddress = async (type, value) => {
    let _data = { ...data };
    _data.address[type] = value;
    if (type === 'zipcode') {
      const locationData = await FetchLocation(value);
      if (locationData && locationData.city && locationData.state) {
        _data.address['city'] = locationData.city.text;
        _data.address['state'] = locationData.state.text;
      }
      setData(_data);
    } else {
      setData(_data);
    }
  };

  const saveAffiliateDetails = () => {
    let _data = data;
    if (!_data.email || !ValidateEmail(_data.email)) {
      messageApi.open({
        key,
        type: 'error',
        content: `Email address seems to be wrong.`,
      });
    } else if (!_data.website || !ValidateURL(_data.website)) {
      messageApi.open({
        key,
        type: 'error',
        content: `Website link seems to be wrong.`,
      });
    } else if (_data.dot_number && _data.dot_number.length > 10) {
      messageApi.open({
        key,
        type: 'error',
        content: `Please enter valid DOT number.`,
      });
    }
    else if (!_data.phone_number || _data.phone_number.length < 10) {
      messageApi.open({
        key,
        type: 'error',
        content: `Please add valid phone number.`,
      });
    } else if (!_data.address.zipcode || !ValidateZIP(_data.address.zipcode)) {
      messageApi.open({
        key,
        type: 'error',
        content: `Please add valid zip code.`,
      });
    }
    else if (!_data.name) {
      messageApi.open({
        key,
        type: 'error',
        content: `Company name can not be blank.`,
      });
    }
    else if (!_data.address.street_address) {
      messageApi.open({
        key,
        type: 'error',
        content: `Street address can not be blank.`,
      });
    } else {
      setIsEditMode(false);
      setIsLoading(true);
      _data.phone_number = FormatPhoneNumberForServer(_data.phone_number)
      UpdateAffiliateDetails(id, _data)
        .then((d) => {
          setIsLoading(false);
          if (d.code === 200) {
            // Update data in the state
            setData(d.data);
            setNote(d.data.admin_note);
            messageApi.open({
              key,
              type: 'success',
              content: `Affiliate details saved.`,
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
          messageApi.open({
            key,
            type: 'error',
            content: e.message,
          });
        });
    }
  };

  const addVehicle = (vehicle) => {
    let _vehicles = [...vehicles];
    // check for duplication
    if (_vehicles.some((v) => 
      v.category === vehicle.category &&
      v.color === vehicle.color &&
      v.seat_fabric === vehicle.seat_fabric &&
      v.passenger_count === vehicle.passenger_count)) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Vehicle already exists!',
      });
      return;
    }
    setIsVehicleLoading(true);
    if (editModeVehicleIndex > -1) {
      EditVehicle(id, vehicle.id, vehicle).then((d) => {
        if (d.code === 200) {
          _vehicles[editModeVehicleIndex] = vehicle;
          messageApi.open({
            type: 'success',
            content: d.message
          });
        } 
        setIsEditVehicleModalOpen(false);
        setEditModeVehicleIndex(-1);
        setVehicles(_vehicles);
        // getAffVehicles(); // in edit just update the data locally
        setIsVehicleLoading(false);
      }).catch((e) => {
        messageApi.open({
          type: 'error',
          content: e.message
        });
        setIsVehicleLoading(false);
      });
    } else {
      CreateVehicle(id, vehicle).then((d) => {
        if (d.code === 200) {
          // _vehicles.push(vehicle);
          messageApi.open({
            type: 'success',
            content: d.message
          });
        }
        setIsEditVehicleModalOpen(false);
        setEditModeVehicleIndex(-1);
        // setVehicles(_vehicles);
        setIsVehicleLoading(false);
        getAffVehicles();
      }).catch((e) => {
        messageApi.open({
          type: 'error',
          content: e.message
        });
        setIsVehicleLoading(false);
      });
    }
  };

  const addContact = (contact) => {
    let _contacts = [...contacts];
    setIsContactLoading(true);
    if (editModeContactIndex > -1) {
      EditContact(id, contact.id, contact).then((d) => {
        if (d.code === 200) {
          _contacts[editModeContactIndex] = contact;
          messageApi.open({
            type: 'success',
            content: d.message
          });
        }
        setIsEditContactModalOpen(false);
        setIsContactLoading(false);
        setEditModeContactIndex(-1);
        setContacts(_contacts);
      }).catch((e) => {
        messageApi.open({
          type: 'error',
          content: e.message
        });
        setIsContactLoading(false);
      });
    } else {
      // Create contact
      CreateContact(id, contact).then((d) => {
        if (d.code === 200) {
          // _contacts.push(contact);
          messageApi.open({
            type: 'success',
            content: d.message
          });
        }
        setIsEditContactModalOpen(false);
        setEditModeContactIndex(-1);
        // setContacts(_contacts);
        setIsContactLoading(false);
        getAffContacts();
      }).catch((e) => {
        messageApi.open({
          type: 'error',
          content: e.message
        });
        setIsContactLoading(false);
      });
    }
  };  

  const DeletePopContent = (id, key, index) =>(
    <div>
      <Button type="primary" danger onClick={() => {
        DeleteVehicle(id, key).then((d) => {
          if (d.code === 200) {
            messageApi.open({
              type: 'success',
              content: d.message
            });
            let _vehicles = [...vehicles];
            _vehicles.splice(index, 1);
            setVehicles(_vehicles);
          } else {
            messageApi.open({
              type: 'error',
              content: d.message
            });
          }
        }).catch((e) => {
          messageApi.open({
            type: 'error',
            content: e.message
          });
        });
      }}>
      Yes</Button>
    </div>
  );

  const DeleteContactPopContent = (id, key, index) =>(
    <div>
      <Button type="primary" danger onClick={() => {
        DeleteContact(id, key).then((d) => {
          if (d.code === 200) {
            messageApi.open({
              type: 'success',
              content: d.message
            });
            
            let _contacts = [...contacts];
            _contacts.splice(index, 1);
            setContacts(_contacts);
          } else {
            messageApi.open({
              type: 'error',
              content: d.message
            });
          }
        }).catch((e) => {
          messageApi.open({
            type: 'error',
            content: e.message
          });
        });
      }}>
      Yes</Button>
    </div>
  );

  return (
    <div className='lead-details-container affiliate-details-container'>
      <BackBtn />
      {contextHolder}
      {data && (
        <div className='lead-detail-section'>
          <h1>
            <Input
              placeholder='Enter company name'
              value={data.name}
              maxLength={60}
              className='company-name'
              variant={`${isEditMode ? 'outlined' : 'borderless'}`}
              disabled={!isEditMode}
              onChange={(e) => updateData('name', e.target.value)}

            />
            <p className='affiliate-id'>#{id}</p>
          </h1>
          {data.is_deleted === '1' &&
            <Text type="danger" italic>This affiliate account has requested to delete account from mobile application.</Text>}

          {/* <br /> */}
          <div className='flex-separate'>
            <div className='note-detail-container'>
              <h3 className='text-ul'>Admin Note</h3>
              <TextArea
                rows={4}
                placeholder='Admin note'
                variant={`${isEditMode ? 'outlined' : 'borderless'}`}
                // maxLength={100}
                value={note}
                disabled={!isEditMode}
                onChange={(e) => {
                    setNote(e.target.value);
                    updateData('admin_note', e.target.value);
                }}
                // showCount={isEditMode}
                size={'large'}
                style={{
                  height: 120,
                  resize: 'none',
                }}
              />
            </div>
            <div className='status-container'>
              Status &nbsp;
              <Select
                mode='tags'
                tagRender={TagRender}
                value={[Status[data.status]]}
                style={{
                  width: '100%',
                }}
                popupClassName={'affiliate-status-tag'}
                className={'affiliate-status-tag'}
                options={options}
                showSearch={false}
                maxCount={2}
                maxTagCount={2}
                onChange={statusChange}
                loading={isLoading}
                disabled={data.is_deleted === '1'}
              />
            </div>
          </div>

          <Row>
            <Col xs={24} md={16} xl={16}>

              <h3 className='text-ul'>Service Details</h3>
              <div className='flex-separate align-base'>
                <div className='service-detail-container'>
                  <div className='title-container'>
                    <span>
                      <b>Dot #: </b>
                      <Input
                        placeholder='Dot number'
                        style={{
                          width: '60%',
                        }}
                        maxLength={10}
                        variant={`${isEditMode ? 'outlined' : 'borderless'}`}
                        disabled={!isEditMode}
                        value={data.dot_number}
                        onChange={(e) => updateData('dot_number', e.target.value)}
                      />
                    </span>
                  </div>
                  <div className='title-container'>
                    <b>Insurance:</b>{' '}
                    <Switch
                      checkedChildren={
                        <CheckCircleTwoTone twoToneColor='#52c41a' />
                      }
                      unCheckedChildren={
                        <CloseCircleTwoTone twoToneColor='#eb2f96' />
                      }
                      defaultChecked={data.is_cross_state_insured}
                      disabled={!isEditMode}
                      onChange={(checked) =>
                        updateData('is_cross_state_insured', checked)
                      }
                    />
                  </div>
                  <div className='title-container'>
                  <b>Pricing Label:</b>{' '}
                  <Select 
                        placeholder='Pricing level'
                        style={{
                          width: '60%',
                        }}
                        value={data.pricing_label}
                        variant={`${isEditMode ? 'outlined' : 'borderless'}`}
                        disabled={!isEditMode}
                        options={GetOptionForEnum(PricingLabel).map((category) => {
                          return {
                            value: category.text,
                            label: <div className="flex-separate jc-start">
                              <div>{category.text}</div>
                            </div>,
                          };
                        })}
                        showSearch={false}
                        onChange={(e) => updateData('pricing_label', e)}
                      />
                  </div>
                  {/*  Support to add timezone */}
                  <div className='title-container'>
                  <b>Timezone:</b>{' '}
                  <Select 
                        placeholder='Select Timezone'
                        style={{
                          width: '60%',
                        }}
                        value={data.timezone}
                        variant={`${isEditMode ? 'outlined' : 'borderless'}`}
                        disabled={!isEditMode}
                        options={GetOptionForEnum(TimeZones).map((timezoneData) => {
                          return {
                            value: timezoneData.text,
                            label: <div className="flex-separate jc-start">
                              <div>{`${timezoneData.text} (${timezoneData.value})`}</div>
                            </div>,
                          };
                        })}
                        showSearch={false}
                        onChange={(e) => updateData('timezone', e)}
                      />
                  </div>
                  {/* Support show already ready affiliates for quotation */}
                  <div className='title-container'>
                    <b> Always Ready For Quotation:</b>{' '}
                    <Switch
                      checkedChildren={
                        <CheckCircleTwoTone twoToneColor='#52c41a' />
                      }
                      unCheckedChildren={
                        <CloseCircleTwoTone twoToneColor='#eb2f96' />
                      }
                      defaultChecked={data.is_always_available_for_quotes}
                      disabled={!isEditMode}
                      onChange={(checked) =>
                        updateData('is_always_available_for_quotes', checked)
                      }
                    />
                  </div>
                </div>
                <div className='approval-detail-container'>
                  <p>
                    {/* <b>Applied on:</b> {new Date(data.created_at).toLocaleString()} */}
                    <b>Applied on:</b> {FormatDateWithDay(dayjs(data.created_at).format('MM-DD-YYYY'))}
                  </p>
                  {data.status_updated_by && (
                    <p>
                      <b>Status updated by:</b> {data.status_updated_by.name}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <div className='flex-separate align-start'>
                  <div className='contact-details-container'>
                    <h3 className='text-ul'>Contact Details</h3>
                    <Flex vertical gap={12}>
                      {isEditMode ? (<Input
                          placeholder='Phone'
                          maxLength={14}
                          variant={`${isEditMode ? 'outlined' : 'borderless'}`}
                          disabled={!isEditMode}
                          prefix={
                            <PhoneOutlined
                              style={{ fontSize: 15 }}
                              className='contact-icon'
                            />
                          }
                          value={FormatPhoneNumberForDisplay(data.phone_number)}
                          onChange={(e) => updateData('phone_number', e.target.value)}
                        /> ) :
                        (<p className='website-link-container'>
                        <PhoneOutlined
                          style={{ fontSize: 15 }}
                          className='contact-icon'
                        />{' '}
                        <span className='cursor-pointer' onClick={(e) => setIsSmsModelOpen(true)} >
                          {FormatPhoneNumberForDisplay(data.phone_number)}
                        </span>
                      </p>)
                      }

                      {isEditMode ? (
                        <Input
                          placeholder='Email'
                          maxLength={30}
                          variant={`${isEditMode ? 'outlined' : 'borderless'}`}
                          prefix={
                            <MailOutlined
                              style={{ fontSize: 15 }}
                              className='contact-icon'
                            />
                          }
                          value={data.email}
                          onChange={(e) => updateData('email', e.target.value)}
                        />
                      ) : (
                        <p className='website-link-container'>
                          <MailOutlined
                            style={{ fontSize: 15 }}
                            className='site-form-item-icon'
                          />{' '}
                          <span className='cursor-pointer' onClick={(e) => setIsEmailModelOpen(true)} >
                            {data.email}
                          </span>
                        </p>
                      )}

                      {isEditMode ? (
                        <Input
                          placeholder='Website'
                          maxLength={500}
                          variant={`${isEditMode ? 'outlined' : 'borderless'}`}
                          prefix={
                            <GlobalOutlined
                              style={{ fontSize: 15 }}
                              className='contact-icon'
                            />
                          }
                          value={data.website}
                          onChange={(e) => updateData('website', e.target.value)}
                        />
                      ) : (
                        <p className='website-link-container'>
                          <GlobalOutlined
                            style={{ fontSize: 15 }}
                            className='site-form-item-icon'
                          />{' '}
                          <a href={GetClickableLink(data.website)} rel='noreferrer' target='_blank'>
                            {data.website}
                          </a>
                        </p>
                      )}
                    </Flex>
                  </div>
                  <div className='address-detail-container'>
                    <h3 className='text-ul'>Address:</h3>
                    <Flex vertical gap={3} style={{ marginLeft: -10 }}>
                      <Input
                        placeholder='Street address'
                        maxLength={250}
                        variant={`${isEditMode ? 'outlined' : 'borderless'}`}
                        disabled={!isEditMode}
                        value={data.address.street_address}
                        onChange={(e) =>
                          updateAddress('street_address', e.target.value)
                        }
                      />
                      <Input
                        placeholder='City'
                        maxLength={30}
                        variant={`borderless`}
                        disabled={true}
                        value={data.address.city}
                        onChange={(e) => updateAddress('city', e.target.value)}
                      />
                      <Input
                        placeholder='State'
                        maxLength={30}
                        variant={`borderless`}
                        disabled={true}
                        value={data.address.state}
                        onChange={(e) => updateAddress('state', e.target.value)}
                      />
                      <Input
                        placeholder='Zip code'
                        maxLength={5}
                        variant={`${isEditMode ? 'outlined' : 'borderless'}`}
                        disabled={!isEditMode}
                        value={data.address.zipcode}
                        onChange={(e) => updateAddress('zipcode', e.target.value)}
                      />
                    </Flex>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={24} md={8} xl={8}>
              {leadActivity.length > 0 && <>
                <h3 className='text-ul'>Activity </h3>
                <Timeline className='timeline-container' items={leadActivity} mode={'left'} />
              </>}
            </Col>
          </Row>
          <br />
          <Flex gap='small' wrap='wrap'>
            <Button
              type='default'
              block={false}
              className='btn'
              onClick={() => {
                setIsEditMode(!isEditMode);
                getAffiliateInfo();
              }}
              size='medium'
            >
              {isEditMode ? 'Cancel' : 'Edit'}
            </Button>
            <Button
              type='primary'
              block={false}
              className='btn'
              loading={isLoading}
              disabled={!isEditMode}
              onClick={saveAffiliateDetails}
              size='medium'
            >
              Save
            </Button>
            <Button
              type='primary'
              danger
              block={false}
              className='btn'
              onClick={() => setIsDeleteModalOpen(true)}
              size='medium'
            >
              Delete
            </Button>
          </Flex>
          <Modal
            title='Please confirm!'
            open={isModalOpen}
            centered
            // okText={'Yes'} cancelText={'No'} onOk={statusUpdate}
            onCancel={() => setIsModalOpen(false)}
            footer={[
              <Button key='back' onClick={() => {
                setIsModalOpen(false);
              }}>
                No
              </Button>,
              <Button
                key='submit'
                type='primary'
                loading={isLoading}
                onClick={() => statusUpdate(true)}
              >
                Yes
              </Button>,
            ]}
          >
            <p>
              Are you sure about{' '}
              {`${confirmationStatus === GetEnumKey(Status, Status.approved)
                ? 'approving'
                : 'rejecting'
                }`}{' '}
              this Affiliate?
            </p>

            <TextArea
              rows={4}
              placeholder='Admin note'
              // maxLength={100}
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
                updateData('admin_note', e.target.value);
              }}
              // showCount
              size={'large'}
              style={{
                height: 120,
                resize: 'none',
                marginBottom: 20,
              }}
            />
          </Modal>

          <Modal centered title="Delete Affiliate" open={isDeleteModalOpen}
            onCancel={() => setIsDeleteModalOpen(false)}
            footer={[
              <Button key='back' onClick={() => {
                setIsDeleteModalOpen(false);
              }}>
                No
              </Button>,
              <Button
                key='submit'
                type='primary'
                danger
                loading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  DeleteAffiliate(id).then((d) => {
                    if (d.code === 200) {
                      messageApi.open({
                        type: 'success',
                        content: d.message
                      });
                      setTimeout(() => {
                        navigate('/affiliates');
                      }, 1000);
                    } else {
                      messageApi.open({
                        type: 'error',
                        content: d.message
                      });
                    }
                    setIsLoading(false);
                    setIsDeleteModalOpen(false);
                  }).catch((e) => {
                    messageApi.open({
                      type: 'error',
                      content: e.message
                    });
                    setIsLoading(false);
                    setIsDeleteModalOpen(false);
                  });
                }}
              >
                Yes
              </Button>,
            ]}>
            <p>Are you sure to delete this affiliate?</p>
          </Modal>
        </div>
      )}
      {vehicles && (
        <>
        {isEditVehicleModalOpen && <VehicleInfoModal 
          isModalOpen={isEditVehicleModalOpen}
          setIsModalOpen={setIsEditVehicleModalOpen}
          addVehicleIntoList={addVehicle}
          editModeIndex={editModeVehicleIndex}
          loading={isVehicleLoading}
          messageApi={messageApi}
          vehicle={editModeVehicleIndex > -1 ? vehicles[editModeVehicleIndex] : null}
        />}
        <div className='p-ant-table-container vehicle-table'>
          <Table
            title={() => 
            <div className='flex-separate'>
              <h3>Vehicles</h3>
              {/* <Button
                type='primary'
                className='btn'
                onClick={() => {
                  setIsEditVehicleModalOpen(true);
                  setEditModeVehicleIndex(-1);
                }}  
                >
                  Add Vehicle
                </Button> */}
            </div>}
            dataSource={vehicles}
            columns={columns}
            rowClassName={(record, index) => `tbl-${record.status.color}-row`}
            scroll={{
              x: 200,
            }}
            loading={vehicles.length === 0}
            pagination={false}
          />
        </div>
        </>
      )}

      {contacts && (
        <>
        {isEditContactModalOpen && <ContactInfoModal 
          isModalOpen={isEditContactModalOpen}
          setIsModalOpen={setIsEditContactModalOpen}
          addContact={addContact}
          editModeIndex={editModeContactIndex}
          loading={isContactLoading}
          messageApi={messageApi}
          contact={editModeContactIndex > -1 ? contacts[editModeContactIndex] : null}
        />}
        <div className='p-ant-table-container contact-table'>
          <Table
            title={() => 
              <div className='flex-separate'>
              <h3>Contacts</h3>
              {/* <Button
                type='primary'
                className='btn'
                disabled={contacts.length === 3}
                onClick={() => {
                  setIsEditContactModalOpen(true);
                  setEditModeContactIndex(-1);
                }}  
                >
                  Add Contact
                </Button> */}
            </div>
            }
            dataSource={contacts}
            columns={contactsColumns}
            // rowClassName={(record, index) => `tbl-${record.status.color}-row`}
            scroll={{
              x: 200,
            }}
            loading={contacts.length === 0}
            pagination={false}
          />
        </div>
        </>
      )}

      
      {isSmsModelOpen && <SmsModal 
        isModalOpen={isSmsModelOpen}
        setIsModalOpen={setIsSmsModelOpen}
        msgType="affiliate"
        phoneNumber={data.phone_number}
        id={id}
      />}

      {isEmailModelOpen && <EmailModal 
        isModalOpen={isEmailModelOpen}
        setIsModalOpen={setIsEmailModelOpen}
        msgType="affiliate"
        emailAddress={data.email}
        id={id}
      />}

    </div>
  );
};

export default AffiliateDetails;
