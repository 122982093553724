// Import Module
import React, { useState } from "react";
import { Modal, Input, message } from "antd";
import { UpdateTripDetails } from "../services/api";

const { TextArea } = Input ;

const TripNote = ({
  isLeadNoteModel,
  setIsLeadNoteModel,
  editLeadId,
  leadNoteToEdit,
  callback,
}) => {
  const [leadNote, setLeadNote] = useState(leadNoteToEdit); //eslint-disable-line
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  return (
    <Modal centered title="Edit trip details" open={isLeadNoteModel}
        okText="Save"
        confirmLoading={isLoading}
        onOk={() => {
          setIsLoading(true);
          UpdateTripDetails(editLeadId, leadNote).then((d) => {
            setIsLoading(false);
            if (d.code === 200) {
              messageApi.open({
                key,
                type: "success",
                content: `Trip details saved successfully.`,
              });
            } else {
              messageApi.open({
                key,
                type: "error",
                content: `Failed to save Trip details.`,
              });
            }
            callback(leadNote);
            // timeout to show message and close modal
            setTimeout(() => {
              setIsLeadNoteModel(false);
            }, 1000);
          });
        }}
        onCancel={() => {
          setIsLeadNoteModel(false);
        }}>
        {contextHolder}
        <TextArea 
          value={leadNote} 
          onChange={(e) => setLeadNote(e.target.value)}
          rows={4}
          maxLength={3000}
          size={'large'}
          style={{
            height: 120,
            resize: 'none',
          }}
          placeholder='Enter trip details'/>
      </Modal>
  );
};

export default TripNote;
