// Import Module
import React, { useState } from "react";
import { Select, message } from "antd";
import { NotifyAgent } from "../services/api";

const AgentDropdown = ({
  id,
  agents,
  className
}) => {
  
  const [sentToAgentName, setSentToAgentName] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const notifyAgent = (v) =>{
    const payload = {
        "agent_id": v,
        "includeLink": false, //includeLink
    };
    NotifyAgent(id, payload)
        .then((d) => {
            if (d.code === 200) {
                messageApi.open({
                    key,
                    type: 'success',
                    content: `Agent notified successfully.`,
                });
            }
        }).catch((e) => {
            messageApi.open({
                key,
                type: 'error',
                content: e.message,
            });
        });
  }

  return (
    <>
      {contextHolder}
      <Select
        value={sentToAgentName}
        placeholder='Send lead to agent'
        onChange={(v, o) => {
            setSentToAgentName(v);
            if(v){
                notifyAgent(v);
            }
        }}
        className={`mt-10 agent-list-dropdown tbl-agent-dropdown ${className}`}
        options={agents}
        listHeight={'100%'}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          return false;
        }}
      />  
    </>  
  );
};

export default AgentDropdown;
