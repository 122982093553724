// Import Module
import React, { useState } from "react";
import { Modal, Select, message } from "antd";
import { UpdateLead } from "../services/api";
import { TripType } from "../constants";
import { GetEnumKey } from "../services/helper";

const HourOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: TripType.one_way_transfer, value: GetEnumKey(TripType, TripType.one_way_transfer) },
  { label: TripType.multiple_day_usage, value: GetEnumKey(TripType, TripType.multiple_day_usage) }];

const TripHours = ({
  isTripHoursModel,
  setIsTripHoursModel,
  editLeadId,
  defaultHours,
  callback,
}) => {
  const [hours, setHours] = useState(defaultHours); //eslint-disable-line
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  return (
    <Modal centered title="Edit trip hours" open={isTripHoursModel}
        okText="Save"
        confirmLoading={isLoading}
        onOk={() => {
          setIsLoading(true);
          let payload = { trip_hours: hours };
          if(hours === TripType.one_way_transfer 
            || hours === TripType.multiple_day_usage){
              payload = { 
                trip_hours: 0,
                trip_type: GetEnumKey(TripType, hours) 
              };
          } else {
            payload = { 
              trip_hours: hours,
              trip_type: GetEnumKey(TripType, TripType.fixed_hours) 
            };
          }
          UpdateLead(editLeadId, payload).then((d) => {
            setIsLoading(false);
            if (d.code === 200) {
              messageApi.open({
                key,
                type: "success",
                content: `Trip hours saved successfully.`,
              });
            } else {
              messageApi.open({
                key,
                type: "error",
                content: `Failed to save Trip hours.`,
              });
            }
            callback(payload);
            // timeout to show message and close modal
            setTimeout(() => {
              setIsTripHoursModel(false);
            }, 1000);
          });
        }}
        onCancel={() => {
          setIsTripHoursModel(false);
        }}>
        {contextHolder}
        <Select
          value={hours}
          placeholder='Select hours'
          onChange={(v, o) => setHours(o.label)}
          className='w-100'
          options={HourOptions}
      />
        {/* <InputNumber 
          min={0} max={100} 
          value={hours}
          style={{ width: '100%' }}
          onChange={(v) => setHours(v)}
          onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
              }
          }}
       />   */}
      </Modal>
  );
};

export default TripHours;
