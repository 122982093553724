// Import Module
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const BackBtn = (props) => {
    const navigate = useNavigate();
    const pageURL = props.pageURL;

    // If props are available redirect to that page 
    // else jump to the detail browser page which was previously opened
    const pageNavigator = () => {
        if(pageURL){
            navigate(pageURL);
        }
        else{
            navigate(-1)
        }
    }

    return (
        <div className='back-icon-container' onClick={() => pageNavigator()}>
            <ArrowLeftOutlined className='back-icon' /> <p className='back-text'>Back</p>
        </div>
    );
};

export default BackBtn;