// Import Module
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Table, Tag, Space, Input, message, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { AutoUpdateStatus, GetLeadQuote, GetAgentsWrapper, UpdateResponseCount } from '../services/api';
import { Events } from '../constants';
import { FormatDate, FindUniqueAffiliateName, GetTotalAffiliatesAndResponses, 
  FormatDateWithDay, ConvertTo12HourFormat, GetTimeDifferenceInHours, SaveLeadIdIntoLocalStorage, 
  SaveFilterIntoLocalStorage, GetFilterFromLocalStorage, GetVT} from '../services/helper';

import AgentDropdown from '../components/agent_dropdown';

import { BadgeCountContext } from '../context/unreadContext.js';

const { Text } = Typography;
const ResponseNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [mtbLoading, setMtbLoading] = useState(null); // storing id of the lead for which move to box is clicked
  const [dataSource, setDataSource] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      total: 1,
      current: 1,
      pageSize: 100,
    },
  });
  /* eslint-disable-next-line */
  const [searchText, setSearchText] = useState('');
  /* eslint-disable-next-line */
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const tableRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [agents, setAgents] = useState([]);

  const { setValue } = useContext(BadgeCountContext);

  const getLeadData = (tableParams) => {
    let params = `per_page_rows=${tableParams.pagination.pageSize}&current_page=${tableParams.pagination.current}`;
    if (tableParams.sorter?.columnKey) {
      params += '&sort_by=' + tableParams.sorter.columnKey;
      if (tableParams.sorter?.order) {
        const order = tableParams.sorter.order === 'ascend' ? 'ASC' : 'DESC';
        params += '&order_by=' + order;
      }
    }
    if (tableParams.filters) {
      Object.keys(tableParams.filters).forEach((key) => {
        if (tableParams.filters[key]) {
          const _val = tableParams.filters[key][0];
          params += `&${key}=${_val}`;
        }
      });
    }
    setLoading(true);
    GetLeadQuote(params).then((d) => {
      setLoading(false);
      if (d.code === 200) {
        const { leads, pagination } = d.data;

        let data = [];
        leads.forEach((l) => {
          const affiliates = l?.affiliates;
          const uniqueAffiliateName = FindUniqueAffiliateName(affiliates);
          const responseSummary = GetTotalAffiliatesAndResponses(affiliates);
          let obj = {
            id: l.id,
            key: l.id,
            leads_details: {
              pickup_city: l.pickup_city,
              pickup_state: l.pickup_state,
              pickup_date: dayjs(l.pickup_date).format('MM-DD-YYYY'),
              dropoff_city: l.dropoff_city,
              dropoff_state: l.dropoff_state,
              passenger_count: l.passenger_count,
              trip_event: Events[l.trip_event],
              trip_hours: l.trip_hours,
              pickup_time: l.pickup_time,
            },
            trip_details: l.trip_details,
            veh_type: l.veh_type,
            customer_link: l.customer_link,
            autolink: l.autolink,
            request_sent: l.last_request_time,
            last_quote_time: l.last_quote_time,
            affiliates: affiliates,
            uniqueAffiliateName: uniqueAffiliateName,
            requestSummary: responseSummary,
          }

          data.push(obj);
        });
        setDataSource(data);

        // Commenting logic of auto scrolling

        // let leadToScroll = GetLeadIdIntoLocalStorage(window.location.pathname);
        // // if ref available with table scroll to row
        // if (tableRef.current && leadToScroll) {
        //   setTimeout(() => {
        //     tableRef.current.scrollTo({ key: leadToScroll});
        //     RemoveLeadIdFromLocalStorage(window.location.pathname);
        //   }, 2000);
        // }

        // Set pagination
        let _pagination = {
          total: pagination.total_items,
          current: pagination.current_page,
          pageSize: parseInt(pagination.per_page_rows),
        };
        if (tableParams.pagination.current === 1) {
          const { filters, sorter } = tableParams;
          setTableParams({ pagination: _pagination, filters, sorter });
        }
      }
    });
  };

  useEffect(() => {
    let defaultFilter = GetFilterFromLocalStorage(window.location.pathname);
    if(defaultFilter) {
      setTableParams(defaultFilter);
      getLeadData(defaultFilter);
    } else {
      getLeadData(tableParams);
    }
    GetAgentsWrapper().then((agents) => {
      setAgents(agents);
    });

    // Wait for 4 seconds before making the read API call 
    setTimeout(() => {
      // TODO: Make API call to read
      UpdateResponseCount().then((response) => {
        setValue(0);
      });
    }, 5000)
  }, []) // eslint-disable-line


  const handleTableChange = (pagination, filters, sorter) => {
    const _tblParams = { pagination, filters, sorter };
    setTableParams(_tblParams);
    getLeadData(_tblParams);
    const path = window.location.pathname;
    SaveFilterIntoLocalStorage(path, _tblParams);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const appendSortOrder = (columnKey) => {
    if(tableParams?.sorter?.columnKey === columnKey){
      return {
        sortOrder:tableParams?.sorter?.order,
      }
    }
  };
  
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
      ...appendSortOrder('id'),
      // sortOrder: tableParams?.sorter?.columnKey === 'id'? tableParams?.sorter?.order : null,
      width: 50
    },
    {
      title: 'Leads Details',
      dataIndex: 'leads_details',
      key: 'leads_details',
      render: (_, { leads_details }) => (
        <div>
          <div>
            {FormatDateWithDay(leads_details.pickup_date)} | {leads_details.passenger_count} PAX | {leads_details.trip_event} 
            <br /><br />
          </div>
          <div>
            {leads_details.pickup_city}, {leads_details.pickup_state} → {leads_details.dropoff_city}, {leads_details.dropoff_state}
            <br /><br />
          </div>
          <div>
            {ConvertTo12HourFormat(leads_details.pickup_time)} {leads_details.trip_hours !== 0 ? `| ${leads_details.trip_hours} Hrs` : ""} 
          </div>
        </div>
      ),
      width: 250,
    },
    {
      title: 'Trip Details',
      dataIndex: 'trip_details',
      key: 'trip_details',
      render: (_, { trip_details, customer_link, veh_type, autolink }) => (
        <div>
          {trip_details}
          <br />
          {veh_type && veh_type.length > 0 && <p>Vehicle Type: {GetVT(veh_type)}</p>}
          <br />
          {(customer_link && autolink === 1) && <p className="customer-link-generated-txt">Custom Link</p>}
          {(customer_link && autolink === 2) && <p className="customer-link-generated-txt auto-link">Autolink</p>}
        </div>
      ),
      width: 250,
    },
    {
      title: 'Last Request Sent',
      dataIndex: 'request_sent',
      key: 'request_sent',
      render: (_, { request_sent }) => (
        <div>
          <div>
            {FormatDate(request_sent)}
          </div>
        </div>
      ),
      width: 120
    },
    {
      title: 'Last Quote Time',
      dataIndex: 'last_quote_time',
      key: 'last_quote_time',
      render: (_, { last_quote_time }) => (
        <div>
          <div>
            {FormatDate(last_quote_time)}
          </div>
        </div>
      ),
      width: 120
    },
    {
      title: 'Status',
      dataIndex: 'affiliate_name',
      key: 'affiliate_name',
      filteredValue: tableParams?.filters?.affiliate_name || null,
      ...getColumnSearchProps('affiliate_name'),
      render: (_, { last_quote_time, requestSummary, customer_link, autolink }) => (
        <div>
          <div className={`request-sent ${(GetTimeDifferenceInHours(last_quote_time) <= 3)? 'active': ''}`}>
            {requestSummary.totalAffiliates} {requestSummary.totalAffiliates === 1 ? "Affiliate" : "Affiliates"}, {requestSummary.totalRequests} {requestSummary.totalRequests === 1 ? "Vehicle" : "Vehicles"}
          </div>
          {/* Doing this as we have a helper function to breakdown per aff details and render it */}
          {Object.keys(requestSummary.requestsPerAffiliate).map(key => {
            const rs = requestSummary.requestsPerAffiliate[key];
            return (
              <div key={key}>
                <Tag className="aff-tags" color={rs.tagColor}>
                  {key} ({rs.responses} / {rs.vehiclesCount})
                </Tag>
                {Object.keys(rs.sentToAgent).map((vehicleKey, index) => {
                  const st = rs.sentToAgent[vehicleKey];
                  return <div key={`sent-to-agent-${index}`} className='pending-sent-to-agent-resp'>
                    {st.str} | <>{st.priceStr.map((p, pi)=>{
                      const _p = p.split('Never sent in link');
                      if(_p.length > 1 && customer_link){
                        return (<span key={`price-str-${vehicleKey}-${pi}`}>
                          <b>{_p[0]}{' '}</b>
                          <Text strong type='danger'> (Never sent in link) </Text>
                          <b>{' | '}</b>
                        </span>);
                      } else {
                        return (<b key={`price-str-${vehicleKey}-${pi}`}>{_p[0]}{' | '}</b>);
                      }
                    })}</>
                  </div>
                })}
              </div>
              )
          })}
        </div>
      ),
      width: 420,
    },
    {
      title: 'Action',
      dataIndex: 'manage',
      key: 'manage',
      render: (_, { id }) => (
        <>
        <Button
          className='response-action-btn'
          onClick={(e) => {
            // Save lead id into local storage
            SaveLeadIdIntoLocalStorage(window.location.pathname, id);
            navigate(`/lead/${id}/request-quote`, {state: location.pathname});
            e.stopPropagation();
            e.preventDefault();
            return false;
          }}
        >
          Manage Leads
        </Button>
        <Button
            type="primary"
            className="response-action-btn generate-link-btn mt-10"
            onClick={(e) => {
              window.open(`${process.env.REACT_APP_LIMO_LINK}?lead=${id}`, '_blank', 'noopener');
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          >
            Generate Link 
          </Button>
          <Button
            type="primary"
            loading={(mtbLoading === id)}
            className="response-action-btn mt-10"
            onClick={(e) => {
              setMtbLoading(id);
              AutoUpdateStatus(id).then((d) => {
                if (d.code === 200) {
                  let _dataSource = [...dataSource];  
                  _dataSource = _dataSource.filter((item) => item.id !== id);
                  setDataSource(_dataSource);
                  messageApi.open({
                    type: 'success',
                    content: d.message
                  });
                }
                setMtbLoading(null);
              }).catch((e) => {
                messageApi.open({
                  type: 'error',
                  content: e.message
                });
                setMtbLoading(null);
              });
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}    
          >
            Move To Box
          </Button>

          <AgentDropdown id={id} agents={agents} className="response-action-btn" />
        </>
        
      ),
      width: 190,
    },
  ];

  return (
    <div className='p-ant-table-container'>
      {contextHolder}

      <Button className='clear-filter-btn' onClick={()=>{
        handleTableChange({total: 1, current: 1, pageSize: 100}, {}, {});
      }}>Clear Filters</Button>

      <Table
        dataSource={dataSource}
        ref={tableRef}
        rowKey="id"
        columns={columns}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
        rowClassName={(record, index) => `request-row`}
        scroll={{
          x: 1400,
        }}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              // Save lead id into local storage
              SaveLeadIdIntoLocalStorage(window.location.pathname, record.id);
              navigate(`/lead/${record.id}`, {state: location.pathname});
            }, // click row
          };
        }}
      />
    </div>
  )
};

export default ResponseNew;