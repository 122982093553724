// Import Module
import React, { useEffect, useState } from 'react';
import { Button, Table, Input, message, Modal } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { GetTemplates, CreateTemplate, DeleteTemplate, UpdateTemplate } from '../services/api';
import { FormatDate } from '../services/helper';


const Templates = () => {
  const [loading, setLoading] = useState(true);
  const [isECModalOpen, setIsECModalOpen] = useState(false); // Edit create modal
  const [isEditMode, setIsEditMode] = useState(false); // Edit or create mode
  const [editTemplateId, setEditTemplateId] = useState(''); // Edit or create mode
  const [dataSource, setDataSource] = useState([]);
  const { type } = useParams();

  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  // eslint-disable-next-line 
  const [templateType, setTemplateType] = useState(type);
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';

  const { TextArea } = Input;

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
      width: 30
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: 200
    },
    {
      title: 'Body',
      dataIndex: 'body',
      key: 'body',
      width: 300,
      render: (_, { body, id }) => (
        <div onClick={() => {
        }}>{body}</div>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, { created_at }) => (
        FormatDate(created_at)
      ),
      width: 200
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (_, { created_by }) => (
        <>{created_by}</>
      ),
      width: 150
    },
    {
      title: 'Actions',
      dataIndex: 'ud',
      key: 'ud',
      render: (_, { id, subject, body, type }) => (
        <div>
          {<>
          <EditOutlined className='vehicle-action-icon' onClick={() => {
            setIsECModalOpen(true);
            setIsEditMode(true);
            setEditTemplateId(id);
            setSubject(subject);
            setBody(body);
          }} />
          <DeleteOutlined className='vehicle-action-icon'
            onClick={() => {
              DeleteTemplate(id).then((d) => {
                if (d.code === 200) {
                  messageApi.open({
                    type: 'success',
                    content: d.message
                  });
                  getTemplatesData();
                } else {
                  messageApi.open({
                    type: 'error',
                    content: d.message
                  });
                }
              }).catch((e) => {
                messageApi.open({
                  type: 'error',
                  content: e.message
                });
              });
            }} />
            </>}
        </div>
      ),
      width: 50
    },
  ];

  const getTemplatesData = () => {
    setLoading(true);
    GetTemplates(type) // sms
      .then((data) => {

        let mappingData = [];
        data?.data?.templates.forEach((d) => {
          let obj = {
            id: d.id,
            key: d.id,
            type: d.type,
            subject: d.content.subject,
            body: d.content.body,
            created_at: d.created_at,
            created_by: d.created_by.name,
          };
          mappingData.push(obj);
        });
        setDataSource(mappingData);
        setLoading(false);
      })
      .catch((e) => {
        // console.log('error', e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTemplatesData();
  }, []); // eslint-disable-line

  const createTemplateData = (data) => {
    CreateTemplate(data).then((d) => {
      if (d.code === 200) {
        messageApi.open({
          type: 'success',
          content: 'Template created successfully.'
        });
      } else {
        messageApi.open({
          type: 'error',
          content: d.message
        });
      }
      setIsECModalOpen(false);
      getTemplatesData();
    }).catch((e) => {
      messageApi.open({
        type: 'error',
        content: e.message
      });
    });
  };

  const updateTemplateData = (templateId, data) => {
    UpdateTemplate(templateId, data).then((d) => {
      if (d.code === 200) {
        messageApi.open({
          type: 'success',
          content: 'Template updated successfully.'
        });
      } else {
        messageApi.open({
          type: 'error',
          content: d.message
        });
      }
      setIsECModalOpen(false);
      getTemplatesData();
    }).catch((e) => {
      messageApi.open({
        type: 'error',
        content: e.message
      });
    });
  }

  const saveTemplateData = () => {
    if (!subject) {
      messageApi.open({
        key,
        type: 'error',
        content: `Subject cannot be blank.`,
      });
    } else if (!body) {
      messageApi.open({
        key,
        type: 'error',
        content: `Body cannot be blank.`,
      });
    }
    else {
      let data = {
        "type": templateType,
        "content": {
            "subject": subject,
            "body": body
        }
      };
      if (isEditMode) {
        updateTemplateData(editTemplateId, data);
      } else {
        createTemplateData(data);
      }
    }
  }

  return (
    <div className='template-container' style={{padding: "10px"}}>
      {contextHolder}
      <Table
        className='p-ant-table-container'
        title={() => <div className='flex-separate'>
          <h2 className='camel-case'>{type} Templates</h2>
          <Button type='primary'
            onClick={() => {
              setIsECModalOpen(true);
              setIsEditMode(false);
              // Clear form data
              setSubject('');
              setBody('');
            }}>Add</Button>
        </div>}
        dataSource={dataSource}
        fixed={'top'}
        columns={columns}
        rowClassName={(record, index) =>
          `affiliate-row`
        }
        scroll={{
          x: 1500,
        }}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
            }, // click row
          };
        }}
      />

      <Modal centered title={`${isEditMode ? "Edit template" : "Add template"}`} open={isECModalOpen} okText="Save"
        onOk={() => {
          saveTemplateData();
        }}
        onCancel={() => {
          setIsECModalOpen(false);
        }}>
        <div className='template-ec-modal-form'>
          {/* <div className='mb-10'>
            <label className='ce-modal-label'>Template type: </label>
            <Radio.Group onChange={(e)=>setTemplateType(e.target.value)} value={templateType}>
              <Radio value={'email'}>Email</Radio>
              <Radio value={'sms'}>SMS</Radio>
            </Radio.Group>
          </div> */}
          <div className='mb-10'>
            <label className='ce-modal-label'>Subject: </label>
            <Input
              placeholder='Enter subject'
              size='mid'
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              maxLength={100}
            />
          </div>
          <div className='mb-10'>
            <label className='ce-modal-label'>Body: </label>
            <TextArea
              rows={5}
              placeholder='Content body'
              maxLength={5000}
              value={body}
              onChange={(e) => setBody(e.target.value)}
              showCount={true}
              size={'large'}
              style={{
                height: 200,
                resize: 'none',
              }}
            />
          </div>
          <br />
        </div>
      </Modal>
    </div >
  );
};

export default Templates;
