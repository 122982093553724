// Import Module
import React from 'react';

import Leads from './leads';

const AllLeads = () => {
  return (
    <Leads isOnlyIncomplete={false} isOnlyUnread={false} showDashboard={false} />
  )
};

export default AllLeads;