// src/socket.js
import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL; // Replace with your server URL

const socket = io(SOCKET_URL, {
    transports: ["websocket"],  
    autoConnect: true,
    reconnect: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 2
});

export default socket;