import React, { createContext, useState } from 'react';

// Create a context
const BadgeCountContext = createContext();

// Create a provider component that stores badge count
const BadgeCountProvider = ({ children }) => {
  const [value, setValue] = useState(0);

  return (
    <BadgeCountContext.Provider value={{ value, setValue }}>
      {children}
    </BadgeCountContext.Provider>
  );
};

export { BadgeCountContext, BadgeCountProvider };