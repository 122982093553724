// Import Module
import React, { useEffect, useRef, useState } from 'react';
import { Button, Table, Input, Space, message, Modal, Radio, Switch } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { GetAgents, CreateAgent, DeleteAgent, UpdateAgent } from '../services/api';
import { ValidateEmail, FormatDate } from '../services/helper';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  SearchOutlined,
} from '@ant-design/icons';

const Agents = () => {
  const [loading, setLoading] = useState(true);
  const [isECModalOpen, setIsECModalOpen] = useState(false); // Edit create modal
  const [isEditMode, setIsEditMode] = useState(false); // Edit or create mode
  const [editAgentId, setEditAgentId] = useState(''); // Edit or create mode
  const [dataSource, setDataSource] = useState([]);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [note, setNote] = useState('');
  const [status, setStatus] = useState('');

  /* eslint-disable-next-line */
  const [searchText, setSearchText] = useState('');
  /* eslint-disable-next-line */
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';


  const [tableParams, setTableParams] = useState({
    pagination: {
      total: 1,
      current: 1,
      pageSize: 20,
    },
  });
  const { TextArea } = Input;

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
    getAgentsData({
      pagination,
      filters,
      sorter,
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
      width: 30
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.name - b.name,
      ...getColumnSearchProps('first_name'),
      width: 100
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.name - b.name,
      ...getColumnSearchProps('last_name'),
      width: 100
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
      width: 50
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      width: 200
    },
    // Not needed for now
    // {
    //   title: 'Created At',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   sorter: (a, b) => a.created_at - b.created_at,
    //   render: (_, { created_at }) => (
    //     FormatDate(created_at)
    //   ),
    //   width: 200
    // },
    {
      title: 'Modified At',
      dataIndex: 'modified_at',
      key: 'modified_at',
      sorter: (a, b) => a.modified_at - b.modified_at,
      render: (_, { modified_at }) => (
        <>{FormatDate(modified_at)}</>
      ),
      width: 150
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { id, first_name, last_name, status,email, note }) => (
        <>
          <Switch
            checkedChildren={
              <CheckCircleTwoTone twoToneColor='#52c41a' />
            }
            unCheckedChildren={
              <CloseCircleTwoTone twoToneColor='#eb2f96' />
            }
            value={(status === 'active')}
            onChange={(checked) => {
              let data = {
                first_name: first_name,
                last_name: last_name,
                email: email,
                note: note,
                status: checked ? 'active' : 'inactive',
              }
              updateAgentData(id, data);
            }} />
          
        </>
      ),
      width: 30
    },
    {
      title: 'Actions',
      dataIndex: 'ud',
      key: 'ud',
      render: (_, { id, first_name, last_name, email, note, is_deleted, status }) => (
        <div>
          {!is_deleted && <>
          <EditOutlined className='vehicle-action-icon' onClick={() => {
            setIsECModalOpen(true);
            setIsEditMode(true);
            setEditAgentId(id);
            setFirstName(first_name);
            setLastName(last_name);
            setEmail(email);
            setNote(note);
            setStatus(status);
          }} />
          <DeleteOutlined className='vehicle-action-icon'
            onClick={() => {
              DeleteAgent(id).then((d) => {
                if (d.code === 200) {
                  messageApi.open({
                    type: 'success',
                    content: d.message
                  });
                  getAgentsData(tableParams);
                } else {
                  messageApi.open({
                    type: 'error',
                    content: d.message
                  });
                }
              }).catch((e) => {
                messageApi.open({
                  type: 'error',
                  content: e.message
                });
              });
            }} />
            </>}
        </div>
      ),
      width: 50
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getAgentsData = (tableParams) => {
    let params = `per_page_rows=${tableParams.pagination.pageSize}&current_page=${tableParams.pagination.current}&is_deleted=0`;
    if (tableParams.sorter?.columnKey) {
      params += '&sort_by=' + tableParams.sorter.columnKey;
      if (tableParams.sorter?.order) {
        const order = tableParams.sorter.order === 'ascend' ? 'ASC' : 'DESC';
        params += '&order_by=' + order;
      }
    }
    if (tableParams.filters) {
      Object.keys(tableParams.filters).forEach((key) => {
        if (tableParams.filters[key]) {
          const _val = tableParams.filters[key][0];
          params += `&${key}=${_val}`;
        }
      });
    }
    setLoading(true);
    GetAgents(params)
      .then((data) => {

        let mappingData = [];
        data?.data?.agentList.forEach((d) => {
          let obj = {
            id: d.id,
            key: d.id,
            first_name: d.first_name,
            last_name: d.last_name,
            email: d.email,
            note: d.note,
            created_at: d.created_at,
            modified_at: d.modified_at,
            is_deleted: (d.is_deleted === '1'),
            status:d.status,
          };
          mappingData.push(obj);
        });
        setDataSource(mappingData);
        setLoading(false);
        //Set pagination
        const pg = data.data.pagination;
        let pagination = {
          total: pg.total_items,
          current: pg.current_page,
          pageSize: parseInt(pg.per_page_rows),
        };
        if (tableParams.pagination.current === 1) {
          const { filters, sorter } = tableParams;
          setTableParams({ pagination, filters, sorter });
        }
      })
      .catch((e) => {
        // console.log('error', e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAgentsData(tableParams);
  }, []); // eslint-disable-line

  const createAgentData = (data) => {
    CreateAgent(data).then((d) => {
      if (d.code === 200) {
        messageApi.open({
          type: 'success',
          content: 'Agent created successfully.'
        });
      } else {
        messageApi.open({
          type: 'error',
          content: d.message
        });
      }
      setIsECModalOpen(false);
      getAgentsData(tableParams);
    }).catch((e) => {
      messageApi.open({
        type: 'error',
        content: e.message
      });
    });
  };

  const updateAgentData = (agentId, data) => {
    UpdateAgent(agentId, data).then((d) => {
      if (d.code === 200) {
        messageApi.open({
          type: 'success',
          content: 'Agent updated successfully.'
        });
      } else {
        messageApi.open({
          type: 'error',
          content: d.message
        });
      }
      setIsECModalOpen(false);
      getAgentsData(tableParams);
    }).catch((e) => {
      messageApi.open({
        type: 'error',
        content: e.message
      });
    });
  }

  const saveAgentData = () => {
    if (!email || !ValidateEmail(email)) {
      messageApi.open({
        key,
        type: 'error',
        content: `Email address seems to be wrong.`,
      });
    } else if (!firstName || firstName.length < 0) {
      messageApi.open({
        key,
        type: 'error',
        content: `Please enter valid first name.`,
      });
    }
    // Last name is optional
    // else if (!lastName || lastName.length < 3) {
    //   messageApi.open({
    //     key,
    //     type: 'error',
    //     content: `Please enter valid last name.`,
    //   });
    // } 
    else if (status === '') {
      messageApi.open({
        key,
        type: 'error',
        content: `Please select agent status.`,
      });
    }
    else {
      let data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        note: note,
        status: status,
      }
      if (isEditMode) {
        updateAgentData(editAgentId, data);
      } else {
        createAgentData(data);
      }
    }
  }

  return (
    <div className='agent-container'>
      {contextHolder}
      <Table
        className='p-ant-table-container'
        title={() => <div className='flex-separate'>
          <h2>Agents</h2>
          <Button type='primary'
            onClick={() => {
              setIsECModalOpen(true);
              setIsEditMode(false);
              // Clear form data
              setFirstName('');
              setLastName('');
              setEmail('');
              setNote('');
              setStatus('');
            }}>Add</Button>
        </div>}
        dataSource={dataSource}
        fixed={'top'}
        columns={columns}
        rowClassName={(record, index) =>
          `affiliate-row`
        }
        pagination={tableParams.pagination}
        onChange={handleTableChange}
        scroll={{
          x: 1500,
        }}
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
            }, // click row
          };
        }}
      />

      <Modal centered title={`${isEditMode ? "Edit agent" : "Add agent"}`} open={isECModalOpen} okText="Save"
        onOk={() => {
          saveAgentData();
        }}
        onCancel={() => {
          setIsECModalOpen(false);
        }}>
        <div className='agent-ec-modal-form'>
          <div className='mb-10'>
            <label className='ce-modal-label'>First Name: </label>
            <Input
              placeholder='Enter your first name'
              size='mid'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              maxLength={50}
            />
          </div>
          <div className='mb-10'>
            <label className='ce-modal-label'>Last Name: </label>
            <Input
              placeholder='Enter your last name'
              size='mid'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              maxLength={50}
            />
          </div>
          <div className='mb-10'>
            <label className='ce-modal-label'>Email address: </label>
            <Input
              placeholder='Enter your email'
              size='mid'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={48}
            />
          </div>
          <div className='mb-10'>
            <label className='ce-modal-label'>Status: </label>
            <Radio.Group onChange={(e)=>setStatus(e.target.value)} value={status}>
              <Radio value={'active'}>Active</Radio>
              <Radio value={'inactive'}>Inactive</Radio>
            </Radio.Group>
          </div>
          <div className='mb-10'>
            <label className='ce-modal-label'>Note: </label>
            <TextArea
              rows={4}
              placeholder='Admin note'
              maxLength={100}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              showCount={isEditMode}
              size={'large'}
              style={{
                height: 120,
                resize: 'none',
              }}
            />
          </div>
          <br />
        </div>
      </Modal>
    </div >
  );
};

export default Agents;
