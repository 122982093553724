// Import Module
import React, { useState, useEffect } from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
  const [isWaiting, setIsWaiting] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsWaiting(false)
    }, 1000);
  }, []);

  return (
    <>
      {!isWaiting &&
        <section className="page_404">
          <div className="container text-center">
            <div className="row">
              <div className="col-sm-12 ">
                <div className="col-sm-10 col-sm-offset-1  text-center">
                  <div className="four_zero_four_bg">
                    <h1 className="text-center ">404</h1>
                  </div>
                  <div className="contant_box_404">
                    <h1>
                      Look like you're lost
                    </h1>
                    <p>the page you are looking for not available!</p>
                    <Button type="primary" block={false} className="btn btn-primary"
                      onClick={() => navigate('/login')} size='large'>Go to Home</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>);
}

export default Error404;
