// Import Module
import React from 'react';
import { Carousel, Col } from 'antd';
import limoOne from '../assets/images/limo-1.jpg';
import limoTwo from '../assets/images/limo-2.jpg';
import limoThree from '../assets/images/limo-3.jpg';
import limoFour from '../assets/images/limo-4.jpg';

const SideCarousel = () => {
    return (
        <Col className='auth-section' xs={0} sm={0} md={12}>
            <Carousel autoplay>
                <div className='limo-img-wrapper'>
                    <img src={limoOne} alt='limo' className='limo-img' />
                </div>
                <div className='limo-img-wrapper'>
                    <img src={limoTwo} alt='limo' className='limo-img' />
                </div>
                <div className='limo-img-wrapper'>
                    <img src={limoThree} alt='limo' className='limo-img' />
                </div>
                <div className='limo-img-wrapper'>
                    <img src={limoFour} alt='limo' className='limo-img' />
                </div>
            </Carousel>
        </Col>

    )
};

export default SideCarousel;