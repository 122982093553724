// Import Module
import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Typography,
  List,
  Checkbox,
  InputNumber,
  Spin,
  AutoComplete,
  Row,
  Col,
  message,
  Select,
  Radio,
} from "antd";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import debounce from "lodash.debounce";
import dayjs from "dayjs";
import {
  GetLeadDetails,
  GetMatchingVehicle,
  RequestLead,
  FetchLocationSuggestions,
  GetAgents,
  NotifyAgent,
} from "../services/api";
import { VehicleCategory, VehicleSeat, Events, TripType, LimoVehicles } from "../constants";
import {
  GetMinMaxPAX,
  FormatPhone,
  FormatDateWithDay,
  ConvertTo12HourFormat,
  GetVT,
} from "../services/helper.js";
import BackBtn from "../components/back_btn";
import SmsModal from '../components/sms_modal';
import EmailModal from '../components/email_modal';
import FullSpin from '../components/full_spin';

const { Text } = Typography;

const LeadQuote = () => {
  const [originCity, setOriginCity] = useState("");
  const [originCityOptions, setOriginCityOptions] = useState("");
  const { id, miles } = useParams();
  const [distance, setDistance] = useState(miles? miles : 35);
  const [minPassenger, setMinPassenger] = useState(0);
  const [maxPassenger, setMaxPassenger] = useState(0);
  const [data, setData] = useState([]); // Vehicle list
  const [leadData, setLeadData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPriceRequestProcessing, setIsPriceRequestProcessing] =
    useState(false);
  const [harMore, setHasMore] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalAffiliates, setTotalAffiliates] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [pagination, setPagination] = useState({
    per_page_rows: 200,
    current_page: 1,
  });
  const [vehicleOrder, setVehicleOrder] = useState("distance");
  const [vehicle2ndOrder, setVehicle2ndOrder] = useState("passengers_count");
  const [vehicleFilter, setVehicleFilter] = useState("");
  const [requestHoursType, setRequestHoursType] = useState(null);
  const hoursType = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  const transHoursType = hoursType.map((hour) => ({
    label: hour,
    isSelected: false,
  }));
  const [numberOfHours, setNumberOfHours] = useState(transHoursType);
  const [agents, setAgents] = useState([]);

  const sortingOrder = [
    { name: "None", value: null },
    { name: "Distance", value: "distance" },
    { name: "Passengers", value: "passengers_count" },
    { name: "Limo Style First", value: "limo_style" },
    { name: "Non Limo Style First", value: "non_limo_style" },
    { name: "Pricing", value: "pricing_label" },
  ];
  const filterOrder = [
    { name: "None", value: "all" },
    { name: "Limo Style", value: "limo_style" },
    { name: "Non Limo Style", value: "non_limo_style" },
  ];
  const [includeLink, setIncludeLink] = useState(false);
  const [isSmsModelOpen, setIsSmsModelOpen] = useState(false);
  const [isEmailModelOpen, setIsEmailModelOpen] = useState(false);
  const [triggerId, setTriggerId] = useState(0);
  const [smsNo, setSmsNo] = useState('');
  const [email, setEmail] = useState('');
  const key = "updatable";

  const updateList = () => {
    let params = `min_pax=${minPassenger}&max_pax=${maxPassenger}&search_radius=${distance}`;
    // if (minPassenger){
    //     params += `min_pax=${minPassenger}`;
    // }
    if (originCity && originCity.latLng) {
      params += `&origin_geo_coordinates=${originCity.latLng}`;
    }
    // pagination part
    params += `&per_page_rows=${pagination.per_page_rows}&current_page=${pagination.current_page}`;
    if (vehicleOrder) {
      params += `&sort=${vehicleOrder}`;
    }
    if (vehicle2ndOrder) {
      params += `&secondary_sort=${vehicle2ndOrder}`;
    }
    if (vehicleFilter && vehicleFilter !== "all") {
      params += `&filter_by=${vehicleFilter}`;
    }
    getVehicles(params);
  };

  const applyFilter = () => {
    // reset existing data
    setData([]);
    setPagination({
      per_page_rows: 200,
      current_page: 1,
    });
    setHasMore(true);
  };

  const preventClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  };
  
  const getVehicles = (params = "") => {
    if (isLoading) return;
    setIsLoading(true);
    GetMatchingVehicle(id, params)
      .then((d) => {
        if (d.code === 200) {
          const preVehicleCount = data.length;
          const newVehicleCount = d.data?.vehicles?.length;
          const totalVehicleCount = preVehicleCount + newVehicleCount;
          setTotalRecords(d.data.pagination.total_items);
          setTotalAffiliates(d.data.pagination.total_affiliate_count);
          setHasMore(totalVehicleCount < d.data.pagination.total_items);
          setData((prevData) => [...prevData, ...d.data.vehicles]);
          setIsLoading(false);
          setPagination({
            per_page_rows: 200,
            current_page: parseInt(d.data.pagination.current_page) + 1,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetLeadDetails(id).then((d) => {
      if (d.code === 200) {
        setLeadData(d.data);

        // Based on PAX update the min and max filter
        const PAX = GetMinMaxPAX(d.data.passenger_count);

        setMinPassenger(PAX.min);
        setMaxPassenger(PAX.max);
        // setOriginCity({
        //     label: d.data?.pickup_city,
        //     latLng: null
        // });

        // set n & n-1 hours checked
        const hours = d.data.trip_hours;
        
        if (!!hours & hours > 0 ) {
          const _data = [...numberOfHours];
          _data[hours - 1].isSelected = true;
          if (hours > 1) {
            _data[hours - 2].isSelected = true;
          }
          setNumberOfHours(_data);
          setRequestHoursType(TripType.fixed_hours);
        } else {
          setRequestHoursType(d.data.trip_type);
        }

        // show only active agents
        GetAgents('status=active')
          .then((d) => {
            if (d.code === 200) {
              let agents = [];
              d.data.agentList.forEach((agent) => {
                agents.push({
                  label: agent.first_name + " " + agent.last_name,
                  value: agent.id,
                });
              });
              setAgents(agents);
            }
          })
          .catch((e) => {
            console.log("e", e);
          });
      }
    });
  }, [id]); //eslint-disable-line

  const getSelectedCount = () => {
    let count = 0;
    data.forEach((d) => {
      if (d.selected) count++;
    });
    return count;
  };

  const selectAllVehicle = () => {
    let _data = [...data];
    _data.forEach((d) => {
      d.selected = true;
    });
    setData(_data);
  };

  const limitOnOriginSearch = async (query) => {
    const data = await FetchLocationSuggestions(query);
    let locations = [];
    data?.features.forEach((d) => {
      const coordinates = d?.geometry?.coordinates;
      const obj = {
        label: d.place_name,
        // value: d.place_name,
        value: `${coordinates[1]},${coordinates[0]}`,
      };
      locations.push(obj);
    });
    setOriginCityOptions(locations);
  };

  // Add delay of 500ms before firing the API mapbox API
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAutoSearch = useCallback(
    debounce(limitOnOriginSearch, 500),
    []
  );

  const onOriginSearch = (query) => {
    setOriginCity({
      label: query,
      latLng: null,
    });
    // API call to autocomplete the data
    debounceAutoSearch(query);
  };

  const onOriginSelect = (v, o) => {
    setOriginCity({
      label: o.label,
      latLng: o.value,
    });
  };

  const onCheckboxSelection = (e, index) => {
    let _data = data;
    _data[index].selected = !_data[index].selected;
    setData([..._data]);
    e.stopPropagation();
    return false;
  };

  const sendPriceRequest = () => {
    let request = {
      vehicles: [],
      notify_already_requested_vehicles: true,
      origin_geo_coordinates: originCity.latLng,
      origin_address: originCity.label,
      search_radius: distance,
      min_pax: minPassenger,
      max_pax: maxPassenger,
    };
    if (!requestHoursType) {
      messageApi.open({
        key,
        type: "error",
        content: "Please select request hours",
      });
      return;
    }
    else if (requestHoursType === TripType.fixed_hours) {
      let hours = [];
      numberOfHours.forEach((d) => {
        if (d.isSelected) {
          // Send request for only selected hours
          if (hours.indexOf(d.label) === -1) {
            hours.push(d.label);
          }
        }
      });
      request["request_hours"] = hours;
    } else {
      request["request_hours"] = [requestHoursType];
    }
    let affiliateIds = [];
    data.forEach((d) => {
      if (d.selected) {
        if (affiliateIds.indexOf(d.affiliates.id) === -1) {
          affiliateIds.push(d.affiliates.id);
        }
        request.vehicles.push({
          id: d.id,
          distance: d.distance,
        });
      }
    });
    if (request.vehicles.length > 0) {
      setIsPriceRequestProcessing(true);
      RequestLead(id, request)
        .then((d) => {
          setIsPriceRequestProcessing(false);
          let message = `You have just sent a request for lead #${id} to ${request.vehicles.length
            } vehicle${request.vehicles.length > 1 ? "s" : ""}, ${affiliateIds.length
            } ${affiliateIds.length > 1 ? "companies" : "company"}.`;
          if (d.code !== 200) {
            message = `Error sending price request: ${d.message}`;
          }
          if (d.code === 200) {
            messageApi.open({
              // key,  // to have multiple toasts
              type: "success",
              content: message,
              duration: 0 // Set duration to 0 to keep the message open
            });
          }
        })
        .catch((error) => {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 0 
          });
          setIsLoading(false);
          setIsPriceRequestProcessing(false);
        });
    }
  };

  const isPastDate = () => {
    if (leadData) {
      const isSameDate = dayjs().isSame(leadData.pickup_date, "day");
      const isBeforeDate = dayjs().isBefore(leadData.pickup_date, "day");
      return !(isSameDate || isBeforeDate);
    }
    return false;
  };

  const hoursChange = (index, e) => {
    setRequestHoursType(TripType.fixed_hours);
    const _data = [...numberOfHours];
    _data[index].isSelected = e.target.checked;

    const allFalse = _data.every(hour => hour.isSelected === false);
    if (allFalse) {
      // if all hours are unchecked then reset the request hours type
      setRequestHoursType(null);
    }
    setNumberOfHours(_data);
  };

  const onHoursTypeChange = (e) => {
    setRequestHoursType(e.target.value);
    const _data = [...numberOfHours];
    _data.forEach((d) => {
      d.isSelected = false;
    });
    setNumberOfHours(_data);
  };

  const updateIncludeLink = (e) => {
    setIncludeLink(e.target.checked);
  } 

  return (
    <div className="lead-quote-container">
      <BackBtn />
      {contextHolder}
      <div className="lead-quote-section">
        {leadData ? (
          <div>
            <div className="flex-separate">
              <h1 className="flex-2">Lead Details #{id}</h1>
              <Checkbox
                checked={includeLink}
                onChange={(e) => {updateIncludeLink(e)}}
              >
              Send W/ Link
              </Checkbox>
              <Select
                placeholder="Send lead to agent"
                listHeight={'100%'}
                onChange={(v, o) => {
                  const payload = {
                    agent_id: v,
                    "includeLink": includeLink
                  };
                  NotifyAgent(id, payload)
                    .then((d) => {
                      if (d.code === 200) {
                        messageApi.open({
                          key,
                          type: "success",
                          content: `Agent notified successfully.`,
                        });
                      }
                    })
                    .catch((e) => {
                      messageApi.open({
                        key,
                        type: "error",
                        content: e.message,
                      });
                    });
                }}
                className="vehicle-color-list-container flex-1"
                options={agents}
              />
            </div>
            <div className="flex-separate align-start">
              <div className="flex-1">
                <p>
                  <b>Pickup: </b>
                  {leadData.pickup_city}, {leadData.pickup_state} |{" "}
                  {leadData.pickup_zipcode}
                </p>
                <p>
                  <b>Type: </b>
                  {Events[leadData.trip_event]}
                </p>
                <p>
                  <b>Time: </b>
                  {FormatDateWithDay(
                    dayjs(leadData.pickup_date).format("MM-DD-YYYY")
                  )}{" "}
                  {ConvertTo12HourFormat(leadData.pickup_time)}{" "}
                </p>
                <p>
                  <b>Vehicle Type: </b>
                  {GetVT(leadData.veh_type)}
                </p>
              </div>
              <div className="flex-1">
                <p>
                  <b>Dropoff: </b>
                  {leadData.dropoff_city}, {leadData.dropoff_state} |{" "}
                  {leadData.dropoff_zipcode}
                </p>
                <p>
                  <b>Passenger: </b>
                  {leadData.passenger_count}
                </p>
                <p>
                  <b>Hours: </b>
                  {leadData.trip_hours}
                </p>
              </div>
            </div>
            <div>
              <p>
                <b>Trip details: </b>
              </p>
              <p>{leadData.trip_details}</p>
            </div>
          </div>
        ) : (
          <FullSpin />
        )}
        <hr />
        <h4>Refine Results</h4>

        <Row className="filter-container" gutter={10}>
          <Col xs={24} md={12} xl={4}>
            <label className="lead-filter-label">Origin City</label>
            <AutoComplete
              value={originCity.label}
              options={originCityOptions}
              onSelect={onOriginSelect}
              onChange={onOriginSearch}
              placeholder="Enter city"
              className="lead-filter-input"
            />
          </Col>
          <Col xs={24} md={6} xl={2}>
            <label className="lead-filter-label">Mile Radius</label>
            <InputNumber
              min={1}
              max={100000}
              placeholder="Enter Mile"
              className="lead-filter-input"
              size="small"
              value={distance}
              onChange={(v) => (v ? setDistance(v) : null)}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
              }
              parser={(value) =>
                value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
              }
            />
          </Col>
          <Col xs={24} md={6} xl={3}>
            <label className="lead-filter-label">Min Passenger</label>
            <InputNumber
              min={1}
              max={100}
              placeholder="Enter minimum passenger"
              className="lead-filter-input"
              size="small"
              value={minPassenger}
              onChange={(v) => (v ? setMinPassenger(v) : null)}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
              }
              parser={(value) =>
                value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
              }
            />
          </Col>
          <Col xs={24} md={12} xl={3}>
            <label className="lead-filter-label">Max Passenger</label>
            <InputNumber
              min={1}
              max={999}
              placeholder="Enter maximum passenger"
              className="lead-filter-input"
              size="small"
              value={maxPassenger}
              onChange={(v) => (v ? setMaxPassenger(v) : null)}
              formatter={(value) =>
                `${value}`.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
              }
              parser={(value) =>
                value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
              }
            />
          </Col>
          <Col xs={24} md={12} xl={4}>
            <label className="lead-filter-label">Sort by</label>
            <Select
              placeholder="Sort by"
              value={vehicleOrder}
              onChange={(v, o) => setVehicleOrder(v)}
              className="vehicle-sorting-order flex-2"
              options={sortingOrder.slice(1, sortingOrder.length).map((d) => ({
                label: d.name,
                value: d.value,
              }))}
            />
          </Col>
          <Col xs={24} md={12} xl={4}>
            <label className="lead-filter-label">Secondary sort by</label>
            <Select
              placeholder="Sort by"
              // disabled={!!vehicleOrder}
              value={vehicle2ndOrder}
              onChange={(v, o) => setVehicle2ndOrder(v)}
              className="vehicle-sorting-order flex-2"
              options={sortingOrder.map((d) => ({
                label: d.name,
                value: d.value,
              }))}
            />
          </Col>
          <Col xs={24} md={12} xl={4}>
            <label className="lead-filter-label">Filter by</label>
            <Select
              placeholder="Filter by"
              // value={vehicleOrder}
              onChange={(v, o) => setVehicleFilter(v)}
              className="vehicle-sorting-order flex-2"
              options={filterOrder.map((d) => ({
                label: d.name,
                value: d.value,
              }))}
            />
          </Col>
        </Row>
        <br />
        
        <div className="update-filter-btn">
          <Button type="primary" className='float-right' loading={isLoading} onClick={applyFilter}>
            Update Results
          </Button>
        </div>
        <br />
        <hr />

        <h4>Request Hours: </h4>
        {/* If the trip type is round trip do not show any other options for selecting hours
        or one way or multi days Trip */}
        {requestHoursType !== 'round_trip' ?
        <>
        <Row>
          {numberOfHours.map((d, i) => {
            return (
              <Col xs={6} md={4} xl={2} key={`no-of-hours-${i}`}>
                <Checkbox
                  checked={d.isSelected}
                  onChange={(e) => hoursChange(i, e)}
                >
                  {d.label} {i === 0 ? "hr" : "hrs"}
                </Checkbox>
              </Col>
            );
          })}
        </Row>
        <p className="text-center">---------------- OR ----------------</p>
        <Radio.Group onChange={onHoursTypeChange} value={requestHoursType}>
          <Radio value={"one_way_transfer"}>{TripType.one_way_transfer}</Radio>
          <Radio value={"multiple_day_usage"}>
            {TripType.multiple_day_usage}
          </Radio>
          {requestHoursType === 'round_trip' && <Radio value={"round_trip"}>
            {TripType.round_trip}
          </Radio>}
        </Radio.Group>
        <br />
        <hr />
        </> : 
        <p>Request hours cannot be selected as the Trip type is Round Trip.  ( Two one way Trip )</p>}
        
        <div className="flex-separate">
          <div className="custom-control custom-checkbox">
            <Text type="success">
              {totalRecords} vehicles found, {totalAffiliates} affiliates in
              total
            </Text>
          </div>
          <div>
            <Button type="link" onClick={selectAllVehicle}>
              Select all
            </Button>
            <span>| {getSelectedCount()} Selected </span>
            <Button
              type="primary"
              loading={isPriceRequestProcessing}
              // Keep the button disabled if it's a past lead or no vehicle is selected for sending the price request
              disabled={isPastDate() || (getSelectedCount() <= 0)}
              title={isPastDate() ? "Pickup date is already passed" : ""}
              onClick={sendPriceRequest}
            >
              Send Price Request
            </Button>
          </div>
        </div>
        <br />

        {leadData && (
          <InfiniteScroll
            pageStart={0}
            loadMore={updateList}
            hasMore={harMore}
            className="vehicle-list-container"
            loader={
              <Spin key={0} style={{ textAlign: "center", width: "100%" }} />
            }
          >
            <List
              size="large"
              bordered
              grid={{
                gutter: 10,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 4,
              }}
              dataSource={[...data]}
              renderItem={(item, index) => (
                <List.Item
                  key={item.id}
                  className={`lead-vehicle vehicle-image-card ${item.category
                    }
                  ${LimoVehicles.includes(item.category) ? "lead-limo-vehicle" : ""} 
                  ${item.selected ? "lead-vehicle-selected" : ""}`}
                  onClick={(e) => onCheckboxSelection(e, index)} // Handle item click
                  actions={[<Checkbox checked={item.selected} />]}
                >
                  <List.Item.Meta
                    title={
                      <p>
                        {item.passenger_count} Passenger{item.passenger_count > 1? 's': ''}{" "}
                        <b className="mile-txt">{VehicleCategory[item.category]}</b> (
                        <span className="txt-cap">{item.color}</span>
                        {item.seat_fabric &&
                          `, ${VehicleSeat[item.seat_fabric]}`}
                        )
                      </p>
                    }
                    description={
                      <div className="lead-quote-desc">
                        <p>
                          <b className="cmpny-name">{item.affiliates.name}</b> |{" "}
                          {item.affiliates.address.city},{" "}
                          {item.affiliates.address.state}
                        </p>
                        <p className="mile-txt">
                          {item.distance.toFixed(0)} Miles
                        </p>
                        <p>
                          <span
                            className="cursor-pointer"
                            onClick={(e)=> {
                              setEmail(item.affiliates.email);
                              setTriggerId(item.affiliates.id);
                              setIsEmailModelOpen(true);
                              preventClick(e);
                          }}>
                            {item.affiliates.email}
                          </span>{" "}
                        | <span 
                            className="cursor-pointer"
                            onClick={(e)=> {
                              setSmsNo(item.affiliates.phone_number);
                              setTriggerId(item.affiliates.id);
                              setIsSmsModelOpen(true);
                              preventClick(e);
                          }}>{FormatPhone(item.affiliates.phone_number)}</span>
                        </p>
                        <p className="pricing-label">{item.affiliates.pricing_label}</p>
                        <div>
                          {/* Show badge only if present */}
                          {item.sent_request_count > 0 && 
                            <span className={"badge-vehicle-quote"}>{item.sent_request_count}</span>
                          }
                        </div>
                        {/* Show who had requested before if the price request was already sent */}
                        {item.request && (
                          <p className="lead-quote-name">
                            Request already sent by{" "}
                            {item.request.created_by.name}
                          </p>
                        )}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        )}
      </div>

      {isSmsModelOpen && <SmsModal 
        isModalOpen={isSmsModelOpen}
        setIsModalOpen={setIsSmsModelOpen}
        msgType="affiliate"
        phoneNumber={smsNo}
        id={triggerId}
      />}

      {isEmailModelOpen && <EmailModal 
        isModalOpen={isEmailModelOpen}
        setIsModalOpen={setIsEmailModelOpen}
        msgType="affiliate"
        emailAddress={email}
        id={triggerId}
      />}

    </div>
  );
};

export default LeadQuote;
