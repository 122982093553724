// Import Module
import React, { useState, useEffect } from "react";
import { Modal, Button, Input, message, Select } from "antd";
import PhoneInput from "./phone_input";
import { SendSMS, GetTemplates } from "../services/api";
import { FormatPhoneNumberForServer} from '../services/helper';

const { TextArea } = Input ;

const SmsModal = ({
  isModalOpen,
  setIsModalOpen,
  phoneNumber,
  id,
  msgType="affiliate",
}) => {
  const [phone, setPhone] = useState(phoneNumber); //eslint-disable-line
  const [sms, setSms] = useState("");
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  useEffect(()=>{
    GetTemplates('sms').then((d) => {
      if (d.code === 200) {
        setSmsTemplates(d.data.templates);
      }
    });
  }, []);

  const send = () => {
    // validate vehicle
    if (!sms || sms.trim() === ""){
      messageApi.open({
        key,
        type: "error",
        content: `Message is required.`,
      });
      return false;
    } else {
      let obj = {
        phone_number: FormatPhoneNumberForServer(phone.toString()),
        message: sms,
        message_type: msgType,
        id: id,
      };
      setIsLoading(true);
      SendSMS(obj).then((d) => {
        setIsLoading(false);
        if (d.code === 200) {
          messageApi.open({
            key,
            type: "success",
            content: `SMS sent successfully.`,
          });
        } else {
          messageApi.open({
            key,
            type: "error",
            content: `Failed to send message.`,
          });
        }
        // timeout to show message and close modal
        setTimeout(() => {
          setIsModalOpen(false);
        }, 1000);
      }).catch((e) => {
        setIsLoading(false);
        messageApi.open({
          key,
          type: "error",
          content: e.message,
        });
      });
    }
  };

  return (
    <Modal
      title="Send SMS"
      open={isModalOpen}
      centered
      width={600}
      // okText={'Yes'} cancelText={'No'} onOk={statusUpdate}
      onCancel={() => setIsModalOpen(false)}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => {
            send();
          }}
        >
          Send
        </Button>,
      ]}
    >
      <div>
        {contextHolder}
        <PhoneInput value={phone} className='mt-10' disabled/>
        <TextArea 
          className='mt-10'
          placeholder="Enter message"
          value={sms}
          rows={4}
          maxLength={500}
          onChange={(e) => setSms(e.target.value)}
          size={'large'}
          style={{
            height: 120,
            resize: 'none',
          }}
        />
        <Select
          value={selectedTemplate}
          placeholder='Insert Template'
          onChange={(v, o) => {
            setSelectedTemplate(o.label);
            setSms(smsTemplates.find(s => s.id === v).content.body);
          }}
          className='w-100 mt-10'
          options={smsTemplates.map((t) => ({ label: t.content.subject, value: t.id }))}
        />
      </div>
    </Modal>
  );
};

export default SmsModal;
