// Import Module
import React, { useState, useEffect, useCallback  } from "react";
import { Modal, Button, message, Spin, List, Checkbox, Typography } from "antd";
import dayjs from "dayjs";
import SmsModal from '../components/sms_modal';
import EmailModal from '../components/email_modal';
import {GetMatchingVehicle30_60, RequestLead} from '../services/api';
import { VehicleCategory, VehicleSeat, LimoVehicles, Events } from "../constants";
import { FormatPhone, FormatDateWithDay, GetCookie } from "../services/helper";

const { Text } = Typography;

const MatchingVehicleModal = ({
  isModalOpen,
  setIsModalOpen,
  tripTypeAndHours,
  id,
  msgType="affiliate",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalAffiliates, setTotalAffiliates] = useState(0);
  const [data, setData] = useState([]);
  const [triggerId, setTriggerId] = useState(0);
  const [smsNo, setSmsNo] = useState('');
  const [email, setEmail] = useState('');
  const [isSmsModelOpen, setIsSmsModelOpen] = useState(false);
  const [isEmailModelOpen, setIsEmailModelOpen] = useState(false);
  const [isPriceRequestProcessing, setIsPriceRequestProcessing] = useState(false);
  const [requestHoursType, setRequestHoursType] = useState(tripTypeAndHours.type); //eslint-disable-line

  // isHardReload if forcefully needs to re-render the list, usecallback to cache function
  const fetchMatchingVehicles = useCallback((isHardReload) => {
    setIsLoading(true);
    GetMatchingVehicle30_60(id, tripTypeAndHours.miles, tripTypeAndHours.min, tripTypeAndHours.max, isHardReload)
    .then((d) => {
        if (d.code === 200) {
          setTotalRecords(d.data.pagination.total_items);
          setTotalAffiliates(d.data.pagination.total_affiliate_count);

          // Check all the vehicles by default
          let dataChecked = d.data.vehicles
          dataChecked.forEach((d) => {
            console.log(dataChecked.is_selected, tripTypeAndHours.isComputerSelected)
            //  Check the boxes if selected is true and is picked by computer
            d.selected = d.is_selected && tripTypeAndHours.isComputerSelected ? true: false;
          });
          setData((prevData) => dataChecked);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, [id, tripTypeAndHours]);

  //  One page load make the API call
  useEffect(()=>{
    fetchMatchingVehicles(false);
  }, [fetchMatchingVehicles]);

  const preventClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  };

  const onCheckboxSelection = (e, index) => {
    let _data = data;
    _data[index].selected = !_data[index].selected;
    setData([..._data]);
    e.stopPropagation();
    return false;
  };

  const vehicleSelection = (flag) => {
    let _data = [...data];
    _data.forEach((d) => {
      d.selected = flag;
    });
    setData(_data);
  };

  const sendPriceRequest = () => {
    let request = {
      vehicles: [],
      notify_already_requested_vehicles: true,
    };
    if (requestHoursType === "fixed_hours") {
      let hours = [];
    // Getting the request hours from the lead as it is needed for sending price request
      let numberOfHours = [tripTypeAndHours.hours, tripTypeAndHours.hours-1]
      numberOfHours.forEach((d) => {
        if (d !== -1) {
            hours.push(d);
        }
      });
      request["request_hours"] = hours;
    } else {
      request["request_hours"] = [requestHoursType];
    }
    let affiliateIds = [];
    data.forEach((d) => {
      if (d.selected) {
        if (affiliateIds.indexOf(d.affiliates.id) === -1) {
          affiliateIds.push(d.affiliates.id);
        }
        request.vehicles.push({
          id: d.id,
          distance: d.distance,
        });
      }
    });
    if (request.vehicles.length > 0) {
      setIsPriceRequestProcessing(true);
      RequestLead(id, request)
        .then((d) => {
          setIsPriceRequestProcessing(false);
          let message = `You have just sent a request for lead #${id} to ${request.vehicles.length
            } vehicle${request.vehicles.length > 1 ? "s" : ""}, ${affiliateIds.length
            } ${affiliateIds.length > 1 ? "companies" : "company"}.`;
          if (d.code !== 200) {
            message = `Error sending price request: ${d.message}`;
          }
          if (d.code === 200) {
            messageApi.open({
              // key,  // to have multiple toasts
              type: "success",
              content: message,
              duration: 0 // Set duration to 0 to keep the message open
            });
          }
        })
        .catch((error) => {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 0 
          });
          setIsLoading(false);
          setIsPriceRequestProcessing(false);
        });
    }
  };

  const getSelectedCount = () => {
    let count = 0;
    data.forEach((d) => {
      if (d.selected) count++;
    });
    return count;
  };

  const {pickup_address,dropOff_address, hours,
  pickup_date, pickup_time, trip_details, trip_event, passenger_count, veh_type, min, max} = tripTypeAndHours;

  const zoomLevel = GetCookie('Zoom');

  return (
    <Modal
      title={`Lead Details #${id} | ${min} - ${max} Miles Search Radius`}
      open={isModalOpen}
      className="matching-vehicle-modal-bootspace"
      centered
      width="75%"
    //  based on the zoom level decide the height of the modal  
      style={{ height: `calc(100vh + ${zoomLevel === '80' ? '100px' : '(-100px)'})`}}
      styles={{
        body:{
            overflowX: 'scroll'
        }
      }}
    //   bodyStyle={{overflowX: 'scroll'}}
      onCancel={() => setIsModalOpen(false)}
      footer={ !isLoading && [
        <Text type="success" key="1">
              {totalRecords} vehicles found, {totalAffiliates} affiliates in total
        </Text>,
        <Button type="link" onClick={() => vehicleSelection(false)} key="23">
          Unselect all
        </Button>,
        <Button type="link" onClick={() => vehicleSelection(true)} key="2">
          Select all
        </Button>,
        <Button
            key="3"
            type="primary"
            loading={isPriceRequestProcessing}
            onClick={sendPriceRequest}
            disabled={(getSelectedCount() <= 0)}
        >
        Send Price Request
        </Button>
      ]}
    >
      <div className="lead-quote-container">
        {contextHolder}
        <Button
            className="hard-fetch-btn"
            key="refresh"
            type="primary"
            loading={isPriceRequestProcessing}
            onClick={() => fetchMatchingVehicles(true)}
        >
        Refresh
        </Button>
        {isLoading ? 
            <div className='text-center'><Spin/></div> 
            :
            <div className='matching-vehicle-container-box'>
                <div className="lead-quote-section-breakdown">
                    <div className="flex-separate align-start">
                        <div className="flex-1">
                            <p>
                            <b>Pickup: </b>
                            {pickup_address}
                            </p>
                            <p>
                            <b>Type: </b>
                            {Events[trip_event]}
                            </p>
                            <p>
                            <b>Time: </b>
                            {FormatDateWithDay(
                                dayjs(pickup_date).format("MM-DD-YYYY")
                            )}{" "}
                            {(pickup_time)}{" "}
                            </p>
                            <p>
                              <b>Vehicle Type: </b>
                              {veh_type}
                            </p>
                        </div>
                        <div className="flex-1">
                            <p>
                            <b>Dropoff: </b>
                            {dropOff_address}
                            </p>
                            <p>
                            <b>Passenger: </b>
                            {passenger_count}
                            </p>
                            <p>
                            <b>Hours: </b>
                            {hours}
                            </p>
                        </div>
                    </div>    
                    <div>
                        <p>
                            <b>Trip details: </b>
                            {trip_details}
                        </p>
                    </div>
                </div>    
                <List
                size="large"
                bordered
                grid={{
                    gutter: 10,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 3,
                }}
                dataSource={[...data]}
                renderItem={(item, index) => (
                    <List.Item
                    key={item.id}
                    className={`lead-vehicle vehicle-image-card ${item.category
                        }
                    ${LimoVehicles.includes(item.category) ? "lead-limo-vehicle" : ""} 
                    ${item.selected ? "lead-vehicle-selected" : ""}`}
                    onClick={(e) => onCheckboxSelection(e, index)} // Handle item click
                    actions={[<Checkbox checked={item.selected} />]}
                    >
                    <List.Item.Meta
                        title={
                        <p>
                            {item.passenger_count} Passenger{item.passenger_count > 1? 's': ''}{" "}
                            <b className="mile-txt">{VehicleCategory[item.category]}</b> (
                            <span className="txt-cap">{item.color}</span>
                            {item.seat_fabric &&
                            `, ${VehicleSeat[item.seat_fabric]}`}
                            )
                        </p>
                        }
                        description={
                        <div className="lead-quote-desc">
                            <p>
                            <b className="cmpny-name">{item.affiliates.name}</b> |{" "}
                            {item.affiliates.address.city},{" "}
                            {item.affiliates.address.state}
                            </p>
                            <p className="mile-txt">
                            {item.distance.toFixed(0)} Miles
                            </p>
                            <p>
                            <span
                                className="cursor-pointer"
                                onClick={(e)=> {
                                setEmail(item.affiliates.email);
                                setTriggerId(item.affiliates.id);
                                setIsEmailModelOpen(true);
                                preventClick(e);
                            }}>
                                {item.affiliates.email}
                            </span>{" "}
                            | <span 
                                className="cursor-pointer"
                                onClick={(e)=> {
                                setSmsNo(item.affiliates.phone_number);
                                setTriggerId(item.affiliates.id);
                                setIsSmsModelOpen(true);
                                preventClick(e);
                            }}>{FormatPhone(item.affiliates.phone_number)}</span>
                            </p>
                            <p className="pricing-label">{item.affiliates.pricing_label}</p>
                            <div>
                            {/* Show badge only if present */}
                            {item.sent_request_count > 0 && 
                                <span className={"badge-vehicle-quote"}>{item.sent_request_count}</span>
                            }
                            </div>
                            {/* Show who had requested before if the price request was already sent */}
                            {item.request && (
                            <p className="lead-quote-name">
                                Request already sent by{" "}
                                {item.request.created_by.name}
                            </p>
                            )}
                        </div>
                        }
                    />
                    </List.Item>
                )}
                />
                {isSmsModelOpen && <SmsModal 
                    isModalOpen={isSmsModelOpen}
                    setIsModalOpen={setIsSmsModelOpen}
                    msgType="affiliate"
                    phoneNumber={smsNo}
                    id={triggerId}
                />}

                {isEmailModelOpen && <EmailModal 
                    isModalOpen={isEmailModelOpen}
                    setIsModalOpen={setIsEmailModelOpen}
                    msgType="affiliate"
                    emailAddress={email}
                    id={triggerId}
                />}
            </div>
        }
      </div>
    </Modal>
  );
};

export default MatchingVehicleModal;
